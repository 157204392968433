import Main from "../Main";

/**
 * Process steps of a given timeline
 * @param {import("../store/utils/process-timeline").TimelineProps} timeline
 * @param {import("../store/utils/get-trigger").TriggerProps} trigger
 * @this import("../Main").MainClassProps
 */
Main.prototype.computeTimeline = function (timeline, trigger, mode) {
  /**
   * Reference
   * @type {import("../store/utils/process-timeline").TimelineInfoType}
   */
  const timelineInfo = timeline.info;

  /** If the timeline is not active, delete the corresponding trigger name from `activeTriggersSet` */
  if (!timelineInfo.active) {
    this.activeTriggersSet.delete(trigger.name);
    this.activeTriggers.get(trigger.type)?.delete(trigger.name);
  }

  /** Current step reference */
  const currentStep = timelineInfo.currentStep;

  /** Reference that will be used in "loop-value-triggers.js" */
  trigger.currentStep = currentStep;

  if (trigger.interactive) {
    /** Run trough all active steps, because some of them can run in parallel */
    for (const step of timeline.steps) {
      if (step.active) {
        this.computeStep(
          step,
          timeline,
          trigger,
          timelineInfo,
          currentStep,
          mode
        );
      }
    }
  } else {
    /**
     * Loop each step in the timeline steps
     * @example `{options: null, parents: {…}}` - this is a step with a parents
     */
    this.computeStep(
      timeline.steps[currentStep],
      timeline,
      trigger,
      timelineInfo,
      currentStep,
      mode
    );
  }
};

export default Main.computeTimeline;
