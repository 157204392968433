/**
 * Parses a string to determine if it starts with a priority indicator '!'.
 * @param {string} string - The input string to check for priority indicator.
 * @property {boolean} priority - Indicates if the string starts with a priority indicator '!'.
 * @property {string} rest - The remaining string after removing the priority indicator if present.
 */
const getPriority = (string) => {
  if (string.startsWith("!")) {
    return {
      priority: true,
      rest: string.slice(1),
    };
  }

  return {
    priority: false,
    rest: string,
  };
};

export default getPriority;
