import checkChildCharacter from "../../../utils/check-child-character";
import getResolutionsObject from "../../../utils/get-resolutions-object";
import G from "../../../utils/globals";
import getIndexType from "./get-index-type";

/**
 *
 * @param {String} input
 * @param {Number} depth
 * @param {Array} parentArray
 * @param {Object | null} previousObject
 * @param {import("../../../utils/process-values-in-resolutions").PropertyMapProps} data
 * @param {import("./process-options").OptionsMapProps} options
 * @returns
 */
const processFunctionValues = (
  input,
  depth,
  parentArray,
  previousObject,
  data,
  options,
  multiIndex
) => {
  let i = 0;

  const object = {};
  const split = input.split(G.depthSeparators[depth]);

  for (const level of split) {
    const indexType = getIndexType(data, i, level);
    const index = indexType.index;
    const resolutions = getResolutionsObject(level, data, options);

    object[index] = object[index] || {};

    for (const [resolution, values] of resolutions) {
      object[index][resolution] = object[index][resolution] || {};
      object[index][resolution] = values;
      object[index][resolution].set("depth", i);
      object[index][resolution].set("path", []);
      object[index][resolution].set("multiIndex", multiIndex || 0);

      if (previousObject) {
        object[index][resolution].set("previousObject", previousObject);
        object[index][resolution].get("path")[2] = index;

        if (previousObject?.get("depth") || previousObject?.get("depth") === 0) {
          object[index][resolution].get("path")[1] = previousObject?.get("depth");
        }
        if (previousObject?.get("previousObject")?.get("depth") || previousObject?.get("previousObject")?.get("depth") === 0) {
          object[index][resolution].get("path")[0] = previousObject?.get("previousObject")?.get("depth");
        }

        object[index][resolution].set("path", object[index][resolution].get("path").slice(-1 * depth));
      }

      if (previousObject) {
        previousObject.set("value", object);
      }

      if (
        resolution !== "current" &&
        (values.get("fn") || checkChildCharacter(values.get("output")))
      ) {
        parentArray.push(values);
      }
    }


    ++i;
  }
  // console.log(input, path);
  // console.log(object, input);
  return object;
};

export default processFunctionValues;
