import G from "../../../utils/globals";
import getNestedString from "./get-nested-string";

/**
 *
 * @param {import("../../Main").MainClassProps} main
 * @param {CSSStyleDeclaration} style
 */
const setInitialsCSS = (main) => {
  const result = {};
  const shift = main.shift;

  if (shift) {
    const tx = shift.translateX ? `translateX(${shift.translateX})` : "";
    const ty = shift.translateY ? `translateY(${shift.translateY})` : "";
    const computed = `${tx} ${ty}`;

    main.shift = computed;
    main.style.setProperty("transform", computed);
  }

  for (const parent in main.initials) {
    const final = [];
    const multi = main.initials[parent];
    // console.log(main.initials);
    result[parent] = result[parent] || new Map();

    for (const multiIndex in multi) {
      /** @type {import("../../../utils/process-values-in-resolutions").PropertyMapProps} */
      const item = multi[multiIndex];

      result[parent].has(multiIndex) || result[parent].set(multiIndex, []);

      for (const index in item) {
        const property = item[index].current;

        if (!property || !property.get("isInitial")) {
          continue;
        }

        const data = getNestedString(property, property, result, multiIndex);

        // TODO: convert to map()
        main.appliedInitials = main.appliedInitials || {};
        main.appliedInitials[parent] = main.appliedInitials[parent] || {};
        main.appliedInitials[parent][multiIndex] =
          main.appliedInitials[parent][multiIndex] || {};
        main.appliedInitials[parent][multiIndex][index] = data.join(" ");

        result[parent].get(multiIndex).push(data);
      }
    }

    // TODO: make in loop after inits
    for (const items of result[parent].values()) {
      final.push(items.join(" "));
    }
    // console.log(
    //   main.initials,
    //   main.store["hover-bgi(linear-gradient(90deg,#fd44e9,#531bbe,#77e5fd))"]
    //     .timeline.steps[0].parents["background-image"].multi[0][0].current,
    // );
    // console.log(parent, final.join(", "));
    main.style.setProperty(parent, final.join(", "));
  }
};

export default setInitialsCSS;
