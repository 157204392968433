import isInResolution from "./is-in-resolution";

/**
 *
 * @param {Map} input
 * @returns
 */
const getCurrentResolution = (input, isObject) => {
  let last;
  const resolutions = isObject ? new Map(Object.entries(input)) : input;

  for (const resolution of resolutions.keys()) {
    if (resolution === "current") {
      continue;
    }

    const inResolution = isInResolution(resolution);

    if (inResolution) {
      last = resolutions.get(resolution);
    }
  }

  return last;
}

export default getCurrentResolution;