/**
 * Extract content between brackets
 * @param {String} input
 * @param {String} openChar
 * @param {String} closeChar
 */
const getClusterContent = (input, openChar = "[", closeChar = "]") => {
  const open = 0;
  const close = input.length - 1;

  if (input.charAt(open) === openChar && input.charAt(close) === closeChar) {
    return input.substring(1, close);
  } else {
    console.warn(`[TorusKit error]: The \`${input}\` is not a valid cluster.`);
    return "";
  }
};

export default getClusterContent;
