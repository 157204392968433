import getIndexes from "./get-indexes";

/**
 * Check for CSS "calc()" function
 * @param {import("./get-function").FunctionObject} input
 */
const checkCalc = (input) => {
  if (input.name === "calc") {
    let parameters = input.parameters;
    const operators = ["+", "-", "*", "/"];

    for (const operator of operators) {
      const indexes = getIndexes(parameters, operator);

      for (const index of indexes) {
        if (index) {
          const prev = parameters[index - 1];
          const next = parameters[index + 1];

          parameters = parameters.replace(prev, "▓");
          parameters = parameters.replace(next, "▓");
          parameters = parameters.replace("(", "〈");
          parameters = parameters.replace(")", "〉");
        }
      }
    }

    return parameters;
  }

  return null;
}

export default checkCalc;