import checkChildCharacter from "../../../utils/check-child-character";

/**
 * Extract the composition character
 * @param {String} input
 * @example `opacity((+)1)`
 */
const getComposition = (input) => {
  if (
    (input.startsWith("(+)") || input.startsWith("(-)")) &&
    !checkChildCharacter(input)
  ) {
    return {
      composition: input.slice(1, 2),
      rest: input.slice(3),
    };
  }

  return {
    composition: false,
    rest: input,
  }
}

export default getComposition;