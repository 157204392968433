import G from "./globals";

const getShorthand = (input) => {
  let result = {};

  for (const parent of ["transform", "other"]) {
    const value = G.shorthands[parent][input];

    if (value) {
      if (parent === "other") {
        result =  {
          value,
          parent: null,
        }
      } else {
        result =  {
          parent,
          value,
        }
      }

      break;
    } else {
      result = {
        parent: null,
        value: input,
      };
    }
  }

  if (G.parents.transform.has(input)) {
    result = {
      parent: "transform",
      value: input,
    }
  }

  return result;

}

export default getShorthand;