import warnings from "../../../utils/warnings";
import WINDOW from "../../../utils/window";
import computeToCalc from "./compute-to-calc";
import getPriority from "./get-priority";

const loopValueTriggers = (item, activeTriggersNames, arrayPool, current) => {
  let initial;
  let priority = false;
  const result = arrayPool.acquire();

  /** Loop trough all active triggers */
  for (const trigger of activeTriggersNames) {
    const name = trigger.name;

    /** Get all steps in the timeline */
    const steps = item.get("value").get(name);

    if (!steps) {
      continue;
    }

    /** Loop all steps */
    for (const stepsItem of steps) {
      let activeEnter;
      let activeLeave;

      const number = stepsItem[0];

      /** @type {import("../../../utils/process-values-in-resolutions").PropertyMapProps} */
      const property = stepsItem[1];

      if (trigger.timeline.info.skip?.get("value") && trigger.iterating && trigger.currentStep === 0) {
        continue;
      }

      if (current.get("hasChild")) {
        /** @type {Array} */
        const path = property.get("path");

        const value0 = current.get("value")[path[0]].current;

        if (value0.get("hasChild")) {
          const prev0 = value0.get("value")[path[1]];

          if (!prev0) {
            warnings.cannotFindInitialValue(value0.get("output"));
          }

          const value1 = prev0.current;

          if (value1.get("hasChild")) {
            const value2 = value1.get("value")[path[2]].current;

            if (value2.get("hasChild")) {
            } else {
              initial = value2.get("output");
            }
          } else {
            initial = value1.get("output");
          }
        } else {
          initial = value0.get("output");
        }
      } else {
        if (WINDOW.isSafari && !current.get("value")) {
          initial = `(0 * ${property.get("output")})`;
        } else {
          initial = current.get("output");
        }
      }

      /** If the currently looped trigger has no corresponding property */
      if (!property) {
        continue;
      }

      /** @type {import("../../../utils/process-values-in-resolutions").LiveProps} */
      const live = property.get("live");

      priority = getPriority(property);

      let output = property.get("output");
      let progress = live.get("progress");

      if (live.has("translate-path")) {
        output = live.get("translate-path");
      }

      if (!trigger.interactive) {
        if (live.get("entering")) {
          activeEnter = trigger.currentStep < +number;
        }
        if (live.get("leaving")) {
          activeLeave = trigger.currentStep > +number;
        }
      }

      /** Property is not active (has no progress) */
      if (!progress && activeEnter) {
        continue;
      }
      if (progress === 1 && activeLeave) {
        continue;
      }

      if (activeEnter) {
        progress = 0;
      }
      if (activeLeave) {
        progress = 1;
      }

      switch (true) {
        case property.get("isString"):
          if (live.get("entering")) {
            // result.push(output);
            result[0] = output;
          } else {
            // result.push(current.get("value"));
            result[0] = current.get("value");
          }
          // console.log(result, live.get("entering"));
          break;

        case property.get("isInherit"):
          result.push(`(0 * ${initial})`);
          break;

        default:
          result.push(
            computeToCalc(property, progress, output, initial, result)
          );
          break;
      }
    }
  }
  // console.log(result);
  return {
    result,
    priority,
    initial,
  };
};

export default loopValueTriggers;
