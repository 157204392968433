import interactiveDelta from "./interactive-delta";

/**
 * Interactive delta (scroll/mouse)
 * @param {import("../../../store/utils/process-options").OptionsMapProps} options
 * @param {String} listener
 * @example `<method: delta(20)>`
 */
const interactiveDeltaAbsolute = (data, listener) => {
  const x = Math.abs(interactiveDelta(data, listener).x);
  const y = Math.abs(interactiveDelta(data, listener).y);
  const both = (x + y) / 2;

  return {
    x,
    y,
    both,
  };
};

export default interactiveDeltaAbsolute;