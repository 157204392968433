import WINDOW from "../../../../utils/window";
import interactiveDeltaAbsolute from "./interactive-delta-absolute";
import interactiveDelta from "./interactive-delta";
import pointerContinuous from "./pointer-continuous";
import pointerDefault from "./pointer-default";
import pointerFacing from "./pointer-facing";
import pointerMiddle from "./pointer-middle";
import pointerParallax from "./pointer-parallax";
import pointerSelf from "./pointer-self";
import pointerSelfContinuous from "./pointer-self-continuous";
import pointerSelfParallax from "./pointer-self-parallax";

/**
 * Get pointer percents
 * @param {import("../../../../utils/process-values-in-resolutions").PropertyMapProps} data
 * @param {import("../../../store/utils/get-trigger").TriggerProps} trigger
 * @param {import("../../../bounds/set-bounds").BoundsProps} bounds
 */
const getPointerPercents = (data, trigger, bounds) => {
  /** @type {import("../../../../utils/window").PointerEventType} */
  const event = WINDOW.pointer;
  const height = WINDOW.height;
  const width = WINDOW.width;

  /** @type {import("../../../store/utils/process-options").OptionsMapProps} */
  const options = data.get("options");

  /** @type {import("../../../store/utils/process-trigger-parameters").AxisType} */
  const axis = trigger.parameters.axis;

  switch (data.get("r_method")) {
    case "delta":
      return interactiveDelta(data, "pointer")[axis];

    case "delta-absolute":
      return interactiveDeltaAbsolute(data, "pointer")[axis];

    case "middle":
      return pointerMiddle(width, height, event)[axis];

    case "continuous":
      return pointerContinuous(width, height, event)[axis];

    // TODO: merge `pointerSelf` and `pointerSelfContinuous` because they share the same logic
    case "self":
      return pointerSelf(bounds, event, data)[axis];

    case "self-continuous":
      return pointerSelfContinuous(bounds, event, data)[axis];

    case "self-parallax":
      return pointerSelfParallax(bounds, event, data)[axis];

    case "parallax":
      return pointerParallax(width, height, event)[axis];

    case "facing":
      return pointerFacing(bounds, event, options)[axis];

    default:
      return pointerDefault(event, data, width, height, trigger)[axis];
  }
};

export default getPointerPercents;
