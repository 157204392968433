import G from "../../utils/globals";
import runEvent from "./run-event";

/**
 * Runs the specified event for affected elements based on the item.
 * If the item is a target element, it runs the event for each element in the associated set of targets.
 * Otherwise, it runs the event for the item itself.
 * @param {import("../Main").MainClassProps} main - The item for which the event is being run or the target element.
 * @param {String} event - The event name.
 * @param {String} type - The type of event (e.g., "enter" or "leave").
 * @param {Object} [data] - Details about the event.
 * @param {String} data.name - Name of the specific trigger event.
 */
const runAffectedElements = (main, event, type, detail, exact) => {
  if (main.isTarget) {
    /**
     * If item is a target element, get the associated set of targets
     * @type {Set}
     */
    const set = G.targets.get(main.element);

    /** Iterate through each `data` in the set */
    for (const data of set) {
      const element = data[1].main;
      /** Run the event for each element in the set */
      runEvent(element, event, type, detail, exact);
    }
  } else {
    /** If item is not a target element, run the event for the item itself */
    runEvent(main, event, type, detail, exact);
  }
};

export default runAffectedElements;