/**
 * Get dynamic progress
 * @param {Number} percents
 * @param {import("../../../utils/process-values-in-resolutions").PropertyMapProps} data
 * @param {import("../../store/utils/get-trigger").TriggerProps} trigger
 * @param {Number} percents
 */

import round from "../../../../utils/round";

/**
 *
 * @param {import("../../../../utils/process-values-in-resolutions").PropertyMapProps} data
 * @param {*} mode
 * @param {*} percents
 * @param {*} trigger
 * @returns
 */
const progressDynamic = (data, percents) => {
  let progress;

  const easing = data.get("r_easing");
  // console.log(data);
  const result = easing(percents);

  if (data.get("mode").enter) {
    progress = data.get("lastLeaveProgress") + (1 - data.get("lastLeaveProgress")) * result;
    data.set("lastEnterProgress", progress);
  }

  if (data.get("mode").leave) {
    progress = data.get("lastEnterProgress") - data.get("lastEnterProgress") * result;
    data.set("lastLeaveProgress", progress);
  }
// console.log(progress, percents);
  return data.get("r_reversed") ? round(1 - progress) : round(progress);
};

export default progressDynamic;
