import splitStringByIndexes from "./split-string-by-indexes";

const splitByTopLevelSeparator = (input, separator) => {
  const letters = input.split("");
  const indexes = [];

  let cluster = 0;
  let index = 0;

  for (let letter of letters) {
    if (letter === "[") {
      ++cluster;
    } else if (letter === "]") {
      --cluster;
    } else if (letter === separator && !cluster) {
      indexes.push(index);
    }

    ++index;
  }

  return splitStringByIndexes(input, indexes);
}

export default splitByTopLevelSeparator;