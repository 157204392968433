import G from "../../utils/globals";
import setPlatform from "../../utils/set-platform";
import WINDOW from "../../utils/window";
import applyAsyncReferences from "../store/utils/apply-async-references";

/**
 * Run actions on `DOMContentLoader` event
 */
const onDom = () => {
  requestAnimationFrame(() => {
    /**
     * Check if the current framework should postpone the initialization and animation start.
     * For example, the Svelte re-renders the page on load twice, causing the animation to also start twice.
     * This condition will skip the various trigger initialization and animation start, so they can
     * start when they are called from the framework external script.
     */
    if (!WINDOW.postpone[WINDOW.framework]) {
      applyAsyncReferences(G.triggers.animate.values());
    }

    /** Platform-specific classes to `body` */
    setPlatform();

    /** Disconnect the mutation (watching for changes on [data-tor] elements) observer */
    WINDOW.observers.mutation.disconnect();

    /** Remove the reference to observer */
    WINDOW.observers.mutation = null;

    /** Remove the `DOMContentLoaded` listener */
    window.removeEventListener("DOMContentLoaded", onDom);
  });
};

export default onDom;
