const splitStringByIndexes = (str, indexes) => {
  indexes.sort((a, b) => a - b);
  let result = [];
  let start = 0;

  for (let index of indexes) {
    result.push(str.slice(start, index));
    start = index + 1;
  }

  result.push(str.slice(start));
  return result;
};

export default splitStringByIndexes;
