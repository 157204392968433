import G from "./globals";

const getSpecificationDefault = (name) => {
  const option = G.optionsDefaults[name];

  if (option) {
    return G.optionsDefaults[name].value;
  } else {
    return null;
  }
}

export default getSpecificationDefault;