/**
 * Reset `status` and `lastPercents` when mode changes (from enter to leave and vice versa)
 * @param {import("../../store/utils/get-trigger").TriggerProps} trigger
 * @param {import("../../../utils/process-values-in-resolutions").PropertyMapProps} data
 */
const checkModeChange = (trigger, data, parent) => {
  if (
    trigger.modeChange ||
    trigger.stepChange ||
    trigger.entering !== data.get("lastEntering")
  ) {
    data.set("status", "");
    data.set("alternateFinished", false);
  }

  if (trigger.modeChange) {
    data.set("startTime", null);
    data.set("fromTrigger", true);

    parent.referenceStarted = false;
  }
};

export default checkModeChange;
