import WINDOW from "../../../utils/window";
import indexReplaceAll from "../../../utils/index-replace-all";
import loopTriggerParameters from "./loop-trigger-parameters";

/**
 * @typedef {"x" | "y" | "both" | null} AxisType
 */

/**
 * @typedef {String | Boolean | (HTMLElement | null)[] | NodeListOf<any>} TriggerParameterValueProps
 */

/**
 * @typedef {Object} TriggerParametersProps
 * @property {Element|null} target
 * @property {Boolean} page
 * @property {Boolean} keep
 * @property {"enter"|"leave"|"both"} mode
 * @property {"x"|"y"|"both"} axis
 * @property {import("./get-trigger").OffsetProps} start
 * @property {import("./get-trigger").OffsetProps} end
 */

/**
 * Process trigger parameters string
 * @param {String|null} input
 * @param {import("./get-trigger-info").TriggerInfoProps} triggerInfo
 * @param {Element} element
 * @example `enter,.h1▄#test,10%,40%`
 * @return {TriggerParametersProps}
 */
const processTriggerParameters = (input, triggerInfo, element) => {
  /** @type {TriggerParametersProps} */
  const result = {
    target: null,
    page: false,
    keep: false,
    mode: "both",
    start: {},
    end: {},
    axis: triggerInfo.pointer ? "both" : "y",
  };

  /** If the trigger is timeout, set `value` instead of offset */
  if (triggerInfo.timeout) {
    result.end.value = null;
  }

  /** Default offsets for the "interactive" and viewport-based triggers */
  if (triggerInfo.interactive || triggerInfo.viewport) {
    result.start = {
      x: [0],
      y: [0],
    };

    result.end = {
      x: [WINDOW.width],
      y: [WINDOW.height],
    };
  }

  /** If no custom parameters, return the default ones */
  if (!input) {
    return result;
  }

  /**
   * If parameters are in brackets (offsets, etc), replace all commas with pipes,
   * because the other parameters are divided also by a comma
   */
  if (input.indexOf("]") > 0) {
    input = input.replace(/\[(.*?)\]/g, (match) =>
      indexReplaceAll(match, { ",": "|" })
    );
  }

  /** Divide all parameters with comma */
  const parameters = input.split(",");

  /** Offsets keywords */
  const offsets = ["start", "end", "offset"];

  /** Check if offset should be calculated related to the whole document page, instead of screen */
  const isPage = parameters.some((item) => item === "page˸true");


  return loopTriggerParameters(
    parameters,
    element,
    offsets,
    isPage,
    triggerInfo,
    result
  );
};

export default processTriggerParameters;
