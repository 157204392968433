import getIterableElements from "../utils/get-iterable-elements";
import getWindowScroll from "../utils/get-window-scroll";
import G from "../utils/globals";
import setWindowData from "../utils/set-window-data";
import WINDOW from "../utils/window";
import staticDestroy from "./static/static-destroy";
import staticInit from "./static/static-init";

/**
 * @typedef {Object} MainClassProps
 * @property {Function} init
 * @property {Function} createStore
 * @property {Function} setInitials
 * @property {Function} setCustomEvents
 * @property {HTMLElement} element
 * @property {Object} activeTriggers
 * @property {Object} customEvents
 * @property {Object} triggers
 * @property {Object} computedStyle
 * @property {Object} dataset
 * @property {Object} variables
 * @property {Object} store
 * @property {Object} initials
 * @property {import("./bounds/set-bounds").BoundsProps} bounds
 * @property {import("./defaults/defaults").ShiftType} shift
 * @property {Set<String|Number>} activeTriggersNames
 * @property {Set<String>} activeTriggersSet
 * @property {CSSStyleDeclaration} style
 * @property {setSpecificListeners: Main.setSpecificListeners} Method for managing specific listeners.
 * @property {Boolean} visible
 * @property {Boolean} isBoundsElement
 * @property {Object} processAction
 * @property {Function} setAction Run various actions
 * @property {Object} Main.prototype.computeProperties
 * @property {Function} addToActiveTriggers
 */

/**
 * @typedef {Object} TorusTypeProps
 * @property {MainClassProps} Main - The main property of Torus.
 */

/**
 * @typedef {Element & { Torus: TorusTypeProps }} TorusElement - Extending Element to include the Torus property.
 */

/**
 * Initializes a new instance of Main.
 * @async
 * @module Main
 * @namespace Main.prototype
 * @property {Object} Main.prototype.computeProperties
 * @returns {Promise<Object>}
 */
class Main {
  /**
   * @this MainClassProps
   * @param {HTMLElement} element
   */
  constructor(element) {
    /**
     * The target DOM element undergoing mutation.
     * @type {Element}
     */
    this.element = element;
    this.defaults();
  }

  /**
   * Destroy method
   * @param {String | Array | Set | NodeList} elements
   */
  static destroy(elements) {
    staticDestroy("Main", "[data-tor]", elements);
  }

  static async init(elements, options) {
    /** Check if the  */
    if (WINDOW.scroll.y === null) {
      getWindowScroll();
      setWindowData();
    }

    return await staticInit("Main", Main, elements, options, "tor");
  }
}

export default Main;
