import getParameterUnit from "./get-parameter-unit";

/**
 *
 * @param {String} input
 * @param {Element} element
 */
const getParameterShorthand = (input, element) => {
  switch (true) {
    case input === "enter":
      return {
        name: "mode",
        value: "enter",
        enter: true,
      };

    case input === "leave":
      return {
        name: "mode",
        value: "leave",
        leave: true,
      };

    case input === "keep":
      return {
        name: "keep",
        value: true,
      };

    case input === "parent":
      return {
        name: "target",
        value: [element.parentElement],
      };

    case input.startsWith(".") || input.startsWith("#"):
      // Incompatible with start/end offset definition `start: [0, 40%]`
      // input.startsWith("["):
      return {
        name: "target",
        value: document.querySelectorAll(input.replaceAll("▄", " ")),
      };

    case input.endsWith("%") || input.endsWith("px") || getParameterUnit(input):
      return {
        name: "offset",
        value: input,
      };

    case input === "x" || input === "y" || input === "both":
      return {
        name: "axis",
        value: input,
      };
  }
};

export default getParameterShorthand;
