const getStatus = (active, step, status) => {
  const result = {
    started: false,
    finished: false,
    done: false,
  }

  if (active.size) {
    if (!status.started) {
      status.started = true;
      status.finished = false;
      result.started = true;
    }
  } else {
    if (!status.finished) {
      status.finished = true;
      status.started = false;
      result.finished = true;
    }
  }

  return result;
}

export default getStatus;
