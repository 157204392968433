/**
 * Merge objects (options)
 * @param {Object} parent Parent object
 * @param {Object} children Children object
 */
const mergeObjects = (parent, children) => {
  const result = new Map();

  for (const name of children.keys()) {
    /** If the step option is default (not the custom one) */
    if (children.get(name)) {
      if (children.get(name).get("isCustom")) {
        result.set(name, children.get(name));
      } else {
        result.set(name, parent.get(name));
      }
    } else {
      result.set(name, parent.get(name));
    }
  }

  // const result = {};

  // for (const name in parent) {
  //   /** If the step option is default (not the custom one) */
  //   if (children[name]) {
  //     if (children[name].isCustom) {
  //       result[name] = children[name];
  //     } else {
  //       result[name] = parent[name];
  //     }
  //   } else {
  //     result[name] = parent[name];
  //   }
  // }

  return result;
}

export default mergeObjects;