import deepObjectToMap from "./deep-object-to-map";
import bezier from "./easing-bezier";

/**
 * Global object
 * @namespace G
 */
const G = {};

/**
 * ------------------------------------------------------------------------
 * Variables
 * ------------------------------------------------------------------------
 */

G.variables = {};

/**
 * ------------------------------------------------------------------------
 * Aliases
 * @typedef {Object} Alias
 * @property {string} R - Replacer - the string that replaces the original string attribute
 * @property {string} A - Addition - composition addition `(+)/(-)`
 * @property {string} V - Value - the value that should be replaced in parenthesis
 * @property {string} O - Options - default options that can be extended
 * @property {Array<string>} I - Initial - initial attributes
 * @property {Array<string>} G - Group - Group of replacers
 * @property {Object} CV - Custom value - Additional value
 * @property {string} CV.name - Custom value name
 * @property {string} CV.value - Custom value value
 * ------------------------------------------------------------------------
 */

/**
 * Object containing aliases for actions.
 * @type {Object<string, Alias>}
 */
G.aliases = {
  "push-up": {
    R: "ty(-«V»<«O»>)",
    // A: "-",
    V: "0.5rem",
    // V: "--tor-sm",
  },
  "push-down": {
    R: "ty(«V»<«O»>)",
    V: "0.5rem",
    // V: "--tor-sm",
  },
  "push-left": {
    R: "tx(-«V»<«O»>)",
    // A: "-",
    V: "0.5rem",
    // V: "--tor-sm",
  },
  "push-right": {
    R: "tx(«V»<«O»>)",
    V: "0.5rem",
    // V: "--tor-sm",
  },
  "pull-up": {
    R: "ty(0rem<«O»>)",
    I: ["ty(«V»)"],
    V: "0.5rem",
    // V: "--tor-sm",
  },
  "pull-down": {
    R: "ty(0rem<«O»>)",
    I: ["ty(-«V»)"],
    V: "0.5rem",
    // V: "--tor-sm",
  },
  "pull-left": {
    R: "tx(0rem<«O»>)",
    I: ["tx(«V»)"],
    V: "0.5rem",
    // V: "--tor-sm",
  },
  "pull-right": {
    R: "tx(0rem<«O»>)",
    I: ["tx(-«V»)"],
    V: "0.5rem",
    // V: "--tor-sm",
  },
  grow: {
    R: "s(1<«O»>)",
    I: ["s(«V»)"],
    V: "0",
  },
  shrink: {
    R: "s(1<«O»>)",
    I: ["s(«V»)"],
    V: "2",
  },
  shadow: {
    variants: {
      sm: {
        R: "bs(0px▄2px▄4px▄0px▄rgba(0,0,0,0.15))",
      },
      md: {
        R: "bs(0px▄4px▄6px▄0px▄rgba(0,0,0,0.2))",
      },
      lg: {
        R: "bs(0px▄8px▄16px▄0px▄rgba(0,0,0,0.25))",
      },
      xl: {
        R: "bs(0px▄16px▄48px▄0px▄rgba(0,0,0,0.3))",
      },
    },
  },
  "reveal-path": {
    R: "stroke-dasharray($length▄$length<«O»>)",
    I: ["$length(get(length))", "stroke-dasharray(0▄$length)"],
  },
  "translate-along-path": {
    G: ["tx($length<«O»>)", "ty($length<«O»>)", "r(0deg<«O»>)"],
    I: ["$length(get(length))"],
  },
  "clip-right": {
    R: "clip-path(polygon(0%▃0%,calc(100%▂+▂«V»)▃0%,100%▃100%,0%▃100%)<«O»>)",
    I: ["clip-path(polygon(0%▃0%,0%▃0%,calc(0%▂-▂«V»)▃100%,0%▃100%))"],
    V: "0%",
  },
  "clip-left": {
    R: "clip-path(polygon(calc(0%▂-▂«V»)▃0%,100%▃0%,100%▃100%,0%▃100%)<«O»>)",
    I: [
      "clip-path(polygon(100%▃0%,100%▃0%,calc(100%▂+▂«V»)▃100%,calc(100%▂+▂«V»)▃100%))",
    ],
    V: "0%",
  },
  "clip-up": {
    R: "clip-path(polygon(0%▃calc(0%▂-▂«V»),100%▃0%,100%▃100%,0%▃100%)<«O»>)",
    I: ["clip-path(polygon(0%▃100%,100%▃calc(100%▂+▂«V»),100%▃100%,0%▃100%))"],
    V: "0%",
  },
  "clip-down": {
    R: "clip-path(polygon(0%▃calc(100%▂+▂«V»),100%▃100%,100%▃0%,0%▃0%)<«O»>)",
    I: ["clip-path(polygon(0%▃0%,100%▃calc(0%▓-▂«V»),100%▃0%,0%▃0%))"],
    V: "0%",
  },
  "reveal-up": {
    G: ["clip-down(<«O»>)", "pull-up(100%<«O»>)"],
  },
  "reveal-down": {
    G: ["clip-up(<«O»>)", "pull-down(100%<«O»>)"],
  },
  "reveal-left": {
    G: ["clip-right(<«O»>)", "pull-left(100%<«O»>)"],
  },
  "reveal-right": {
    G: ["clip-left(<«O»>)", "pull-right(100%<«O»>)"],
  },
  "bg-lighten": {
    R: "bg(rgba((+)«V»,(+)«V»,(+)«V»,?)<«O»>)",
  },
  "bg-darken": {
    R: "bg(rgba((-)«V»,(-)«V»,(-)«V»,?)<«O»>)",
  },
  "bg-opacity": {
    R: "bg(rgba(?,?,?,«V»)<«O»>)",
  },
  "parallax-y": {
    R: "ty(-«V»<«O»>)",
    I: ["ty(«V»)"],
    O: "methodːcontinuous",
  },
  "parallax-x": {
    R: "tx(«V»<«O»>)",
    I: ["tx(-«V»)"],
    O: "methodːcontinuous",
  },

  /** Loops */
  jump: {
    R: "[0%_[ty(0rem)<easingːbezier(0.3,0,0.7,0)>],50%_[ty(-«V»)],100%_[ty(0rem<spring>)]<infinite,«O»>]",
    V: "1rem",
    O: "1.5s",
    L: 1,
  },
  spin: {
    R: "r(«V»<linear,infinite,«O»>)",
    V: "360deg",
    O: "2s",
    L: 1,
  },
  blink: {
    R: "o(«V»<alternate,infinite,«O»>)",
    V: "0",
    O: "0.5s",
    L: 1,
  },
  fly: {
    R: "ty(«V»<alternate,infinite,«O»>)",
    I: ["ty(-«V»)"],
    V: "1rem",
    O: "1s",
    L: 1,
  },
  pulse: {
    R: "s(calc(1▃+▃«V»)<alternate,infinite,«O»>)",
    I: ["s(calc(1▃-▃«V»))"],
    V: "0.2",
    O: "1s",
    L: 1,
  },
  swing: {
    R: "r(«V»<alternate,infinite,«O»>)",
    I: ["r(-«V»)"],
    V: "25deg",
    O: "1s",
    L: 1,
  },
  "wobble-h": {
    R: "tx(«V»<alternate,infinite,«O»>)",
    I: ["tx(-«V»)"],
    V: "1rem",
    O: "1s",
    L: 1,
  },
  "wobble-v": {
    R: "ty(«V»<alternate,infinite,«O»>)",
    I: ["ty(-«V»)"],
    V: "1rem",
    O: "1s",
    L: 1,
  },
  radiate: {
    R: "bs(0px▄0px▄0px▄«CV»▄rgba(?,?,?,0)<1s,infinite,«O»>)",
    I: ["bs(0px▄0px▄0px▄0px▄«V»)"],
    V: "rgba(0,0,0,1)",
    O: "1s",
    L: 1,
    CV: {
      name: "spread",
      value: "1rem",
    },
  },
  tada: {
    R: "[[s(calc(1▃-▃«V»)<0.5s>)]░[s(calc(1▃+▃«V»))█r(calc(-100deg▃*▃«V»))]░[r(calc(100deg▃*▃«V»)<2x,alternate>)]░[s(calc(1)<1s,spring>)█r(calc(0deg)<.25s>)]<infinite,«O»>]",
    V: "0.1",
    L: 1,
    O: "0.5s",
  },
  move: {
    R: "tx(100%<infinite,«O»>)",
    I: ["tx(-100%)"],
    O: "2s",
    L: 1,
  },
  orbit: {
    // R: "r(360deg<linear,infinite,«O»>)",
    G: [
      "r(360deg<linear,infinite,orderː1,«O»>)",
      "tx(«V»<0s,linear,orderː2>)",
      "rz((-)360deg<linear,infinite,orderː3,«O»>)",
    ],
    I: ["transform-origin(50%)", "r(0deg)", "tx(«V»)"],
    V: "1rem",
    O: "2s",
    L: 1,
  },
};

/**
 * ------------------------------------------------------------------------
 * Object containing sets of characters that precede and follow specific characters in strings.
 * @namespace G.replace
 * ------------------------------------------------------------------------
 */
G.replace = {
  /**
   * Set of characters that can precede specific characters in strings.
   * @type {Set<string>}
   */
  prev: new Set(["[", "|", ",", "ː", ":", "<", ">", "˸", "▹", "("]),

  /**
   * Set of characters that can follow specific characters in strings.
   * @type {Set<string>}
   */
  next: new Set(["]", "|", ",", "ː", "<", ">", ":", "˸", "▹", " ", ")", "="]),
};

/**
 * ------------------------------------------------------------------------
 * Replace resolutions
 * ------------------------------------------------------------------------
 */

G.replaceResolutions = {
  "xs::": "✦xs▹",
  "sm::": "✦sm▹",
  "md::": "✦md▹",
  "lg::": "✦lg▹",
  "xl::": "✦xl▹",
  "xxl::": "✦xxl▹",
};

/**
 * ------------------------------------------------------------------------
 * Actions
 * ------------------------------------------------------------------------
 */

G.actionTypes = new Set(["get", "set", "add", "toggle", "remove", "replace"]);
G.actionBoundTypes = new Set([
  "height",
  "width",
  "top",
  "left",
  "right",
  "bottom",
]);

/**
 * ------------------------------------------------------------------------
 * Easing types
 * ------------------------------------------------------------------------
 */

G.easingTypes = new Map([
  ["linear", "bezier(∫0⊗0⊗1⊗1)"],
  ["ease-in", "bezier(∫0.4⊗0⊗1⊗1)"],
  ["ease-out", "bezier(∫0⊗0⊗0.6⊗1)"],
  ["ease-in-out", "bezier(∫0.4⊗0⊗0.6⊗1)"],
  ["ease-in-expo", "bezier(∫0.7⊗0⊗0.84⊗0)"],
  ["ease-out-expo", "bezier(∫0.16⊗1⊗0.3⊗1)"],
  ["ease-in-out-expo", "bezier(∫0.87⊗0⊗0.13⊗1)"],
]);

/**
 * ------------------------------------------------------------------------
 * Pointer methods that triggers scroll update
 * ------------------------------------------------------------------------
 */

G.pointerScrollMethods = new Set(["self", "self-continuous", "facing"]);

/**
 * ------------------------------------------------------------------------
 * Depth level separators
 * ------------------------------------------------------------------------
 */

G.depthSeparators = {
  0: "▄",
  1: ",",
  2: "▃",
  3: "▂",
  4: "▁",
};

/**
 * ------------------------------------------------------------------------
 * Operators
 * ------------------------------------------------------------------------
 */

G.operators = ["+", "-", "*", "/"];

/**
 * ------------------------------------------------------------------------
 * Resolution separators
 * ------------------------------------------------------------------------
 */

G.resolutionSeparators = [
  {
    start: "⑴",
    end: "Ⓐ",
  },
  {
    start: "⑵",
    end: "Ⓑ",
  },
  {
    start: "⑶",
    end: "Ⓒ",
  },
  {
    start: "⑷",
    end: "Ⓓ",
  },
];

/**
 * ------------------------------------------------------------------------
 * Separators and characters that marks that the value is inside the function
 * ------------------------------------------------------------------------
 */

G.functionCharacters = [...Object.values(G.depthSeparators), "("];

/**
 * ------------------------------------------------------------------------
 * Resolutions
 * @typedef {'xxl' | 'xl' | 'lg' | 'md' | 'sm' | 'all'} ResolutionNamesProps
 * ------------------------------------------------------------------------
 */

G.resolutions = {
  xxl: 1400,
  xl: 1200,
  lg: 992,
  md: 768,
  sm: 576,
  all: 0,
};

/**
 * ------------------------------------------------------------------------
 * Shorthands
 * ------------------------------------------------------------------------
 */

G.shorthands = {
  transform: {
    tx: "translateX",
    ty: "translateY",
    tz: "translateZ",
    t3d: "translate3d",

    s: "scale",
    sx: "scaleX",
    sy: "scaleY",
    s3d: "scale3d",

    r: "rotate",
    rz: "rotateZ",
    rx: "rotateX",
    ry: "rotateY",
    r3d: "rotate3d",

    sk: "skewX",
    skx: "skewX",
    sky: "skewY",
  },

  other: {
    bg: "background-color",
    bgi: "background-image",
    bbm: "background-blend-mode",
    o: "opacity",

    bs: "box-shadow",
    ts: "text-shadow",
    br: "border-radius",

    lg: "linear-gradient",
    rg: "radial-gradient",
    cg: "conic-gradient",
    z: "z-index",

    p: "padding",
    pt: "padding-top",
    pb: "padding-bottom",
    pr: "padding-right",
    pl: "padding-left",

    m: "margin",
    mt: "margin-top",
    mb: "margin-bottom",
    mr: "margin-right",
    ml: "margin-left",

    to: "transform-origin",
  },
};

G.parents = {
  transform: new Set(Object.values(G.shorthands.transform)),
};

/**
 * ------------------------------------------------------------------------
 * Named (index) properties
 * ------------------------------------------------------------------------
 */
G.namedProperties = new Set(["transform", "filter", "backdrop-filter"]);

/**
 * ------------------------------------------------------------------------
 * Multi-index properties
 * Includes multiple values in parenthesis
 * @example `box-shadow(0px 5px 8px rgba(0,0,0,0.1))`
 * ------------------------------------------------------------------------
 */
G.multiIndex = new Set(["box-shadow"]);

/**
 * ------------------------------------------------------------------------
 * References
 * ------------------------------------------------------------------------
 */
// TODO: needs to be cleared on every reload
G.references = {
  /** All resolutions calculated on resize */
  resolutions: new Set(),
  /** All trigger resolution calculated on resize */
  triggers: new Set(),
  /** All regular references defined with `@` */
  regular: {},
  /** Initial triggers references */
  initials: new Set(),

  options: new Set(),
};

G.optionsReferences = {
  regular: new Set([
    "iterations",
    "direction",
    "duration",
    "easing",
    "pause",
    "delay",
    "order",
  ]),
  boolean: {
    spring: {
      type: "fn",
      name: "easing",
    },
    reversed: {
      type: "value",
      name: "direction",
    },
    alternate: {
      type: "value",
      name: "direction",
    },
    infinite: {
      type: "value",
      name: "iterations",
    },
  },
};

/**
 * ------------------------------------------------------------------------
 * Separators
 * ------------------------------------------------------------------------
 */

G.separators = new Map([["linear-gradient", ","]]);

/**
 * ------------------------------------------------------------------------
 * Defaults for `filter` and `backdrop-filter`
 * ------------------------------------------------------------------------
 */

G.filterDefaults = {
  blur: "blur(0px)",
  brightness: "brightness(0)",
  contrast: "contrast(0%)",
  drop: "drop-shadow(0px 0px 0px rgba(0,0,0,0))",
  grayscale: "grayscale(0%)",
  invert: "invert(0%)",
  opacity: "opacity(0%)",
  saturate: "saturate(0%)",
  sepia: "sepia(0%)",
  url: "url()",
  "hue-rotate": "hue-rotate(0deg)",
};

/**
 * ------------------------------------------------------------------------
 * Defaults
 * ------------------------------------------------------------------------
 */

G.defaults = {
  filter: G.filterDefaults,
  "backdrop-filter": G.filterDefaults,
  transform: {
    matrix: "matrix(1,0,0,1,0,0)",
    matrix3d: "matrix3d(1,0,0,0,0,1,0,0,0,0,1,0,0,0,0,1)",
    perspective: "perspective(0px)",
    rotate: "rotate(0deg)",
    rotate3d: "rotate3d(1, 1, 1, 0deg)",
    rotateX: "rotateX(0deg)",
    rotateY: "rotateY(0deg)",
    rotateZ: "rotateZ(0deg)",
    translate: "translate(0px,0px)",
    translate3d: "translate3d(0px,0px,0px)",
    translateX: "translateX(0px)",
    translateY: "translateY(0px)",
    translateZ: "translateZ(0px)",
    scale: "scale(1,1)",
    scale3d: "scale3d(1,1,1)",
    scaleX: "scaleX(1)",
    scaleY: "scaleY(1)",
    scaleZ: "scaleZ(1)",
    skew: "skew(0deg,0deg)",
    skewX: "skewX(0deg)",
    skewY: "skewY(0deg)",
  },
  "background-color": {
    0: "rgb(0,0,0)",
  },
  "box-shadow": {
    0: "0px",
    1: "0px",
    2: "0px",
    3: "0px",
    4: "rgba(0,0,0,0)",
  },
};

/**
 * ------------------------------------------------------------------------
 *
 * ------------------------------------------------------------------------
 */

G.optionsDefaults = {
  delta: {
    string: "∫10",
    value: [10],
  },
  "delta-absolute": {
    string: "∫10",
    value: [10],
  },
  spring: {
    string: "∫5⊗10",
    value: [5, 10],
  },
};

/**
 * ------------------------------------------------------------------------
 * Options
 * ------------------------------------------------------------------------
 */

export const optionsObject = {
  duration: {
    current: {
      value: 350,
      unit: "ms",
      isNumber: true,
      isFn: false,
    },
    isCustom: false,
  },
  pause: {
    current: {
      value: 0,
      unit: "ms",
      isNumber: true,
      isFn: false,
    },
    isCustom: false,
  },
  delay: {
    current: {
      value: 0,
      unit: "ms",
      isNumber: true,
      isFn: false,
    },
    isCustom: false,
  },
  easing: {
    current: {
      value: bezier(0.75, 0, 0.25, 1),
      unit: null,
      isString: false,
      isFn: true,
      fn: "bezier",
    },
    isCustom: false,
  },
  iterations: {
    current: {
      value: 0,
      unit: null,
      isNumber: true,
      isFn: false,
    },
    isCustom: false,
  },
  direction: {
    current: {
      value: "normal",
      unit: null,
      isString: true,
      isFn: false,
    },
    isCustom: false,
  },
  start: {
    current: {
      value: 0,
      unit: "%",
      output: "0%",
      isNumber: true,
      isFn: false,
    },
    isCustom: false,
  },
  end: {
    current: {
      value: 100,
      unit: "%",
      output: "100%",
      isNumber: true,
      isFn: false,
    },
    isCustom: false,
  },
  method: {
    current: {
      value: null,
      fn: null,
      unit: null,
      isNumber: false,
      isFn: false,
    },
    isCustom: false,
  },
  "method-init": {
    current: {
      value: "start",
      unit: null,
      isString: true,
      isFn: false,
    },
    isCustom: false,
  },
  reverse: {
    current: {
      value: false,
    },
    isCustom: false,
  },
  "skip-first": {
    current: {
      value: false,
    },
    isCustom: false,
  },
  order: {
    current: {
      value: 0,
      unit: null,
      isNumber: true,
      isFn: false,
    },
    isCustom: false,
  },
};

/**
 * ------------------------------------------------------------------------
 * All elements except animations
 * ------------------------------------------------------------------------
 */

G.elements = {};
G.elements.all = new Map();
G.elements.Main = new Set();
G.elements.Group = new Set();
G.elements.Container = new Set();
G.elements.Target = new Set();

G.elements.active = new Set();
G.elements.running = new Map();

/**
 * ------------------------------------------------------------------------
 * Triggers
 * ------------------------------------------------------------------------
 */

G.triggers = {};
G.triggers.active = new Map();
G.triggers.viewport = new Map();
G.triggers.animate = new Map();
G.triggers.class = new Map();
G.triggers.timeout = new Map();
G.triggers.scroll = new Map();
G.triggers.pointer = new Map();
G.triggers.initial = new Set();
G.triggers.running = new Set();

/**
 * ------------------------------------------------------------------------
 * All group elements
 * ------------------------------------------------------------------------
 */

G.groups = {};
G.groups.all = new Map();
G.groups.visible = new Set();
G.groups.invisible = new Set();

/**
 * ------------------------------------------------------------------------
 * Targets
 * ------------------------------------------------------------------------
 */

G.targets = new Map();

/**
 * ------------------------------------------------------------------------
 * Containers
 * ------------------------------------------------------------------------
 */

G.containers = {};
/**
 * Container HTML element set
 * @type { Set<HTMLElement> }
 */
G.containers.elements = new Set();
/**
 * Container class set
 * @type { Set<import("../Container/Container").ContainerClass> }
 */
G.containers.all = new Set();
/**
 * Container observer
 * @type {IntersectionObserver}
 */
G.containers.observer = null;

/**
 * ------------------------------------------------------------------------
 * Represents a collection of utility functions and objects.
 * @module G
 * ------------------------------------------------------------------------
 */

/**
 * The default export of the G module, containing utility functions and objects.
 * @exports G
 */
export default G;
