import Main from "../Main";
import checkModeChange from "./utils/check-mode-change";
import getPercents from "./utils/get-percents";
import getProgress from "./utils/get-progress";
import checkReference from "./utils/check-reference";
import checkAnimateAction from "./utils/check-animate-action";
import checkInitials from "./utils/check-initials";
import getPercentsStatus from "./utils/get-percents-status";
import getPathPoint from "../../utils/get-path-point";

/**
 * Compute each individual property
 * @param {Object} properties
 * @param {import("../store/utils/get-trigger").TriggerProps} trigger
 * @param {import("../store/utils/process-parent").ParentProps} parent - Parent object
 * @param {String} parentName - Parent name
 * @param {import("../store/utils/get-trigger").TriggerModeProps} triggerMode
 * @this {import("../Main").MainClassProps}
 * @returns {Object}
 */
Main.prototype.computeProperties = function (
  properties,
  trigger,
  parent,
  parentName,
  triggerMode
) {
  /** Status result empty object */
  const result = {
    started: false,
    finished: false,
  };

  /**
   * Loop each property in the current step properties
   */
  for (const name in properties) {
    /** @type {String} */
    let status;

    /** @type {Number} */
    let percents;

    /** @type {Number} */
    let progress;

    /** @type {import("../../utils/process-values-in-resolutions").PropertyMapProps} */
    const data = properties[name].current;

    /** @type {Boolean} */
    const spring = data.get("r_spring");

    /** @type {import("../bounds/set-bounds").BoundsProps} */
    const bounds = this.bounds;

    /** @type {import("../../utils/process-values-in-resolutions").LiveProps} */
    const live = data.get("live");

    const activeName = data.get("activeName");

    let mode = {
      enter: triggerMode.enter,
      leave: triggerMode.leave,
    }

    if (data.get("r_alternate")) {
      data.set("mode", mode);
    }

    /**
     * Prevents from running animation from 1 to 0
     * when animation finished in alternate direction and
     * user, for example, hovers out
     */
    if (data.get("alternateFinished") && mode.leave) {
      return "finished";
    }

    if (!trigger.interactive) {
      checkModeChange(trigger, data, parent);

      if (data.get("status") == "finished") {
        continue;
      }
    }

    percents = getPercents(data, trigger, spring, bounds, mode);
    progress = getProgress(data, trigger, percents);
    status = getPercentsStatus(data, trigger, mode);

    if (data.get("paused")) {
      continue;
    }
    if (data.get("delayed")) {
      continue;
    }

    data.set("status", status);
    data.set("lastPercents", percents);
    data.set("progress", progress);
    data.set("lastEntering", trigger.entering);
    data.set("lastLeaving", trigger.leaving);
    data.set("entering", trigger.entering);
    data.set("leaving", trigger.leaving);

    live.set("progress", progress);
    live.set("entering", trigger.entering);
    live.set("leaving", trigger.leaving);
    live.set("status", status);

    if (data.get("r_path")) {
      const main = trigger.this;
      const path = getPathPoint(progress, data, main);

      live.set("translate-path", path);
      live.set("progress", 1);
    }

    checkReference(trigger, status, data, parent);
    checkAnimateAction(status, data, this, parent);
    checkInitials(status, parentName, data, name, this);

    if (status === "started") {
      trigger.activeProperties.add(activeName);
      trigger.timeline.info.active = true;
      result.started = true;
    }
    if (status === "finished") {
      trigger.activeProperties.delete(activeName);
      result.finished = true;
      data.set("startTime", null);

      if (mode.leave) {
        data.set("enterPercents", 0);
        data.set("lastEnterProgress", 0);
      }
    }

    if (status === "running") {
      trigger.activeProperties.add(activeName);
      trigger.timeline.info.active = true;
    }
    if (status === "idle") {
      trigger.activeProperties.delete(activeName);
    }
    if (status === "repeating") {
      data.set("startTime", null);
    }

    data.set("statusProgress", progress);
  }

  return result;
};

export default Main.computeProperties;
