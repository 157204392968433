import indexReplaceAll from "../../utils/index-replace-all";

const checkAndReplaceFirstLastAction = (elements, matchings, i, replaced) => {
  for (const match of matchings) {
    if (i === 0 && match.indexOf("first@") === 0) {
      replaced = replaced.replace("first@", "@");
    } else if (i === elements.length - 1 && match.indexOf("last@") === 0) {
      replaced = replaced.replace("last@", "@");
    } else {
      replaced = indexReplaceAll(replaced, {
        [match]: "",
        ",>": ">",
        "<,": "<",
        "<<": "<",
        "<>": "",
      });
    }
  }
};

export default checkAndReplaceFirstLastAction;
