import getMaxSide from "../../../../utils/get-max-side";
import WINDOW from "../../../../utils/window";
import getCenter from "../get-center";
import threshold from "../threshold";

/**
 * Pointer self continuous
 * @param {import("../../../bounds/set-bounds").BoundsProps} bounds
 * @param {import("../../../../utils/window").PointerEventType} event
 * @param {import("../../../../utils/process-values-in-resolutions").PropertyMapProps} data
 */
const pointerSelfContinuous = (bounds, event, data) => {
  const center = getCenter(bounds);
  const centerX = center.x;
  const centerY = center.y;

  const max = getMaxSide(bounds, WINDOW.width, WINDOW.height);
  const maxX = max.xSide;
  const maxY = max.ySide;

  bounds.maxXSide = maxX;
  bounds.maxYSide = maxY;

  const width = bounds.width;
  const height = bounds.height;

  let x = ((event.x - centerX) / threshold(data, maxX, width)) + 1;
  let y = ((event.y - centerY) / threshold(data, maxY, height)) + 1;
  let both = (x + y) / 2;

  x = Math.max(Math.min(x, 2), 0);
  y = Math.max(Math.min(y, 2), 0);

  both = Math.max(Math.min(both, 2), 0);

  return {
    x,
    y,
    both,
  };
};

export default pointerSelfContinuous;
