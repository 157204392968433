/**
 * Interactive delta oriented (scroll/mouse)
 * @param {Object} method
 * @param {String} listener
 */

import WINDOW from "../../../../utils/window";

/**
 * Interactive delta percents
 * @param {import("../../../../utils/process-values-in-resolutions").PropertyMapProps} data
 * @param {import("../../../../utils/window").PointerEventType} listener
 * @returns
 */
const interactiveDelta = (data, listener) => {
  const event = WINDOW[listener];
  const specification = 500 / data.get("r_methodValue");

  const x = (event.x - (event.lastRafX || event.x)) / specification;
  const y = (event.y - (event.lastRafY || event.y)) / specification;
  const both = (x + y) / 2;

  return {
    x,
    y,
    both,
  };
};

export default interactiveDelta;
