import processAliases from "../process-aliases";
import setAlias from "./set-alias";

/**
 *
 * @param {import("./get-aliases").AliasDefinition} item
 * @param {string} match
 * @param {string} result
 * @param {Set} initials
 */
const getReplacedResult = (item, match, result, initials) => {
  /** Has group of replacers */
  const replacers = item.definition.G;

  if (replacers) {
    const group = [];

    /** Loop all replacers */
    for (const replacer of replacers) {
      /** Get data for each replacer */
      const data = setAlias(match, item, replacer);

      /** Process replacer again, because it can include another replacer */
      const processed = processAliases(data.final)

      /** Add replaced string to the group */
      group.push(result.replace(match, processed));

      /** Add initial to the set */
      initials.add(data.initial);
    }

    /** Join all computed replacers in group */
    result = group.join(" ");
  } else {
    /** Returns the computed alias as a regular CSS property */
    const replaced = setAlias(match, item);

    /** Replace in original string */
    result = result.replace(match, replaced.final);

    /** Add initial property for current alias into set */
    initials.add(replaced.initial);
  }

  return result;
};

export default getReplacedResult;
