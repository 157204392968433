import G from "../../utils/globals";
import getInitialStyle from "./utils/get-initial-style";

/**
 *
 * @param {import("../Main").MainClassProps} main
 * @param {import("../store/utils/get-trigger").TriggerProps} trigger
 * @param {Array<import("../store/utils/get-steps").Step>} steps
 */
const setInitials = (main, trigger, steps) => {
  for (const item of steps) {
    for (const name in item.parents) {
      /** @type {import("../store/utils/process-parent").ParentProps} */
      const parent = item.parents[name];

      /** Only run action when it is initial trigger */
      if (parent.action) {
        if (trigger.initial) {
          main.setAction(name, parent);
        }

        continue;
      }

      // TODO:
      if (parent.reference) {
        continue;
      }

      const multi = parent.multi;

      main.initials[name] = main.initials[name] || {};

      let multiIndex = 0;

      for (const properties of multi) {
        for (const index in properties) {
          /** @type {import("../../utils/process-values-in-resolutions").PropertyMapProps} */
          const property = properties[index];

          /** @type {Number} */
          // let indexName;
          // indexName = index;

          main.initials[name][multiIndex] =
            main.initials[name][multiIndex] || {};

          /**
           * Trigger is initial
           * @example `tx(2rem)`
           */
          if (trigger.initial) {
            main.initials[name][multiIndex][index] = property;
          } else {
            getInitialStyle(name, multiIndex, index, property, main);
          }

          // console.log(main.initials);
        }

        ++multiIndex;
      }
    }
  }
};

export default setInitials;
