import Main from "../Main";

Main.prototype.releaseUsedMaps = function (usedMaps) {
  const mapPool = this.mapPool;
  for (const map of usedMaps) {
    mapPool.release(map);
  }
};

export default Main.releaseUsedMaps;
