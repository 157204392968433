import WINDOW from "../../utils/window";

const getBoundsObject = (bounds, ratio = 1) => {
  let scrollLeft = WINDOW.scroll.x;
  let scrollTop = WINDOW.scroll.y;

  return {
    rect: bounds,
    width: bounds.width * ratio,
    height: bounds.height * ratio,
    top: bounds.y * ratio,
    left: bounds.x * ratio,
    right: bounds.right || bounds.x * ratio + bounds.width * ratio,
    bottom: bounds.bottom || bounds.y * ratio + bounds.height * ratio,
    "offset-left": bounds.x * ratio + scrollLeft,
    "offset-right": WINDOW.width - (bounds.x * ratio + bounds.width * ratio) - scrollLeft,
    "offset-top": bounds.y * ratio + scrollTop,
    "offset-bottom": bounds.y * ratio + bounds.height * ratio + scrollTop,
    centerX: bounds.x * ratio + (bounds.width * ratio) / 2,
    centerY: bounds.y * ratio + (bounds.height * ratio) / 2,
  };
}

export default getBoundsObject;
