/**
 * Determines whether the step should be changed based on the trigger mode and current step count.
 * @param {import("../../store/utils/get-trigger").TriggerProps} trigger - The trigger object containing mode information.
 * @param {Number} currentStep - The current step.
 * @param {Number} stepCount - The total number of steps.
 * @param {import("../../store/utils/process-timeline").TimelineInfoType} info - Additional information object containing step-related data.
 */
const shouldChangeStep = (trigger, currentStep, stepCount, info, steps) => {
  if (trigger.mode.enter && currentStep < stepCount) {
    if (info.currentStep < stepCount) {
      ++info.currentStep;
    }

    trigger.stepChange = true;
    steps[info.currentStep].active = true;
  }

  if (trigger.mode.leave && currentStep > 0) {
    if (info.currentStep > 0) {
      --info.currentStep;
    }

    trigger.stepChange = true;
    steps[info.currentStep].active = true;
  }
};

export default shouldChangeStep;
