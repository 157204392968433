/**
 * Linear interpolation
 * @param {Number} start
 * @param {Number} end
 * @param {Number} amount
 * @returns {Number}
 */
const linearInterpolation = (start, end, amount) => {
  const count = (1 - amount) * start + amount * end;

  if (count > 0.5) {
    return Math.ceil(count * 10000) / 10000;
  } else {
    return Math.floor(count * 10000) / 10000;
  }
};

export default linearInterpolation;
