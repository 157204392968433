import setSpecificListeners from "../Main/listeners/set-specific-listeners";
import getSpecificationDefault from "./get-specification-default";
import G from "./globals";

/**
 * Set method defined in `<method: {value}>`
 * @param {import("../Main/store/utils/process-options").OptionsMapProps} options
 * @param {import("./process-values-in-resolutions").PropertyMapProps} result
 */
const setOptionMethod = (options, result) => {
  const current = options.get("method").get("current");
  const method = current.get("fn") || current.get("value");

  if (method) {
    result.set("r_method", method);
    result.set(
      "r_methodInit",
      options.get("method-init").get("current").get("value")
    );

    if (result.get("r_methodInit") === "start") {
      result.set("r_methodInit", 0);
    }

    if (result.get("r_methodInit") === "finish") {
      result.set("r_methodInit", 1);
    }

    if (current.get("fn")) {
      result.set("r_methodValue", current.get("value"));
    } else {
      result.set("r_methodValue", getSpecificationDefault(method));
    }

    /** Check if the pointer method needs to update the scroll */
    if (G.pointerScrollMethods.has(method)) {
      /** Fake the trigger to be a `viewport` to force set a scroll trigger */
      setSpecificListeners({ viewport: true });
    }
  }
};

export default setOptionMethod;
