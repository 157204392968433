import { Main } from "../../Main/index";

const initMainElements = async (elements) => {
  for (const [element, set] of elements) {
    const joined = Array.from(set).join(" ");

    element.dataset.tor = joined;

    Main.init(element, "group");
  }
}

export default initMainElements;