import processFunctionValues from "./process-function-values";

/**
 * Processes an input string according to depth and specified separators.
 *
 * @param {string} input - The input string to be processed.
 * @param {number} depth - The depth level for determining separators.
 * @returns {Object} - An object containing processed data at different levels.
 */
const processFunction = (input, data, options) => {
  const multiIndex = data?.multiIndex;
  const level0Array = [];

  const result = processFunctionValues(
    input,
    0,
    level0Array,
    null,
    data,
    options,
    multiIndex
  );

  const level1Array = [];

  for (const level0Item of level0Array) {
    processFunctionValues(
      level0Item.get("output"),
      1,
      level1Array,
      level0Item,
      null,
      null,
      multiIndex
    );
  }

  const level2Array = [];

  for (const level1Item of level1Array) {
    processFunctionValues(
      level1Item.get("output"),
      2,
      level2Array,
      level1Item,
      null,
      null,
      multiIndex
    );
  }

  const level3Array = [];

  for (const level2Item of level2Array) {
    processFunctionValues(
      level2Item.get("output"),
      3,
      level3Array,
      level2Item,
      null,
      null,
      multiIndex
    );
  }

  const level4Array = [];

  for (const level3Item of level3Array) {
    processFunctionValues(
      level3Item.get("output"),
      4,
      level4Array,
      level3Item,
      null,
      null,
      multiIndex
    );
  }

  return result;
};

export default processFunction;
