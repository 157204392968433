import getPause from "./get-pause";

/**
 * Check for timeline iteration
 * @param {import("../../store/utils/get-trigger").TriggerProps} trigger
 * @param {import("../../store/utils/process-timeline").TimelineInfoType} info
 * @param {Number} iterations
 * @param {Number} currentStep
 * @param {Number} duration
 * @param {String} direction
 */
const timelineIterations = (trigger, info, currentStep, timeline) => {
  const options = timeline.options;
  // TODO: check if works with `r_` responsive references
  const iterations = options?.get("iterations").get("current").get("value");
  const direction = options?.get("direction").get("current").get("value");
  const duration = options?.get("duration").get("current").get("value");

  /** Alternate (back and forth) direction */
  const alternate = direction === "alternate";

  /** Timeline pause */
  const pause = info.pause?.get("value");
  const startTime = trigger.startTime;
  const enter = trigger.mode.enter;
  const lastStep = currentStep === info.stepCount - 1;
  const beforeFinish =
    info.currentIteration < iterations - 1 || iterations === "infinite";

  if (enter && lastStep && beforeFinish) {
    if (pause) {
      if (getPause(pause, startTime, duration)) {
        trigger.paused = true;
        return true;
      } else {
        trigger.paused = false;
      }
    }

    if (alternate) {
      trigger.mode.leave = true;
      trigger.mode.enter = false;
      trigger.alternating = true;
    } else {
      info.currentStep = 0;
    }

    if (trigger.entering) {
      ++info.currentIteration;
    }

    trigger.stepChange = true;
    trigger.iterating = true;

    return false;
  }

  const leave = trigger.mode.leave;
  const firstStep = currentStep === 0;
  const afterStart = info.currentIteration > 0 || iterations === "infinite";

  /**
   * Not working with:
   * animate:[
      [tx(-5rem)],
      [r(360deg)]
      <3s, alternate, infinite>
    ]
   */
  if (leave && !trigger.isActive && iterations === "infinite") {
    info.currentIteration = 0;
    trigger.alternating = false;

    if (firstStep) {
      info.active = false;
    }

    return false;
  }

  if (leave && afterStart && firstStep) {
    if (pause) {
      if (getPause(pause, startTime, duration)) {
        trigger.paused = true;
        return true;
      } else {
        trigger.paused = false;
      }
    }

    if (alternate) {
      trigger.mode.leave = false;
      trigger.mode.enter = true;
      trigger.alternating = false;
    } else {
      info.currentStep = info.stepCount - 1;
    }

    if (trigger.leaving) {
      --info.currentIteration;
    }

    trigger.stepChange = true;
    trigger.iterating = false;

    return false;
  }

  if (!trigger.interactive) {
    if ((enter && lastStep) || (leave && firstStep)) {
      info.active = false;
    }
  }

  /** Remove alternating reference on total finish */
  if (leave && firstStep) {
    trigger.alternating = false;
  }
};

export default timelineIterations;
