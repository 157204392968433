const setOptionThreshold = (options, result, data) => {
  const threshold = options.threshold;
  const value = threshold ? threshold.current.value : 0;

  // TODO:
  if (threshold) {
    if (threshold.current.fn === "hover") {
      /** @type {import("../Main/Main").MainClassProps} */
      const main = data.trigger.this;

      main.element.addEventListener("pointerover", () => {
        main.hovered = true;
      });
      main.element.addEventListener("pointerout", () => {
        main.hovered = false;
      });

      result.set("this", data.trigger.this);
      result.set("r_thresholdHover", true);
      result.set("r_threshold", 0);
    } else {
      result.set("r_threshold", value);
      result.set("r_thresholdHover", false);
    }
  } else {
    result.set("r_threshold", 100);
  }

}

export default setOptionThreshold;