import round from "../../../utils/round";
import getAlternate from "./get-alternate";
import getDynamicPercents from "./get-dynamic-percents";
import getInteractivePercents from "./get-interactive-percents";

/**
 * Get percents (dynamic or interactive)
 * @param {import("../../../utils/process-values-in-resolutions").PropertyMapProps} data
 * @param {import("../../store/utils/get-trigger").TriggerProps} trigger
 * @param {Boolean} spring
 * @param {import("../../bounds/set-bounds").BoundsProps} bounds
 * @param {import("../../store/utils/get-trigger").TriggerModeProps} mode
 */
const getPercents = (data, trigger, spring, bounds, mode) => {
  let percents;

  if (trigger.interactive) {
    percents = getInteractivePercents(data, trigger, bounds);
  } else {
    percents = getDynamicPercents(data, trigger, spring, mode);
  }

  getAlternate(data, trigger);

  return round(percents);
};

export default getPercents;
