import getWindowPointer from "../../utils/get-window-pointer";
import G from "../../utils/globals";
import runEvent from "./run-event";

const onPointerMove = (e) => {
  /**
   * Set global `WINDOW.pointer`
   */
  getWindowPointer(e);

  for (const item of G.triggers.pointer.values()) {
    runEvent(item, "pointer", "enter");
  }
}

export default onPointerMove;