import checkFinishedIterations from "../check-finished-iterations";
import getPause from "../get-pause";

/**
 * Get status of dynamic triggers
 * @param {import("../../../../utils/process-values-in-resolutions").PropertyMapProps} data
 * @param {import("../../../store/utils/get-trigger").TriggerProps} trigger
 * @returns {import("../get-percents-status").StatusType}
 */
const statusDynamic = (data, trigger, mode) => {
  const percents = data.get("percents");

  switch (true) {
    // case data.get("lastDelayed") && data.get("percents") > 0 && data.get("percents") < 1:
    //   data.set("lastDelayed", false);
    //   // return "started";
    //   break;

    // case data.get("delayed") && (data.get("percents") === 0 || data.get("percents") === 1):
    //   data.set("lastDelayed", data.get("delayed"));
    //   // return "delayed";
    //   break;

    case data.get("lastPercents") === 1 && trigger.modeChange:
      return "started";

    case percents === 0:
      {
        const pause = data.get("r_pause");
        const duration = data.get("r_duration");
        const startTime = data.get("startTime");

        if (data.get("delayed")) {
          return "delayed";
        }

        if (mode.enter) {
          return "started";
        }

        if (pause && getPause(pause, startTime, duration, data)) {
          return "paused";
        }

        if (mode.leave) {
          return checkFinishedIterations(data, trigger);
        }
      }
      break;

    case percents > 0 && percents < 1:
      if (data.get("r_delay") && !data.get("lastPercents")) {
        return "started";
      }

      if (data.get("delayed")) {
        return "delayed";
      }

      return "running";

    case percents === 1:
      {
        const pause = data.get("r_pause");
        const duration = data.get("r_duration");
        const startTime = data.get("startTime");

        if (data.get("delayed")) {
          return "delayed";
        }

        if (mode.leave) {
          return "started";
        }

        if (pause && getPause(pause, startTime, duration, data)) {
          return "paused";
        }

        if (mode.enter) {
          return checkFinishedIterations(data, trigger);
        }
      }
      break;

    default:
      return "running";
  }
}

export default statusDynamic;
