/**
 * Parallax pointer percents
 * @param {Number} width
 * @param {Number} height
 * @param {import("../../../../utils/window").PointerEventType} event
 */
const pointerParallax = (width, height, event) => {
  const x = (event.x - width / 2) / (width / 2);
  const y = (event.y - height / 2) / (height / 2);
  const both = (x + y) / 2;

  return {
    x,
    y,
    both,
  };
};

export default pointerParallax;