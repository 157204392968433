const getPathPoint = (progress, data, main) => {
  if (!main.bounds.width || !main.bounds.height) {
    return;
  }

  let result;
  let property = data.get("fn");
  let path = main.path.target;
  let current = data.get("value")[0].current;

  if (property === "rotate") {
    property = "rotateZ";
  }

  switch (property) {
    case "translateX":
      const value = current.get("value");

      if (!value || !progress) {
        return;
      }

      main.path.computed = path
        .getPointAtLength(progress * value)
        .matrixTransform(main.svgPathMatrix);

      result = `${main.path.computed.x - main.bounds.width / 2}px - ${
        main.svgPathReduceWidth
      }px`;
      break;

    case "translateY":
      result = `${main.path.computed.y - main.bounds.height / 2}px - ${
        main.svgPathReduceHeight
      }px`;
      break;

    case "rotateZ":
      {
        let rotation =
          (Math.atan2(
            main.path.computed.y - data.get("lastPointY"),
            main.path.computed.x - data.get("lastPointX")
          ) *
            180) /
          Math.PI;

        result = `${rotation || data.get("lastPointRotation") || 0}deg`;

        if (rotation === 180) {
          rotation = data.get("lastPointRotation");
          result = `${rotation}deg`;
        }

        if (rotation) {
          data.set("lastPointRotation", rotation);
        }

        main.path.computed.rotation = rotation;
      }
      break;
  }

  if (main.path.computed.x && main.path.computed.x !== data.get("lastPointX")) {
    data.set("lastPointX", main.path.computed.x);
  }
  if (main.path.computed.y && main.path.computed.y !== data.get("lastPointY")) {
    data.set("lastPointY", main.path.computed.y);
  }

  /** Remove class and make the element visible */
  if (!main.pathInitialized && main.path.computed.rotation) {
    main.pathInitialized = true;
    main.element.classList.remove("tor-hidden");
  }

  // current.set("output", result);
  return result;
};

export default getPathPoint;
