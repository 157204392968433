import onEvents from "../on-events";

/**
 * Dispatch custom class event when new classes matches the one in the `this.triggers.classes`
 * @param {import("../../Main").MainClassProps} main
 * @param {String} match
 * @param {String} type
 */
const dispatchClassEvent = (main, match, type) => {
  for (const trigger of main.triggers.class) {
    if (trigger.class === match) {
      const event = main.customEvents[`${type}:${trigger.name}`];
      main.on[event.type](event);
      // main.element.dispatchEvent(event);
    }
  }
};

export default dispatchClassEvent;
