/**
 *
 * @param {*} parent
 * @param {import("../../../utils/process-values-in-resolutions").PropertyMapProps} property
 * @param {*} store
 * @returns
 */
const getNestedString = (parent, property, store) => {
  const final = [];

  if (property.get("hasChild")) {
    const result = [];
    const values = property.get("value");

    store.fn = property.get("fn");
    store.values = store.values || {};

    for (const i in values) {
      store.values[i] = store.values[i] || {};
      /** @type {import("../../../utils/process-values-in-resolutions").PropertyValueData} */
      const resolution = values[i];

      /** Actual value data */
      const data = resolution.current;

      /** Returned data from nested child */
      const child = getNestedString(parent, data, store.values[i]);

      result.push(child);
    }

    if (property.get("isFn")) {
      final.push(`${property.get("fn")}(${result.join(", ")})`);

    } else {
      const path = property.get("path")[0];
      const parentInfo = parent.get("value")[path].current;

      if (parentInfo.get("isFn")) {
        final.push(result.join(", "));
      } else {
        final.push(result.join(" "));
      }

    }
  } else {
    // console.log(property.get("output"));
    final.push(property.get("output"));
  }
// console.log(final, multiIndex);
  return final;
};

export default getNestedString;