import G from "./globals";

/**
 * Check it the input has a child characters
 * @param {String} input
 * @param {String|null} replacer
 * @example `hsla(calc(20▁+▁2)▂20▂20▂/▂40%)▃top` - Has `(` / `▃` / `▂`
 * and other character, that tells the string value has child, so it is a function
 * @returns {Boolean}
 */
// TODO: optimize?
const checkChildCharacter = (input) => {
  for (const character of G.functionCharacters) {
    if (typeof input === "string" && input.indexOf(character) > 0) {
      return true;
    }
  }

  return false;
};

export default checkChildCharacter;