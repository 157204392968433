import getWindowDimension from "../Main/animate/utils/percents/get-window-dimension";
import setWindowData from "./set-window-data";
import WINDOW from "./window";

/**
 * Get the value of pixel or percentage-based offset
 * @param {Boolean} start "start" or not (end)
 * @param {import("./process-values-in-resolutions").PropertyMapProps} data
 * @param {import("../Main/store/utils/process-trigger-parameters").AxisType} axis
 */

const getOffsetValue = (value, axis, isPage, isPercents) => {
  /** @type {"width"|"height"} */
  let size = getWindowDimension(axis, isPage);

  /** Percentage-based */
  if (isPercents) {
    return value * (WINDOW[size] / 100);
  } else {
    /** Pixel based */
    return value;
  }
};

export default getOffsetValue;
