/**
 * Set the duration for each step in the timeline
 * @param {String} item - Step item string @example `[tx(1rem)]`
 * @param {import("./process-options").OptionsMapProps} options
 * @param {Number} offset - Current step offset
 * @param {Number} lastOffset
 * @param {Number} duration - Timeline duration
 * @param {Number} count - Steps count
 */
const setStepDuration = (
  item,
  options,
  offset,
  lastOffset,
  duration,
  count
) => {
  /** Check for offset character */
  const offsetCharacter = item.indexOf("%_[");

  if (offsetCharacter > 0) {
    /** Extract offset value @example `20%_[tx(1rem)]` */
    offset = +item.substring(0, offsetCharacter);

    /** Remove offset from item @example `[tx(1rem)]` */
    item = item.substring(offsetCharacter + 2);

    /**
     * If offset-based timeline
     */
    if (offset) {
      /**
       * Calculate offset fragment
       * @example
       * Step offset: `20%_[tx(1rem)]`
       * Timeline duration: 2000ms
       * (20% - 0%) / 100 - The percentage of the current step from whole timeline
       * 2000ms * 0.2 = 200ms - The final duration of the current step
       */
      const fragment = (offset - lastOffset) / 100;
      options
        .get("duration")
        .get("current")
        .set("value", duration * fragment);
      // options.duration.current.value = duration * fragment;
    } else {
      /** Set the duration for the first step to 0ms, to skip it immediately during animation */
      options.get("duration").get("current").set("value", 0);
      // options.duration.current.value = 0;
    }

    lastOffset = offset;
  } else {
    /**
     * Sequenced timeline
     */
    options
      .get("duration")
      .get("current")
      .set("value", duration / count);
    // options.duration.current.value = duration / count;
  }

  return {
    item,
    offset,
    lastOffset,
  };
};

export default setStepDuration;
