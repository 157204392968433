const getMaxSide = (bounds, width, height) => {
  const sideX = Math.pow(width - bounds.centerX, 2);
  const a = Math.pow(bounds.centerX, 2);
  const b = Math.pow(bounds.centerY, 2);
  const lt = Math.sqrt(a + b);
  const lb = Math.sqrt(a + Math.pow(height - bounds.centerY, 2));
  const rt = Math.sqrt(sideX + Math.pow(bounds.centerY, 2));
  const rb = Math.sqrt(sideX + Math.pow(height - bounds.centerY, 2));
  const corner = Math.max(...[lt, lb, rt, rb]);

  const ls = bounds.centerX;
  const rs = width - bounds.centerX;
  const ts = bounds.centerY;
  const bs = height - bounds.centerY;
  const xSide = Math.max(...[ls, rs]);
  const ySide = Math.max(...[ts, bs]);

  return { corner, xSide, ySide };
};

export default getMaxSide;