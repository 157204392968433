import getOffsetValue from "../../../../utils/get-offset-value";
import WINDOW from "../../../../utils/window";
import scrollAxisPercents from "./scroll-axis-percents";

/**
 * Get scroll percents for default method
 * @param {import("../../../../utils/process-values-in-resolutions").PropertyMapProps} data
 * @param {import("../../../bounds/set-bounds").BoundsProps} bounds
 * @param {import("../../../store/utils/get-trigger").TriggerProps} trigger
 * @returns
 */
const scrollDefault = (data, bounds, trigger) => {
  // TODO: <1s, start: page([50px xl::150%])>
  if (trigger.r_axis === "x") {
    const start = trigger.r_start.x[data.get("stepNumber")] || 0;
    const end = trigger.r_end.x[data.get("stepNumber")] || WINDOW.width;
    const isPage = trigger.r_page;

    const x = scrollAxisPercents(
      isPage,
      start,
      end,
      "width",
      bounds["offset-left"]
    );

    return {
      x,
    };
  }

  if (trigger.r_axis === "y") {
    // TODO: set also for "r_start"
    const start = trigger.r_start.y[data.get("stepNumber")] || 0;
    const end = trigger.r_end.y[data.get("stepNumber")] || WINDOW.height;
    const isPage = trigger.r_page;

    const y = scrollAxisPercents(
      isPage,
      start,
      end,
      "height",
      bounds["offset-top"]
    );

    return {
      y,
    };
  }
};

export default scrollDefault;
