import indexReplaceAll from "../../utils/index-replace-all";
import replaceVariable from "./replace-variable";

const setVariables = (data, main) => {
  /** Final array with replaced variables along with the original attributes */
  const dataset = [];

  /** Loop trough rest of the attributes (variable declarations are excluded) */
  for (const original of data.dataset) {
    let attribute = original;

    /** Check for `$` character (global and local) */
    const variableCharacter = attribute.indexOf("$");

    /** If the attribute contains */
    if (variableCharacter !== -1) {
      /** Check for global variable */
      const global = attribute.charAt(variableCharacter + 1) === "$";

      /** Replace variable from global variable store */
      if (global) {
        attribute = replaceVariable(attribute, true, main);
      } else {
        /** Replace variable from local variable store */
        attribute = replaceVariable(attribute, false, main, data);
      }
    }

    if (attribute?.indexOf("<") > 0) {
      attribute = indexReplaceAll(attribute, { "▄<": "<", "▃<": "<", "▂<": "<", "▁<": "<" })
    }

    dataset.push(attribute);
  }

  return dataset.filter((item) => item).join(" ");
};

export default setVariables;
