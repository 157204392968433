/**
 * Split string by index
 * @memberof String
 * @param {string} input Attributes string
 * @param {separator} separator character to separate by
 * @returns Array
 */
const splitIndex = function (input, separator) {
  const result = [];

  let startIndex = 0;
  let foundSpace = false;

  for (let i = 0, len = input.length; i < len; i++) {
    if (input[i] === separator) {
      if (foundSpace) {
        result.push(input.substring(startIndex, i));
      }
      startIndex = i + 1;
      foundSpace = false;
    } else {
      foundSpace = true;
    }
  }

  if (startIndex < input.length) {
    result.push(input.substring(startIndex));
  }

  return result;
};

export default splitIndex;
