import applyAsyncReferences from "./Main/store/utils/apply-async-references";
import getWindowPointer from "./utils/get-window-pointer";
import getWindowScroll from "./utils/get-window-scroll";
import G from "./utils/globals";

export const reload = async () => {
  TORUS.destroy();

  getWindowScroll();
  getWindowPointer();

  await TORUS.Group.init();
  await TORUS.Main.init();
  await TORUS.Container.init();

  applyAsyncReferences(G.triggers.animate.values());
};
