import WINDOW from "../../utils/window";
import getMaxSide from "../../utils/get-max-side";
import Main from "../Main";
import getBoundsObject from "./get-bounds-object";

/**
 * @typedef {Object} BoundsProps
 * @property {Number} width
 * @property {Number} height
 * @property {Number} top
 * @property {Number} left
 * @property {Number} right
 * @property {Number} bottom
 * @property {Number} offset-left
 * @property {Number} offset-right
 * @property {Number} offset-top
 * @property {Number} offset-bottom
 * @property {Number} centerX
 * @property {Number} centerY
 * @property {Number} middleX
 * @property {Number} middleY
 * @property {Number} maxDiagonal
 * @property {Number} maxXSide
 * @property {Number} maxYSide
 */

/**
 * Compute and set bounds
 * @param {import("../store/utils/get-trigger").TriggerProps} trigger
 * @param {Number} ratio
 * @this import("../Main").MainClassProps
 */
Main.prototype.setBounds = function (trigger, ratio = 1) {
  const boundsTriggers = new Set(["viewport", "pointer", "scroll"]);

  if (this.element.Torus.bounds?.rect) {
    this.bounds = this.element.Torus.bounds;
    return;
  }

  if (!boundsTriggers.has(trigger.type) && !this.isBoundsElement) {
    return;
  }

  const bounds = this.element.getBoundingClientRect();
  const B = getBoundsObject(bounds, ratio);

  B.middleX = B["offset-left"] + B.width / 2;
  B.middleY = B["offset-top"] + B.height / 2;

  if (this.hasPointer) {
    const max = getMaxSide(B, WINDOW.width, WINDOW.height);

    B.maxDiagonal = Math.round(max.corner);
    B.maxXSide = max.xSide;
    B.maxYSide = max.ySide;
  }

  this.bounds = B;
  this.element.Torus.bounds = B;
};

export default Main.setBounds;
