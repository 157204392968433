import limit from "../../../../utils/limit";
import round from "../../../../utils/round";
import WINDOW from "../../../../utils/window";

const scrollAxisPercents = (isPage, start, end, dimension, bounds) => {
  let position;
  let y;
  let x;

  const scroll = WINDOW.scroll;
  const window = WINDOW[dimension];

  if (isPage) {
    position = scroll.y - start;
    y = position / (end - start - position);
  } else {
    position = window + scroll.y - bounds - start;
    y = position / (window - (window - end) - start);
  }

  y = round(y);
  y = limit(y);

  return y;
};

export default scrollAxisPercents;
