import G from "../../utils/globals";
import runAffectedElements from "./run-affected-elements";

const onPointerUp = () => {
  for (const main of G.elements.active) {
    runAffectedElements(main, "active", "leave");
    G.elements.active.delete(main);
    // console.log(G.elements.active);
  }
}

export default onPointerUp;