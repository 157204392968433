import getMaxSide from "../../../../utils/get-max-side";
import limit from "../../../../utils/limit";
import WINDOW from "../../../../utils/window";
import getCenter from "../get-center";
import threshold from "../threshold";

/**
 * Pointer self
 * @param {import("../../../bounds/set-bounds").BoundsProps} bounds
 * @param {import("../../../../utils/window").PointerEventType} event
 * @param {import("../../../../utils/process-values-in-resolutions").PropertyMapProps} data
 */
const pointerSelf = (bounds, event, data) => {
  const center = getCenter(bounds);
  const centerX = center.x;
  const centerY = center.y;

  const max = getMaxSide(bounds, WINDOW.width, WINDOW.height);
  const maxX = max.xSide;
  const maxY = max.ySide;

  bounds.maxXSide = maxX;
  bounds.maxYSide = maxY;

  const width = bounds.width;
  const height = bounds.height;

  let x = 1 - Math.abs((event.x - centerX) / threshold(data, maxX, width));
  let y = 1 - Math.abs((event.y - centerY) / threshold(data, maxY, height));
  let both = (x + y) / 2;

  x = limit(x);
  y = limit(y);
  both = limit(both);

  return {
    x,
    y,
    both,
  };
};

export default pointerSelf;
