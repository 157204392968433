const getTriggerResolution = (input) => {
  const split = input.split("▸");

  if (split[1]) {
    return {
      resolution: split[0],
      rest: split[1],
    }
  } else {
    return {
      resolution: "all",
      rest: input,
    }
  }
}

export default getTriggerResolution;