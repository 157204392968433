/**
 * ------------------------------------------------------------------------
 * Get group selector and parameters
 * ------------------------------------------------------------------------
 */

const getGroupSelectorAndParameters = function (attribute, group) {
  const split = attribute.split("=>");
  const selector = split[0];
  const parameters = split[1];

  if (!selector) {
    console.warn(`[TorusKit warn]: Missing selector in "${attribute}"`);
  }

  if (!parameters) {
    console.warn(`[TorusKit warn]: Missing parameters in "${attribute}"`);
  }

  const elements = group.element.querySelectorAll(selector);

  if (!elements.length) {
    console.warn(
      `[TorusKit warn]: No elements with the selector of \`${selector}\` found!`
    );
  }

  return { elements, parameters };
};

export default getGroupSelectorAndParameters;
