import Main from "../Main";
import setInitialsCSS from "../store/utils/set-initials-css";

/**
 * Executes immediate trigger events for specified types (e.g., hover, check, class, focus) for elements immediately after initialization.
 * @function
 * @name Main.prototype.immediateInitials
 * @memberof Main.prototype
 * @returns {void}
 */
const immediateInitials = function (main, target) {
  /**
   * Array containing trigger types that should be executed immediately.
   * @type {string[]}
   */
  const immediate = ["check", "class", "focus"];

  /**
   * Reference to the store object.
   * @type {Object}
   */
  const store = main.store;

  /** Iterate through each trigger in the store */
  for (const name in store) {
    /**
     * Trigger object retrieved from the store.
     * @type {import("../store/utils/get-trigger").TriggerProps}
     */
    const trigger = store[name];

    /** Iterate through each immediate trigger type */
    for (const type of immediate) {
      /** Check if the current trigger has the specified immediate type */
      if (trigger[type]) {
        /**
         * Retrieve the custom event associated with the trigger
         * @type {CustomEvent}
         */
        const event = target.customEvents[`${trigger.events[0]}:${trigger.name}`];

        /** If a custom event exists, dispatch it on the element */
        if (event) {
          main.immediate = true;
          target.on[event.type](event);
        }
      }
    }
  }

  setInitialsCSS(main);
}

export default immediateInitials;