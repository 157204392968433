import getParameterShorthand from "./get-parameter-shorthand";
import getTriggerOffset from "./get-trigger-offset";
import warnings from "../../../utils/warnings";
import getValueAndUnit from "../../../utils/get-value-and-unit";

/**
 * Loop parameters and process
 * @param {Array<String>} parameters
 * @param {Element} element
 * @param {Array<String>} offsets
 * @param {Boolean} isPage
 * @param {import("./get-trigger-info").TriggerInfoProps} triggerInfo
 * @param {import("./process-trigger-parameters").TriggerParametersProps} result
 */
const loopTriggerParameters = (
  parameters,
  element,
  offsets,
  isPage,
  triggerInfo,
  result
) => {
  /** Iterate over each parameter in the parameters array */
  for (const parameter of parameters) {
    /** Variable to store the parameter name */
    let name;
    /** Variable to store the parameter value */
    let value;
    /** Variable to store the output value */
    let output;
    /** Variable to determine if the offset is 'start' or 'end' */
    let start = null;

    /** Split the parameter string by the custom delimiter "˸" */
    const split = parameter.split("˸");

    /** If there is a value after splitting, assign name and value */
    if (split[1]) {
      name = split[0];
      value = split[1];

      /** Convert value to boolean */
      if (value === "true") {
        output = true;
      }
      if (value === "false") {
        output = false;
      }
    } else {
      /** If the parameter does not contain a value, use a shorthand lookup */
      const data = getParameterShorthand(parameter, element);

      if (!data) {
        /** If shorthand lookup fails, log a warning and continue to the next parameter */
        warnings.unknownTriggerParameter(parameter);
        continue;
      }

      /** Assign name and output from the shorthand data */
      name = data.name;
      output = data.value;
    }

    /** Check if the parameter's name is in the offsets array and has a value */
    if (value && offsets.includes(name)) {
      /** Determine whether to set 'start' or 'end' offset */
      name = start ? "end" : "start";
      start = true;

      /** If a timeout is specified in triggerInfo, get value and unit */
      if (triggerInfo.timeout) {
        output = getValueAndUnit(value, null);
      } else {
        /** Otherwise, calculate the trigger offset */
        output = getTriggerOffset(value, result, name, isPage);
      }
    }

    /** Store the output in the result object under the computed name */
    result[name] = output;
  }

  return result;
};

export default loopTriggerParameters;
