import WINDOW from "../../../utils/window";
import countIterations from "./count-iterations";

/**
 * Get iterations if percents = 1
 * @param {import("../../../utils/process-values-in-resolutions").PropertyMapProps} data
 * @param {import("../../store/utils/get-trigger").TriggerProps} trigger
 * @returns {"finished"|"repeating"}
 */
const getIterations = (data, trigger) => {
  /** In enter mode, but has unfinished iterations */
  if (data.get("mode").enter) {
    const stillLower = data.get("currentIteration") < data.get("r_iterations") - 1;

    if (stillLower || data.get("r_infinite")) {
      data.set("startTime", WINDOW.now);
      data.set("leavePercents", 0);
      data.set("lastLeaveProgress", 0);

      countIterations(data, trigger, true);

      return "repeating";
    }
  }

  if (data.get("mode").leave) {
    if (!trigger.isActive) {
      data.set("currentIteration", 0);

      return "finished";
    }

    if (data.get("r_alternate") && data.get("currentIteration") === data.get("r_iterations")) {
      data.set("overallIterations", 0);
      data.set("currentIteration", 0);
      data.set("alternateFinished", true);

      return "finished";
    }

    if (data.get("currentIteration") > 0) {
      data.set("startTime", WINDOW.now);
      data.set("enterPercents", 1);
      data.set("lastEnterProgress", 1);

      countIterations(data, trigger, false);

      return "repeating";
    } else {
      data.set("overallIterations", 0);
    }
  }

  data.set("overallIterations", 0);
  data.set("currentIteration", 0);

  return "finished";
};

export default getIterations;
