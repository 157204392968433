import setPathMatrix from "./set-path-matrix";

/**
 * Find the SVG element that represents a `path`
 * @param {import("../Main/store/utils/process-options").OptionsData} options
 * @param {import("../Main/Main").MainClassProps} main
 */
const setOptionPath = (options, main) => {
  /**
   * Get path definition prom options
   * @type {String}
   */
  const path = options.get("path");

  /** No path defined */
  if (!path) {
    return null;
  }

  /** Element string selector */
  const element = path.get("current").get("output");

  /** Get the element from DOM */
  const target = document.querySelector(element);

  /** Set the path target to `Main` class to be available globally */
  main.path.target = target;

  /** Calculate SVG Matrix  */
  if (!main.svgPathMatrix) {
    setPathMatrix(target, main);
  }

  return target;
};

export default setOptionPath;
