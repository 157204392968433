import warnings from "../../utils/warnings";
import getVariableAction from "./get-variable-action";

/**
 * Find corresponding variable and replace it
 * @param {String} variable
 * @param {String} original
 * @param {import("../Main").MainClassProps} main
 * @returns {String} - The found variable or an empty string if not found.
 */
const findVariable = (variable, original, main, input) => {
  /** If no local or global variable definition found */
  if (!variable) {
    warnings.noVariable(original);

    return null;
  }

  switch (true) {
    case variable.startsWith("get("):
      return getVariableAction(variable, main, input);

    case variable.startsWith("^"):
      return variable.slice(1);

    default:
      return variable;
  }
};

export default findVariable;
