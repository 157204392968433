import G from "../../../utils/globals";
import attributeGet from "./attribute-get";
import boundsGet from "./bounds-get";
import getLength from "./get-length";

/**
 * "Get" action
 * @param {import("../../Main").MainClassProps} main
 * @param {import("../../../utils/process-values-in-resolutions").PropertyMapProps} property
 * @param {import("../../store/utils/process-parent").ParentProps} parent
 * @param {String} original
 */
const actionGet = (main, property, parent, original) => {
  switch (true) {
    case property.get("output") === "attribute":
      return attributeGet(main, property, parent);

    /** Get "length" of SVG "path" element */
    case property.get("output") === "length":
      return getLength(main, property, parent, original);

    case G.actionBoundTypes.has(property.get("output")):
      return boundsGet(main, property, parent);
  }
};

export default actionGet;
