import Main from "../Main";

Main.prototype.computeValues = function (item, finalIndex) {
  let final = "";
  let priority = false;
  // let computed = [];

  const arrayPool = this.arrayPool;
  const computed = arrayPool.acquire();
  const values = item.get("values");

  for (const data of values.values()) {
    const initial = data.get("initial");

    /** Previously single-value computed item */
    const value = data.get("computed");

    priority = data.get("priority");

    if (!value) {
      continue;
    }

    if (data.get("isString")) {
      computed.push(value);
    } else if (initial) {
      computed.push(`calc(${initial} - ${value})`);
    } else {
      computed.push(value);
    }
  }

  if (!computed.length) {
    return false;
  }

  if (item.get("fn")) {
    const order = item.get("order");

    final = `${item.get("fn")}(${computed.join(", ")})`;

    if (order) {
      if (item.get("first")) {
        /** Push string at specific index */
        finalIndex.get("result").splice(order - 1, 0, final);
        item.set("computed", final);
        return;
      }
    }
  } else {
    final = computed.join(item.get("separator"));
  }

  item.set("computed", final);

  if (item.get("first")) {
    finalIndex.get("result").push(final);
    finalIndex.set("priority", priority);
    finalIndex.set("isTransform", item.get("isTransform"));
  }
}

export default Main.computeValues;