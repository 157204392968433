import G from "../utils/globals";
import optimizeString from "../utils/optimize-string";
import Group from "./Group";
import initMainElements from "./utils/init-main";
import reduceSpaces from "./utils/reduce-spaces";
import separateAttributes from "./utils/separate-attributes";

Group.prototype.init = async function () {
  this.dataset = reduceSpaces(this.element.dataset.torGroup);
  this.defaults();

  G.groups.all.set(this.element, this);

  separateAttributes(this);
  initMainElements(this.targets);

  return Promise.resolve(this);
}

export default Group.init;