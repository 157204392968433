/**
 * Get pointer percents where the 100% is in the middle of a screen,
 * but it continues to count up to 200% on the very side of a screen
 * @param {Number} width
 * @param {Number} height
 * @param {*} event
 * @param {*} options
 * @returns
 */
const pointerContinuous = (width, height, event, options) => {
  let x = 1 - (width / 2 - event.x) / (width / 2);
  let y = 1 - (height / 2 - event.y) / (height / 2);
  let both = (x + y) / 2;

  return {
    x,
    y,
    both,
  };
};

export default pointerContinuous;