/**
 * Toggle an attribute value on a list of HTML elements.
 * This function toggles the specified attribute value by adding it if it does not exist
 * and removing it if it is already present on each target element.
 *
 * @param {Array<Element>} targets - The list of HTML elements to toggle the attribute on.
 * @param {String} name - The name of the attribute to be toggled (e.g., `data-name`).
 * @param {String} values - The value to add or remove from the attribute (e.g., `John`).
 * @param {import("../../../utils/process-values-in-resolutions").PropertyMapProps} property - An object to store the original and updated attribute values.
 */
const attributeToggle = (targets, name, values, property) => {
  for (const target of targets) {
    /** Get the current attribute value, if any. */
    const currentAttr = target.getAttribute(name);

    if (currentAttr) {
      /** Split the current attribute value into an array of values. */
      const currentValues = currentAttr
        .split(" ")
        .filter((val) => val.trim() !== "");

      /** Determine if the new value is already present. */
      const valueIndex = currentValues.indexOf(values);

      if (valueIndex !== -1) {
        /** If the value is present, remove it. */
        currentValues.splice(valueIndex, 1);
      } else {
        /** If the value is not present, add it. */
        currentValues.push(values);
      }

      /** Update the attribute with the new values. */
      target.setAttribute(name, currentValues.join(" ").trim());
    } else {
      /** If no current attribute, set it with the new value. */
      target.setAttribute(name, values);
    }

    /** Update the property with the new attribute value. */
    property.set("newAttr", target.getAttribute(name));
  }
};

export default attributeToggle;
