const computeToCalc = (property, progress, output, initial, result) => {
  let computed = "";
  const composition = property.get("composition");

  if (composition) {
    computed = `((-1) * (${composition}1) * (${progress} * ${output}))`;
  } else {
    const all = result.join(" - ");
    const previous = all ? ` - ${all}` : "";
    // computed = `(${progress} * (${initial}${previous} - ${output}))`;
    computed = `(${progress} * (${initial}${previous} - ${output}))`;
  }

  return computed;
};

export default computeToCalc;
