import runDynamicTrigger from "./run-dynamic-trigger";
import runInteractiveTrigger from "./run-interactive-trigger";
import runSelectedTrigger from "./run-selected-trigger";

/**
 * Find a trigger that should be activated in all triggers store.
 * For example the `hover` includes multiple timelines, but only one "hover" event can be run at a time
 * @param {import("../../Main").MainClassProps} main
 * @param {Object} mode
 * @param {Object} event
 * @param {Object} type
 * @param {Number} startTime
 * @param {Object} detail
 */
const findInAllTriggers = (main, mode, event, type, startTime) => {
  /** Access the main store from the main */
  const store = main.store;

  /** Iterate through each trigger in the store */
  for (const name in store) {
    /** @type {import("../store/utils/get-trigger").TriggerProps} */
    const trigger = store[name];

    if (!trigger.timeline || trigger.initial) {
      continue;
    }

    /**
     * If detail is provided and matches the current trigger name,
     * or the trigger has the specified event, activate the trigger.
     * There can be multiple "viewport" events with different times, so they cannot
     * be triggered at the same time
     */
    // if (trigger[event] || trigger.name === name) {
    if (trigger[event] || trigger.reference) {
      runSelectedTrigger(main, trigger, mode, type, startTime);
    }
  }
};

export default findInAllTriggers;
