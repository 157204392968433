/**
 * Get list of indexes in the string for the selected item
 * @param {import("./get-function").FunctionObject} input
 * @param {String} item
 * @returns
 */
const getIndexes = (input, item) => {
  let i = 0;
  const result = [];

  for (const letter of input) {
    if (letter === item) {
      result.push(i);
    }

    ++i;
  }

  return result;
};

export default getIndexes;
