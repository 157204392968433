import runSelectedTrigger from "./run-selected-trigger";

const findInExactTriggers = (main, detail, mode, type, startTime) => {
  const name = detail.detail?.name;

  if (!name) {
    return;
  }

  const trigger = main.store[name];

  if (!trigger) {
    return;
  }

  if (!trigger.timeline) {
    return;
  }

  runSelectedTrigger(main, trigger, mode, type, startTime);
};

export default findInExactTriggers;