const setAlternateStartTime = (data, startTime, trigger, multiplier, add) => {
  const addendum = add ? multiplier : 0;

  if (!data.get("firstAlternate")) {
    const fragment = (1 - data.get("percents")) * multiplier;
    startTime = trigger.startTime + addendum - fragment;
    data.set("startTime", startTime);
    data.set("firstAlternate", true);
  }

  return startTime;
};

export default setAlternateStartTime;
