import G from "../../utils/globals";
import Main from "../Main";

/**
 * Add to active triggers
 * @param {import("../store/utils/get-trigger").TriggerProps} trigger
 * @this import("../Main").MainClassProps
 */
Main.prototype.addToActiveTriggers = function (trigger) {
  const active = this.activeTriggers;
  const set = this.activeTriggersSet;

  active.set(trigger.trigger, active.get(trigger.trigger) || new Map());
  active.get(trigger.trigger).set(trigger.name, trigger);

  set.add(trigger.name);

  G.elements.running.set(this.element, this);

  this.activeTriggersNames.add(trigger);
};

export default Main.addToActiveTriggers;
