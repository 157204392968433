import getFunction from "../../../utils/get-function";
import isInResolution from "../../../utils/is-in-resolution";
import randomHash from "../../../utils/random-hash";
import getTriggerInfo from "./get-trigger-info";
import getTriggerResolution from "./get-trigger-resolution";
import processTriggerParameters from "./process-trigger-parameters";

/**
 * @typedef {Object} TriggerModeProps
 * @property {Boolean} enter
 * @property {Boolean} leave
 */

/**
 * @typedef {Object} OffsetProps
 * @property {Array<Number>} [start.x]
 * @property {Array<Number>} [start.y]
 * @property {Array<Number>} [end.x]
 * @property {Array<Number>} [end.y]
 * @property {Object | null} [end.value]
 * @property {Object | null} [start.value]
 */

/**
 * @typedef {Object} TriggerProps
 * @property {String} name - Name with hash
 * @property {String} trigger - Original trigger name (without hash)
 * @property {Array<String>} events - Event listeners associated with a trigger
 * @property {TriggerModeProps} mode - Mode (enter/leave)
 * @property {String} resolution
 * @property {import("./process-timeline").TimelineProps} timeline
 * @property {Boolean} inResolution
 * @property {Boolean} entering
 * @property {Boolean} leaving
 * @property {Array<Object>} steps
 * @property {Boolean} hasTarget
 * @property {Number} [startTime]
 * @property {Number} [originalStartTime]
 * @property {Boolean} [modeChange]
 * @property {import("./process-trigger-parameters").TriggerParametersProps} parameters
 * @property {import("./get-trigger-type").TriggerTypeProps} type
 * @property {import("../../Main").MainClassProps} this - Main class constructor
 * @property {Boolean} [class]
 * @property {Boolean} [hover]
 * @property {Boolean} [pointer]
 * @property {Boolean} [scroll]
 * @property {Boolean} [viewport]
 * @property {Boolean} [active]
 * @property {Boolean} [initial]
 * @property {Boolean} [timeout]
 * @property {Boolean} [shift]
 * @property {Boolean} [delayDone]
 * @property {Boolean} [delayed]
 * @property {Boolean} [pauseDone]
 * @property {Boolean} [paused]
 * @property {Boolean} [stepChange]
 * @property {Boolean} [alternating]
 * @property {Boolean} [interactive]
 * @property {Boolean} [fromTrigger]
 * @property {Boolean} [isActive]
 * @property {Boolean} [inResolution]
 * @property {Boolean} [resolution]
 * @property {Boolean} [hasResolution]
 * @property {Boolean} [iterating]
 * @property {Number} [currentStep]
 * @property {Number} [timelineDelay]
 * @property {Number} [timelinePause]
 * @property {String} [rest]
 * @property {{ x: Array<Number>, y: Array<Number> }} [r_start]
 * @property {{ x: Array<Number>, y: Array<Number> }} [r_end]
 * @property {Boolean} [r_page]
 * @property {Boolean} [r_keep]
 * @property {Boolean} [r_alternate]
 * @property {Boolean|null} timelineAlternate
 */

/**
 * Extracts trigger information from a given string and returns an object containing trigger data and the remaining string.
 * @param {String} input The input string, potentially containing a trigger definition.
 * @param {String} value Rest of the attribute
 * @param {Element} element Target element
 */
const getTrigger = (input, value, element) => {
  let name = "";
  let type = "";

  let inResolution = true;

  /** Get trigger resolution */
  const resolutionData = getTriggerResolution(input);

  /** String resolution */
  const resolution = resolutionData.resolution;

  /**
   * Get the name and parameters (CSS properties) of the trigger
   */
  const data = getFunction(resolutionData.rest);

  /** Name of the trigger. (mouse, hove,r ...) @type {String} */
  const trigger = data ? data.name : resolutionData.rest;

  /** @type {import("./get-trigger-info").TriggerInfoProps} */
  const info = getTriggerInfo(data ? data.name : resolutionData.rest);

  /** @type {import("./process-trigger-parameters").TriggerParametersProps} */
  const parameters = processTriggerParameters(
    data ? data.parameters : null,
    info,
    element
  );

  name = info.reference ? trigger : `${trigger}-${value}`;
  type = info.type;

  const r_start = parameters.start;
  const r_end = parameters.end;
  const r_axis = parameters.axis;
  const r_page = parameters.page;
  const r_keep = parameters.keep;

  /**
   * Check if the trigger is in resolution
   */
  inResolution = isInResolution(resolution);

  const result = {
    name,
    parameters,
    resolution,
    inResolution,
    trigger,
    type,
    r_start,
    r_end,
    r_axis,
    r_page,
    r_keep,
    hasTarget: false,
    initial: false,
    modeChange: false,
    delay: false,
    pause: false,
    delayDone: false,
    pauseDone: false,
    stepChange: false,
    alternating: false,
    active: false,
    iterating: false,
    activeProperties: new Set(),
    hasResolution: resolution !== "all",
    interactive: info.interactive,
    events: info.events,
    [type]: true,
  };

  if (result.viewport) {
    result[trigger] = true;
  }

  /**
   * Has external target
   * @example `hover(#parent)`
   */
  if (parameters.target) {
    result.hasTarget = true;
  }

  return result;
};

export default getTrigger;
