import WINDOW from "../../../utils/window";
import onRaf from "../on-raf";

const startRAF = () => {
  // return new Promise((resolve) => {
  //   if (!WINDOW.rafRunning) {
  //       // WINDOW.now = now;
  //       WINDOW.rafRunning = true;

  //       onRaf().then((now) => {
  //         resolve(now);
  //       });
  //   } else {
  //     resolve("done");
  //   }
  // });

  if (!WINDOW.visible) {
    return;
  }

  if (!WINDOW.rafRunning) {
    WINDOW.now = performance.now();
    WINDOW.rafRunning = true;
    onRaf();
  }
};

export default startRAF;
