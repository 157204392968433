import warnings from "../../../utils/warnings";
import processParent from "../../store/utils/process-parent";
import getDefaultInitial from "./get-default-initial";
import optimizeComputed from "./optimize-computed";

/**
 * Get initial style. Computed or default
 * @param {String} name
 * @param {String} index
 * @param {import("../../../utils/process-values-in-resolutions").PropertyMapProps} property
 * @param {import("../../Main").MainClassProps} main
 */
const getInitialStyle = (name, multiIndex, index, property, main) => {
  /**
   * Has initial manually defined
   */
  if (main.initials[name][multiIndex][index]) {
    return;
  }

  /** Get the element computed style */
  const computedStyle = main.computedStyle[name];

  /**
   * Unknown default property
   */
  if (!computedStyle) {
    warnings.unknownDefaultProperty(name, main.element);
    return;
  }

  /**
   * Get computed style from CSS and optimize
   */
  const optimizedStyle = optimizeComputed(computedStyle);

  /**
   * ComputedStyle returns "none". This means that no initial CSS property is defined
   * We need to get it from defaults
   */
  if (optimizedStyle === "none") {
    getDefaultInitial(name, multiIndex, index, property, main);
    return;
  }

  const computedParent = processParent(`${name}(${optimizedStyle})`, { name: "initial" });
  const computedIndex = computedParent[name].multi[0][index];

  if (computedIndex) {
    main.initials[name][0][multiIndex] = computedIndex;
  } else {
    getDefaultInitial(name, multiIndex, index, property, main);
  }

  /**
   * No initial, no computed. Need to get the default
   */
  if (!main.initials[name][multiIndex][index]) {
    getDefaultInitial(name, multiIndex, index, property, main);
  }
};

export default getInitialStyle;
