import G from "../../utils/globals";
import runAffectedElements from "./run-affected-elements";

/**
 * @typedef {"enter" | "leave"} EventTypeProps
 */

/**
 * Run functions on various events
 * @param {import("../Main").MainClassProps} main
 */

const onEvents = (main) => {
  return {
    pointerover: () => {
      console.log("pointer over");
    },

    pointerout: () => {
      console.log("pointer out");
    },

    pointerenter: () => {
      runAffectedElements(main, "hover", "enter");
    },

    pointerleave: () => {
      runAffectedElements(main, "hover", "leave");
    },

    pointerdown: () => {
      G.elements.active.add(main);
      runAffectedElements(main, "active", "enter");
    },

    click: () => {
      runAffectedElements(main, "click", "enter");
    },

    focus: () => {
      runAffectedElements(main, "focus", "enter");
    },

    blur: () => {
      runAffectedElements(main, "focus", "leave");
    },

    focusin: () => {
      runAffectedElements(main, "focus-within", "enter");
    },

    focusout: () => {
      runAffectedElements(main, "focus-within", "leave");
    },

    change: (e) => {
      const element = main.element.control ? main.element.control : main.element;

      if (main.element.control?.checked || e.details.check) {
        const name = element.checked ? "check" : "uncheck";
        const event = main.customEvents[`${name}:${e.details.name}`];

        main.element.dispatchEvent(event);
      } else {
        runAffectedElements(main, "change", "enter");
      }
    },

    check: (e) => {
      if (main.element.control?.checked || main.element.checked) {
        runAffectedElements(main, "check", "enter", e, true);
      }
    },

    uncheck: (e) => {
      runAffectedElements(main, "check", "leave", e, true);
    },

    classadded: (e) => {
      const name = e.detail.trigger.slice(1);

      if (main.element.classList.contains(name)) {
        runAffectedElements(main, "class", "enter", e, true);
      }
    },

    classremoved: (e) => {
      runAffectedElements(main, "class", "leave", e, true);
    },

    viewportenter: (e) => {
      runAffectedElements(main, "viewport", "enter", e, true);
    },

    viewportleave: (e) => {
      runAffectedElements(main, "viewport", "leave", e, true);
    },

    referenceenter: (e) => {
      console.log("reference enter", e);
    },

    referenceleave: (e) => {
      console.log("reference leave", e);
    },

    animateenter: (now) => {
      runAffectedElements(main, "animate", "enter", now, true);
    },

    animateleave: (now) => {
      runAffectedElements(main, "animate", "leave", now, true);
    },

    timeoutenter: (e) => {
      runAffectedElements(main, "timeout", "enter", e, true);
    },

    timeoutleave: (e) => {
      runAffectedElements(main, "timeout", "leave", e, true);
    },

    run: () => {
      main.compute();
    },

    flat: () => {
      main.computeFlat();
    },

    css: () => {
      main.setCSS();
    },
  };
};

export default onEvents;
