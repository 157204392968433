import getWindowScroll from "../../../utils/get-window-scroll";
import WINDOW from "../../../utils/window";

/**
 *
 * @param {import("../../bounds/set-bounds").BoundsProps} bounds
 * @returns
 */
const getCenter = (bounds) => {
  const scroll = {};

  scroll.x = WINDOW.scroll.x;
  scroll.y = WINDOW.scroll.y;

  // const x = bounds["offset-left"] + bounds.width / 2 - scroll.x;
  // const y = bounds["offset-top"] + bounds.height / 2 - scroll.y;
  const x = bounds.middleX - scroll.x;
  const y = bounds.middleY - scroll.y;

  bounds.centerX = x;
  bounds.centerY = y;

  return {
    x,
    y,
  };
};

export default getCenter;