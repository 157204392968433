import G from "../../../utils/globals";

/**
 * @typedef {Object} AliasDefinition
 * @property {string} attribute - Attribute that contains alias
 * @property {string} name - Alias name
 * @property {import("../../../utils/globals").Alias} definition - Alias global definition
 */

/**
 * Get array of aliases
 * @param {string} input
 * @returns {Array<AliasDefinition>}
 */
const getAliases = (input) => {
  /** Whole dataset */
  const dataset = input.split(" ");

  /** Array with the attributes that actually includes an alias */
  const result = [];

  /** Loop all attributes */
  for (const attribute of dataset) {

    /** Then loop all global aliases */
    for (const name in G.aliases) {
      /** Check if the global alias name is included in the single attribute */
      if (attribute.includes(`${name}(`)) {
        const indexOfName = attribute.indexOf(name);

        if (attribute.charAt(indexOfName - 1) === "-") {
          continue;
        }

        result.push({
          attribute,
          name,
          definition: G.aliases[name],
        });
      }
    }
  }

  return result;
}

export default getAliases;