import processCluster from "./process-cluster";
import processSingle from "./process-single";
import processTimeline from "./process-timeline";

/**
 * @typedef {Object} Step
 * @property {null|import("./process-cluster").StepInfo} info
 * @property {null|Object} options
 * @property {Object} parents
 * @property {Boolean} active
 * @property {Boolean} processed
 * @property {Number} index
 * @property {Number |null} offset
 */

/**
 * Object representing a step with options and parents.
 * @typedef {Object} StepsProps
 * @property {null|Object} data - Options data
 * @property {null|Object} options - Options for the step (currently set to null).
 * @property {Array<Step>} steps - An array containing step objects.
 */

/**
 * Convert string into steps object
 * @param {String} input Input string
 * @param {import("./get-trigger").TriggerProps} trigger Input string
 */
const getSteps = (input, trigger) => {
  let result;

  switch (true) {
    /**
     * Timeline
     * @example `hover:[[opacity(.2)], [tx(2rem)] <2s>]`
     */
    case input.startsWith("[[") || input.includes("%_["):
      result = processTimeline(input, trigger);
      break;

    /**
     * Cluster
     * @example `hover:[opacity(.2) tx(2rem) <2s>]`
     * @param {String} input - The input string representing cluster steps.
     */
    case input.startsWith("["):
      result = processCluster(input, trigger);
      break;

    /**
     * Single property
     * Creates a step object with default options and processed parents.
     * @param {String} input - The input string to be processed.
     * @returns {StepObject} An object representing a step with options and parents.
     * @example `opacity(.2)`
     */
    default:
      result = processSingle(input, trigger);
      break;
  }

  return result;
};

export default getSteps;
