const setPathMatrix = (svgPath, main) => {
  if (!svgPath) {
    return;
  }

  const svgOwner = svgPath.ownerSVGElement;

  /** @type {DOMRect} */
  const bounds = svgOwner.getBoundingClientRect();

  const scaleX = bounds.height / svgOwner.viewBox.baseVal.height;
  const scaleY = bounds.width / svgOwner.viewBox.baseVal.width;

  // TODO: make work with scale
  main.svgPathReduceWidth = 0;
  main.svgPathReduceHeight = 0;

  main.svgPathMatrix = svgOwner
    .createSVGMatrix()
    .scaleNonUniform(scaleX, scaleY);

  if (!main.pathInitialized) {
    main.element.classList.add("tor-hidden");
  }
};

export default setPathMatrix;
