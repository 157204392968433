import splitIndex from "../../../utils/split-index";
import getTriggerResolution from "./get-trigger-resolution";

/**
 * Get the array of divided attributes from a dataset string
 * @param {string} input
 */
const getAttributes = (input) => {
  const attributes = splitIndex(input, " ");

  const result = [];

  for (const attribute of attributes) {
    const triggers = {};
    const split = attribute.split(":");
    const value1 = split[0];
    const value2 = split[1];

    if(value2) {
      triggers[value1] = value2;
    } else {
      const data = getTriggerResolution(value1);

      if (data.resolution === "all") {
        triggers.initial = value1;
      } else {
        triggers[`${data.resolution}▸initial`] = value1.slice(value1.indexOf("▸") + 1);
      }
    }

    result.push(triggers);
  }

  return result;
}

export default getAttributes;