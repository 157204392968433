import getFunction from "../get-function";
import colorSlashToRgba from "./color-slash-to-rgba";
import hslaToRgba from "./hsla-to-rgba";

const parseHsla = (input) => {
  const color = colorSlashToRgba(input, "hsla");
  const fn = getFunction(color);
  const numbers = fn.parameters.split(",").map((item) => {
    return parseFloat(item);
  });

  return hslaToRgba(...numbers);
};

export default parseHsla;
