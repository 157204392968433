const getFinalString = (multi, initials, name, arrayPool) => {
  const result = arrayPool.acquire();
  let priority = false;
  let isTransform = false;

  for (const [multiIndex, parents] of multi) {
    for (const data of parents.values()) {
      /** @type {Array} */
      const items = data.get("result");

      priority = data.get("priority");
      isTransform = data.get("isTransform");

      if (items) {
        if (initials) {
          const initialsObject = initials[name]?.[multiIndex];

          if (initialsObject) {
            for (const key in initialsObject) {
              items.unshift(initialsObject[key]);
            }
          }
        }

        const computed = items.join(" ");

        result.push(computed);
      }
    }
  }

  return {
    result,
    priority,
    isTransform,
  };
};

export default getFinalString;
