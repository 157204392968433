import processParent from "./process-parent";

/**
 * Process single property
 * @param {String} input
 * @param {import("./get-trigger").TriggerProps} trigger
 */
const processSingle = (input, trigger) => {
  return {
    data: null,
    options: null,
    info: {
      currentStep: 0,
      stepCount: 1,
    },
    steps: [
      {
        /** @type {import("./process-cluster").StepInfo} */
        info: {
          parentsCount: 1,
          startedParents: 0,
          finishedParents: 0,
          started: false,
          finished: false,
        },
        options: null,
        active: false,
        parents: processParent(input, trigger, null, 0),
      },
    ],
  };
};

export default processSingle;
