/**
 * ------------------------------------------------------------------------
 * Check random definition
 * @example `hove:scale(/random(1, 3)/)`
 * ------------------------------------------------------------------------
 */

import getRandomNumber from "../../utils/get-random-number";
import indexReplaceAll from "../../utils/index-replace-all";

const checkRandomDefinition = function (value) {
  if (value.indexOf("random") === 0) {
    value = indexReplaceAll(value, { "random(": "", ")": "" });
    const split = value.split(",");

    if (split[1]) {
      return getRandomNumber(+split[0], +split[1]);
    } else {
      return getRandomNumber(0, +split[0]);
    }
  } else {
    return NaN;
  }
};

export default checkRandomDefinition;
