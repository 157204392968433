import checkInview from "../../utils/check-inview";
import G from "../../utils/globals";
import dispatchViewportEvent from "./utils/dispatch-viewport-event";

/**
 * Loop trough all "viewport" elements and run events
 */
const onViewport = () => {
  for (const item of G.triggers.viewport.values()) {
    /**
     * @type {import("../store/utils/get-trigger").TriggerProps}
     */
    let trigger;

    for (trigger of item.triggers.viewport) {
      let target;

      if (trigger.hasTarget) {
        // TODO: add reference like `r_target`
        target = trigger.parameters.target[0].Torus.Target;
      } else {
        target = item;
      }

      /** Check if "viewport" elements are in/out viewport */
      const isInview = checkInview(target.bounds, trigger);

      /** Dispatch custom viewport event */
      dispatchViewportEvent(isInview, trigger, target);
    }
  }
};

export default onViewport;
