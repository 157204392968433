import actionGet from "../actions/utils/action-get";
import processParent from "../store/utils/process-parent";

/**
 * Process variable "get" actions
 * @param {String} input variable string
 * @param {import("../Main").MainClassProps} main
 * @param {String} original
 * @example `$top(get(height <target: .h1>))`
 * @returns {number|string}
 */
const getVariableAction = (input, main, original) => {
  /** Processed input string */
  const data = processParent(input);

  /** @type {import("../store/utils/process-parent").ParentProps} */
  const parent = data.get;

  /** @type {import("../../utils/process-values-in-resolutions").PropertyMapProps} */
  const property = parent.multi[0][0].current;

  /** Get the "action" according the definition */
  return actionGet(main, property, parent, original);
};

export default getVariableAction;
