/**
 * @typedef {Object} TriggerTypeProps
 * @property {String} [class]
 * @property {String} [hover]
 * @property {String} [pointer]
 * @property {String} [viewport]
 * @property {String} [active]
 * @property {String} [initial]
 */

/**
 *
 * @param {String} trigger
 * @returns {String}
 */
const getTriggerType = (trigger) => {
  let type;

  const triggerTypes = {
    mouse: "pointer",
    inview: "viewport",
    outview: "viewport",
  };

  type = triggerTypes[trigger];

  if (type) {
    return type;
  }

  switch (true) {
    case trigger.startsWith("."):
      return "class";

    case trigger.startsWith("@"):
      return "reference";

    // case trigger.startsWith("timeout"):
    //   return "timeout";

    case !trigger:
      return "initial";

    default:
      return trigger;
  }

  // if (trigger.startsWith(".")) {
  //   return "class";
  // }

  // if (trigger.startsWith("@")) {
  //   return "reference";
  // }

  // if (type) {
  //   return type;
  // }

  // if (!trigger) {
  //   return "initial";
  // }

  // return trigger;
};

export default getTriggerType;
