const spring = (stiffness, damping) => {
  stiffness = stiffness || 5;
  damping = damping || 10;

  const calcSpring = (t) => {
    return 1 - Math.exp(-stiffness * t) * Math.cos(damping * t);
  }

  return (t) => {
    return calcSpring(t);
  };
};

export default spring;