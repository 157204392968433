const getNestedValue = (info, property, resolution, index, data) => {
  let value;

  // if (data.get("isCalc")) {
  //   // console.log(
  //   //   info.property[info.resolution].get("value")[index]?.current,
  //   //   property[info.resolution] || property[resolution]
  //   // );
  //   // value = info.property[info.resolution]?.value[index]?.current;
  //   value = info.property[info.resolution].get("value")[index]?.current;
  // } else {
  //   value = property[info.resolution] || property[resolution];
  // }

  value = property[info.resolution] || property[resolution];

  return value;
  // ▓
  // return new Map(Object.entries(value));
};

export default getNestedValue;
