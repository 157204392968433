import WINDOW from "../../utils/window";
import findInAllTriggers from "./utils/find-in-all-triggers";
import findInExactTriggers from "./utils/find-in-exact-triggers";
import startRAF from "./utils/start-raf";

/**
 * Runs an event for a given item.
 * Starts requestAnimationFrame and sets up necessary properties for triggers.
 * If the event detail matches a specific trigger, only that trigger is activated.
 * Otherwise, all triggers associated with the event are activated.
 * @param {import("../Main").MainClassProps} main - The item for which the event is being run.
 * @param {String} event - The event name.
 * @param {import("./on-events").EventTypeProps} type - The type of event (e.g., "enter" or "leave").
 * @param {Object} [detail] - Details about the event.
 * @param {String} [detail.name] - Name of the specific trigger event.
 * @param {Boolean} [exact] - If it should look for exact trigger name
 */
const runEvent = function (main, event, type, detail, exact) {
  const startTime = WINDOW.now;

  /** Determine the trigger mode based on the event type */
  const mode = {
    enter: type === "enter" ? true : false,
    leave: type === "leave" ? true : false,
  };

  if (exact) {
    findInExactTriggers(main, detail, mode, type, startTime);
  } else {
    findInAllTriggers(main, mode, event, type, startTime);
  }
};

export default runEvent;
