import getClusterContent from "../../../utils/get-cluster-content";
import mergeObjects from "../../../utils/merge-objects";
import extractLastOptions from "./extract-last-options";
import processClusterProperties from "./process-cluster-properties";
import processOptions from "./process-options";

/**
 * @typedef {Object} StepInfo
 * @property {Number} parentsCount
 * @property {Number} finishedParents
 * @property {Number} startedParents
 * @property {Boolean} started
 * @property {Boolean} finished
 */

/**
 * Process clusters by extracting its content and merging the parent options with inherited ones
 * @param {String} string
 * @param {import("./process-options").OptionsMapProps} parentOptions
 * @param {Number} stepNumber
 * @param {Number|null} offset
 * @param {Boolean} isTimeline
 */
const processCluster = (string, trigger, parentOptions, stepNumber, isTimeline) => {
  /** @type {import("./process-options").OptionsMapProps} */
  let options;

  /** Extracted cluster content */
  const cluster = getClusterContent(string);

  /** Extracted last cluster option and the rest of the string */
  const data = extractLastOptions(cluster, true);

  /** Common options for every parent */
  options = processOptions(data.options);

  if (parentOptions) {
    /** If timeline, merge only a `duration` */
    if (isTimeline) {
      options.set("duration", parentOptions.get("duration"));
    } else {
      options = mergeObjects(parentOptions, options);
    }
  }

  /** Actual list of parents */
  const content = data.rest;

  /** Divided array of parents */
  const split = content.split("█");

  /**
   * Processed each parent CSS property in the current cluster/step
   * @example `['o(2)', 'tx(4rem)', 's(2)']` - properties in one step
   */
  const parentsData = processClusterProperties(split, trigger, options, stepNumber);

  /** Parent CSS properties */
  const parents = parentsData.parents;

  /** Count of parent properties */
  const count = parentsData.count;

  const info = {
    parentsCount: count,
    finishedParents: new Set(),
    startedParents: 0,
    started: false,
    finished: false,
  };

  return {
    options: null,
    info: {
      stepCount: 1,
      currentStep: 0,
    },
    steps: [
      {
        info,
        options,
        parents,
        active: false,
      },
    ],
  };
}

export default processCluster;