import bezier from "./easing-bezier";
import spring from "./easing-spring";

const easing = {
  spring: (stiffness, damping) => {
    return spring(stiffness, damping);
  },

  bezier: (mX1, mY1, mX2, mY2) => {
    return bezier(mX1, mY1, mX2, mY2);
  }
};

export default easing;