import processParent from "./process-parent";

/**
 * Processed each parent CSS property in the current cluster/step
 * @example `['o(2)', 'tx(4rem)', 's(2)']` - properties in one step
 * @param {Array} input
 */
const processClusterProperties = (input, trigger, options, stepNumber) => {
  const parents = {};
  let count = 0;
  let lastParent = "";

  for (const parent of input) {
    const processed = processParent(parent, trigger, options, stepNumber);

    for (const name in processed) {
      parents[name] = parents[name] || {};
      parents[name].multi = parents[name].multi || [];

      /** @type {import("./process-parent").ParentProps} */
      const data = processed[name];

      /**
       * Shorthand store variable
       * @type {import("./process-parent").ParentProps}
       */
      const result = parents[name];

      result.original = data.original;
      result.action = data.action;
      result.reference = data.reference;
      result.priority = data.priority;

      let index = 0;

      for (const item of data.multi) {
        result.multi[index] = result.multi[index] || {};

        for (const n in item) {
          result.multi[index][n] = item[n];
        }
        ++index;
      }

      if (lastParent !== name) {
        ++count;
      }

      lastParent = name;
    }

    // for (const name in processed) {
    //   parents[name] = parents[name] || {};
    //   parents[name] = processed[name];
    // }
  }

  return {
    parents,
    count,
  };
};

export default processClusterProperties;
