import warnings from "../../../utils/warnings";

/**
 * Get the element bounds
 * @param {import("../../Main").MainClassProps} item
 * @param {import("../../../utils/process-values-in-resolutions").PropertyMapProps} property
 * @param {import("../../store/utils/process-parent").ParentProps} parent
 * @returns {String}
 */
const boundsGet = (item, property, parent) => {
  /** @type {DOMRect | undefined} */
  let bounds;

  /** @type {NodeListOf<Element>} */
  const target = property
    .get("options")
    ?.get("target")
    ?.get("current")
    .get("value");

  if (target) {
    if (target.length > 1) {
      warnings.multipleTargets(parent);
    } else {
      bounds = target[0].getBoundingClientRect();
    }
  } else {
    bounds = item.element.getBoundingClientRect();
  }

  if (!bounds) {
    throw new Error("Unable to determine bounds");
  }

  /** @type {keyof DOMRect} */
  const output = property.get("output");

  return bounds[output] + "px";
};

export default boundsGet;
