import attributeActions from "./attribute-actions";

/**
 * "Replace" action
 * @param {import("../../Main").MainClassProps} main
 * @param {import("../../../utils/process-values-in-resolutions").PropertyMapProps} property
 * @param {import("../../store/utils/process-parent").ParentProps} parent
 */
const actionReplace = (main, property, parent) => {
  attributeActions(main, property, parent, "replace");
};

export default actionReplace;
