const getWindowDimension = (axis, isPage) => {
  if (isPage) {
    if (axis === "x") {
      return "documentWidth";
    }
    if (axis === "y") {
      return "documentHeight";
    }
  } else {
    if (axis === "x") {
      return "width";
    }
    if (axis === "y") {
      return "height";
    }
  }
};

export default getWindowDimension;
