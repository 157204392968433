import WINDOW from "../../utils/window";
import startRAF from "./utils/start-raf";

const onVisibilityChange = () => {
  if (document.visibilityState === "visible") {
    WINDOW.visible = true;
    WINDOW.difference = performance.now() - (WINDOW.now || WINDOW.lastNow);

    startRAF();
  } else {
    WINDOW.visible = false;

    if (WINDOW.now) {
      WINDOW.lastNow = WINDOW.now;
    }

    cancelAnimationFrame(WINDOW.raf);
    WINDOW.rafRunning = false;
  }
};

export default onVisibilityChange;
