import warnings from "../../../utils/warnings";
import processFunction from "./process-function";

/**
 * Split the values with pipe "|"
 * @type {import("../../../utils/get-function").IsFn}
 * @param {Object} data Object that contains the processed function string
 * @param {string} data.name
 * @param {string} data.parameters
 */
const processMulti = (data, options) => {
  let index = 0;

  const result = [];
  const multi = data.parameters.split("|");

  if (!data.parameters) {
    warnings.noValue(data.name)
    return [];
  }

  for (const item of multi) {
    data.multiIndex = index;
    const fn = processFunction(item, data, options);
    result.push(fn);

    ++index;
  }

  return result;
}

export default processMulti;