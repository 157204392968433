import startRAF from "./Main/events/utils/start-raf";
import afterMutation from "./utils/after-mutation";
import beforeMutation from "./utils/before-mutations";

// TODO: make auto init and use loop init functions from `beforeMutation`
export const init = async (autoInit) => {
  if (autoInit) {
    await beforeMutation();
    await afterMutation();

    startRAF();
  }
};

init(true);
