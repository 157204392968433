/**
 * Count threshold
 * @param {Number} value
 * @param {import("../../../utils/process-values-in-resolutions").PropertyMapProps} data
 * @returns
 */
const threshold = (data, value, size) => {
  if (data.get("r_thresholdHover")) {
    if (data.this.hovered) {
      return size / 2;
    } else {
      return 0;
    }
  }

  return value / (100 / data.get("r_threshold"));
}

export default threshold;