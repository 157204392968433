// import setCurrentResolutions from "../../utils/set-current-resolutions";
import { reload } from "../../reload";
import setWindowData from "../../utils/set-window-data";
import WINDOW from "../../utils/window";

/**
 * ------------------------------------------------------------------------
 * Run methods on window resize
 * ------------------------------------------------------------------------
 */
const onResize = () => {
  if (WINDOW.resize !== null) {
    clearTimeout(WINDOW.resize);
  }

  /** Reset the idle time difference */
  WINDOW.difference = 0;

  WINDOW.resize = setTimeout(async () => {
    /** Get the old window `width` before the checking for the new window data */
    const oldWidth = WINDOW.width;

    /** Get and set the current window data */
    setWindowData();

    /** Get the new window `width` */
    const newWidth = WINDOW.width;

    /** If widths are different, apply reload, because the resolution has changed */
    if (oldWidth !== newWidth) {
      reload();
    }

    // setCurrentResolutions();
  }, 150);
}

export default onResize;