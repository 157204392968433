import interactiveDeltaAbsolute from "./interactive-delta-absolute";
import scrollContinuous from "./scroll-continuous";
import scrollDefault from "./scroll-default";

/**
 * Get scroll percents
 * @param {import("../../../../utils/process-values-in-resolutions").PropertyMapProps} data
 * @param {import("../../../store/utils/get-trigger").TriggerProps} trigger
 * @param {*} bounds
 */
const getScrollPercents = (data, trigger, bounds) => {
  /** @type {import("../../../store/utils/process-trigger-parameters").AxisType} */
  const axis = trigger.parameters.axis;

  switch (data.get("r_method")) {
    case "continuous":
      return scrollContinuous(bounds)[axis];

    case "delta":
      return interactiveDeltaAbsolute(data, "scroll")[axis];

    default:
      return scrollDefault(data, bounds, trigger)[axis];
  }
};

export default getScrollPercents;