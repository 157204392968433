import warning from "../../../utils/warning";
import warnings from "../../../utils/warnings";
import getTarget from "./get-target";

/**
 * Get attribute
 * @param {import("../../Main").MainClassProps} main
 * @param {import("../../../utils/process-values-in-resolutions").PropertyMapProps} property
 * @param {import("../../store/utils/process-parent").ParentProps} parent
 * @param {String} type - add/get/remove
 */
const classActions = (main, property, parent, type) => {
  /** Check for class names */
  const names = property.get("options")?.get("name").get("current").get("value");
  /** Get the target (this.element or external) */
  const targets = getTarget(main, property.get("options"));

  if (!names) {
    warnings.noClassName(parent);
    return;
  }

  for (const target of targets) {
    target.classList[type](...names.split("▪"));
  }
}

export default classActions;
