/**
 * @typedef {Object} FunctionObject
 * @property {String} name - Function name
 * @property {String} parameters - Function parameters
 * @property {boolean} priority - Boolean that it's a function
 * @property {boolean} isFn - Boolean that it's a function
 * @property {boolean} isNamed
 */

/**
 * Parses a string representation of a function and returns its name and parameters.
 * @param {String} input
 * @returns {FunctionObject | false}
 */
const getFunction = (input) => {
  let priority = false;
  let openCharacterIndex = input.indexOf("(");

  // TODO: test performance
  const isPureFnName = /^(\w|\.|\$)(.*?)\(/.test(input);

  if (!isPureFnName || input[input.length - 1] !== ")") {
    return null;
  }

  /**
   * Special case when `rgba` is together with other property
   * @example `background(rgba(0,0,0,1) 30%)`
   */
  if (input.startsWith("rgb")) {
    const matches = input.match(/\(+/g);

    if (matches.length > 1) {
      return null;
    }
  }

  // console.log(input);

  if (input.startsWith("!")) {
    input = input.substring(1);
    priority = true;
    openCharacterIndex -= 1;
  }

  const name = input.substring(0, openCharacterIndex);
  const parameters = input.substring(
    openCharacterIndex + 1,
    input.length - 1
  );

  return { name, parameters, priority, isFn: true };
};

export default getFunction;
