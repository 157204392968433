var WINDOW = WINDOW || {};

WINDOW.now = 0;
WINDOW.lastNow = 0;
WINDOW.difference = 0;

WINDOW.run = false;

WINDOW.raf = null;
WINDOW.rafRunning = false;

WINDOW.width = null;
WINDOW.height = null;
WINDOW.documentHeight = null;
WINDOW.documentWidth = null;

WINDOW.resize = null;
WINDOW.visible = true;

WINDOW.isChrome = !!window.chrome;
WINDOW.isSafari =
  /Safari/i.test(navigator.userAgent) && !/Chrome/i.test(navigator.userAgent);
WINDOW.isFirefox = /Firefox/i.test(navigator.userAgent);

const framework = document.currentScript.dataset?.torFramework;

WINDOW.framework = framework?.startsWith("svelte") ? "svelte" : null;

WINDOW.postpone = {
  svelte: true,
}

/** Create new style with custom CSS classes */
const style = document.createElement("style");
style.innerText = ".tor-hidden { visibility: hidden !important; }";
document.head.appendChild(style);

WINDOW.observers = {
  /** @type {MutationObserver} */
  class: null,
  /** @type {MutationObserver} */
  mutation: null,
  /** @type {MutationObserver} */
  asyncMutation: null,
};

/**
 * @typedef {Object} ScrollEventType
 * @property {Number | null} x
 * @property {Number | null} y
 * @property {Object} direction
 * @property {Number} direction.x
 * @property {Number} direction.y
 * @property {Number} lastRafX
 * @property {Number} lastRafY
 * @property {Object} lastDirection
 * @property {Number} lastDirection.x
 * @property {Number} lastDirection.y
 * @property {Boolean} initialized
 * @property {Boolean} running
 * @property {Object} mode
 * @property {Object} mode.x
 * @property {Boolean} mode.x.enter
 * @property {Boolean} mode.x.leave
 * @property {Object} mode.y
 * @property {Boolean} mode.y.enter
 * @property {Boolean} mode.y.leave
 */
WINDOW.scroll = {
  x: null,
  y: null,
  initialized: false,
  direction: {
    x: null,
    y: null,
  },
  lastDirection: {
    x: null,
    y: null,
  },
  mode: {
    x: {
      enter: true,
      leave: false,
    },
    y: {
      enter: true,
      leave: false,
    },
  },
};

/**
 * @typedef {Object} PointerEventType
 * @property {Number | null} x
 * @property {Number | null} y
 * @property {Object} direction
 * @property {Number} direction.x
 * @property {Number} direction.y
 * @property {Number} lastRafX
 * @property {Number} lastRafY
 * @property {Object} lastDirection
 * @property {Number} lastDirection.x
 * @property {Number} lastDirection.y
 * @property {Boolean} initialized
 * @property {Boolean} running
 */
WINDOW.pointer = {
  x: null,
  y: null,
  initialized: false,
  direction: {
    x: null,
    y: null,
  },
  lastDirection: {
    x: null,
    y: null,
  },
  mode: {
    x: {
      enter: true,
      leave: false,
    },
    y: {
      enter: true,
      leave: false,
    },
    both: {
      enter: true,
      leave: false,
    },
  },
};

WINDOW.listeners = {
  scroll: false,
  mouse: false,
  pointer: false,
  sensor: false,
  class: false,
  active: false,
};

export default WINDOW;
