import Main from "../Main";
import loopValueTriggers from "./utils/loop-value-triggers";

Main.prototype.computeSingle = function (item, index, finalIndex, multiIndex) {
  const activeTriggersNames = this.activeTriggersNames;
  const current = item.get("value").get("initial").get(multiIndex).current;

  const triggers = loopValueTriggers(
    item,
    activeTriggersNames,
    this.arrayPool,
    current
  );

  const triggersArray = triggers.result;
  const priority = triggers.priority;
  const initial = triggers.initial;

  if (triggersArray.length) {
    let result = triggersArray.join(" - ");

    item.set("computed", result);
    item.set("initial", initial);
    item.set("priority", priority);

    if (item.get("first")) {
      let final = "";

      if (item.get("isString")) {
        final = result;
      } else {
        final = `calc(${initial} - ${result})`;
      }

      finalIndex.get("result").push(final);
      finalIndex.set("priority", priority);
    }
  }
}

export default Main.computeSingle;