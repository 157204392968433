import getFunction from "../../../utils/get-function";

/**
 * Get the index number or string (transform properties are string-based)
 * @param {object} data
 * @param {number} i Index
 */
const getIndexType = (data, i, level) => {
  if (data?.isNamed) {
    const fn = getFunction(level);
    return {
      index: `${fn.name}`,
      named: true,
    }
  } else {
    return {
      index: i,
      named: false,
    }
  }
}

export default getIndexType;