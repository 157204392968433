import loopChildValues from "./loop-child-values";

const handleChildValues = (
  store,
  data,
  info,
  parent,
  trigger,
  main,
  resolution,
  previous
) => {
  const values = data.get("value");

  store.set("fn", data.get("fn"));
  store.has("values") || store.set("values", new Map());

  for (const i in values) {
    store.get("values").has(i) || store.get("values").set(i, new Map());

    for (const resolution in values[i]) {
      values[i][resolution].set("index", i);

      loopChildValues(
        parent,
        values[i],
        store.get("values").get(i),
        trigger,
        main,
        info,
        false,
        resolution,
        previous
      );
    }
    // values[i][resolution].set("index", i);

    // loopChildValues(
    //   parent,
    //   values[i],
    //   store.get("values").get(i),
    //   trigger,
    //   main,
    //   info,
    //   false,
    //   resolution,
    //   previous
    // );
  }
};

export default handleChildValues;
