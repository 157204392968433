import getCurrentResolution from "./get-current-resolution";
import getResolutionsFromCluster from "./get-resolutions-from-cluster";
import processValuesInResolutions from "./process-values-in-resolutions";

/**
 * Utility function that wraps two functions to create a resolution object from cluster string
 * @returns {import("./process-values-in-resolutions").PropertyMapProps}
 */
const getResolutionsObject = (input, data, options) => {
  const resolutions = getResolutionsFromCluster(input, data);
  const processed = processValuesInResolutions(resolutions, data, options);

  processed.set("current", getCurrentResolution(processed));

  return processed;
};

export default getResolutionsObject;
