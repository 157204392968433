import onViewport from "./on-viewport";
import getWindowScroll from "../../utils/get-window-scroll";
import G from "../../utils/globals";
import runEvent from "./run-event";

const onScroll = () => {
  /**
   * Set global `WINDOW.scroll`
   */
  getWindowScroll();

  /**
   * Loop all "viewport" triggers
   */
  onViewport();

  for (const item of G.triggers.scroll.values()) {
    runEvent(item, "scroll", "enter");
  }
}

export default onScroll;