import indexReplaceAll from "../../../utils/index-replace-all";

const handleCalc = (data, property, info, _resolution) => {
  if (data.get("isCalc")) {
    const prop = property[info.resolution] || property[_resolution];
    const replaced = indexReplaceAll(prop.get("value"), {
      "▓": " ",
      "〈": "(",
      "〉": ")",
    });
    const string = `calc(${replaced})`;

    prop.set("output", string);
  }
};

export default handleCalc;
