import G from "../../utils/globals";
import Main from "../Main";

/**
 * Compute triggers with timelines
 * @this import("../Main").MainClassProps
 */
Main.prototype.compute = function () {
  /**
   * Shorthand
   * @type {Map<String, any>}
   */
  const active = this.activeTriggers;

  /**
   * Loop active trigger groups
   * @example `hover, class`
   */
  for (const group of active.values()) {
    /** @type {import("../store/utils/get-trigger").TriggerProps} */
    let trigger;

    /**
     * Loop groups and the name of each trigger
     * @example `{hover-cOUed: {…}, hover-XuTEq: {…}}`
     */
    for (trigger of group.values()) {
      /** @type {import("../store/utils/get-trigger").TriggerModeProps} */
      const mode = trigger.mode;

      /**
       * In rare cases the trigger is fired before the DOMContentLoad
       * while the timeline is not fully computed
       */
      if (!trigger.computed || !trigger.inResolution) {
        continue;
      }

      /** Process the timeline steps of the current trigger */
      this.computeTimeline(trigger.timeline, trigger, mode);

      /** Reset the `modeChange` value that is `true` only in a moment of real mode change (hover in/out) */
      trigger.modeChange = false;
    }
  }

  /** All triggers an their corresponding timelines has finished */
  if (!this.activeTriggersSet.size) {
    /** Delete instance to `this` object to exclude it from RAF loop */
    G.elements.running.delete(this.element);
  }
};

export default Main.compute;
