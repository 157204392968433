class ObjectPool {
  constructor(createFn) {
    this.createFn = createFn;
    this.pool = [];
  }

  acquire() {
    return this.pool.length > 0 ? this.pool.pop() : this.createFn();
  }

  release(object) {
    if (object instanceof Map) {
      object.clear();
    } else if (Array.isArray(object)) {
      object.length = 0;
    }
    this.pool.push(object);
  }
}

export default ObjectPool;