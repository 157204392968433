/**
 * ------------------------------------------------------------------------
 * Set platforms-specific classes
 * ------------------------------------------------------------------------
 */

import WINDOW from "./window";

const setPlatform = () => {
  const classList = document.body.classList;

  if (WINDOW.isChrome) {
    if (navigator?.userAgentData?.platform === "Windows") {
      classList.add("tor-chrome-win");
    }
    if (navigator?.userAgentData?.platform === "macOS") {
      classList.add("tor-chrome-mac");
    }
  }

  if (WINDOW.isFirefox) {
    if (/Windows/i.test(navigator.userAgent)) {
      classList.add("tor-moz-win");
    }
    if (/MacOs/i.test(navigator.userAgent)) {
      classList.add("tor-moz-mac");
    }
  }
};

export default setPlatform;
