import getFunction from "../../../utils/get-function";

/**
 * Check for `shift`
 * @param {String} input
 * @param {import("../../Main").MainClassProps} main
 */
const getShift = (input, main) => {
  const data = getFunction(input);

  if (data) {
    main.shift = main.shift || {};
  }

  switch (data.name) {
    case "up":
      main.shift.translateY = `-${data.parameters}`;
      break;

    case "down":
      main.shift.translateY = `${data.parameters}`;
      break;

    case "left":
      main.shift.translateX = `-${data.parameters}`;
      break;

    case "right":
      main.shift.translateX = `${data.parameters}`;
      break;
  }
};

export default getShift;
