import G from "../../../utils/globals";
import loopChildValues from "../../animate/utils/loop-child-values";

/**
 * @typedef {Map} ComputedProps
 * @property {Boolean} active
 * @property {Array} multi
 * @property {Set} triggers
 */

/**
 * @typedef {Map} ComputedSingletProps
 * @property {String} fn
 * @property {Object} [value]
 * @property {Object} [values]
 * @property {String} [computed]
 * @property {Boolean} [first]
 * @property {Boolean} [hasChild]
 * @property {String} [initial]
 * @property {Boolean} [isString]
 * @property {Boolean} [isCalc]
 * @property {Boolean} [priority]
 */

/**
 * @typedef {CSSStyleDeclaration} <FlatProps
 * @property {Object} [i]
 * @property {Object} [index]
 */

/**
 * Create store for computed properties
 * @param {import("../../Main").MainClassProps} main
 * @param {import("./get-trigger").TriggerProps} trigger
 * @param {import("./get-steps").Steps} result
 */
const createComputedStore = (main, trigger, result) => {
  if (trigger.initial) {
    return;
  }

  for (const step of result.steps) {
    const parents = step.parents;

    for (const name in parents) {
      /** @type {import("./process-parent").ParentProps} */
      const parent = parents[name];

      if (parent.action) {
        continue;
      }

      /**
       * Create new named object that stores computed values for each CSS property
       * @example `this.computed.transform`
       */
      main.computed.has(name) || main.computed.set(name, new Map());

      /**
       * The "flat" object that stores single-level object that was extracted from deeply nested children
       */

      /**
       * Reference
       * @type {ComputedProps}
       */
      const computed = main.computed.get(name);

      /** Set the current CSS property active state to false as default */
      computed.set("active", false);

      /** Store currently active triggers */
      computed.set("triggers", new Set());

      /** "Multi" values array */
      computed.has("multi") || computed.set("multi", new Map());

      for (const number in parent.multi) {
        /** Multi-value index */
        const i = +number;

        computed.get("multi").has(i) || computed.get("multi").set(i, new Map());

        const store = computed.get("multi").get(i);

        /**
         * Property
         * @type {import("../../../utils/process-values-in-resolutions").PropertyValueData}
         */
        const properties = parent.multi[i];

        /** Index name (number/translateX, scale...) */
        for (const indexName in properties) {
          let flat;
          let resolutions = properties[indexName];

          store.has(indexName) || store.set(indexName, new Map());

          /** Special case. The actual resolutions are defined in deeper level */
          if (name === "transform") {
            resolutions = resolutions.current.get("value")[0];
          }

          if (trigger.initial) {
            G.references.initials.add(resolutions);
          }

          main.flat.has(name) || main.flat.set(name, new Map());
          flat = main.flat.get(name);
          flat.has(i) || flat.set(i, new Map());
          flat.get(i).get(0) || flat.get(i).set(0,  new Map());

          for (const resolution in resolutions) {
            if (!flat.get(i).get(0).has(resolution)) {
              flat.get(i).get(0).set(resolution, new Set());
            }
          }

          for (const resolution in resolutions) {
            /** @type {ComputedSingletProps} */
            store.get(indexName).has(resolution) || store.get(indexName).set(resolution, new Map());

            /** @type {import("../../../utils/process-values-in-resolutions").PropertyValueData} */
            const property = properties[indexName];

            /** `translate`, `blur`... are named properties */
            const isNamed = property.current.get("isNamed");

            /** Timeline step number */
            const stepNumber = property.current.get("stepNumber");

            /** CSS function */
            const fn = isNamed ? property.current.get("fn") : null;

            /** "important" priority */
            const priority = property.current.get("priority");

            const data = {
              stepNumber,
              resolution,
              property: resolutions,
              isNamed,
              index: i,
              valueIndex: i || +indexName,
              fn,
              priority,
              original: property,
            };

            loopChildValues(
              name,
              property,
              store.get(indexName).get(resolution),
              trigger,
              main,
              data,
              true,
              resolution,
            );
          }

        }
      }
    }
  }
  // console.log(main.computed.get("background").get("multi").get(0).get("0"), main.initials.background[0]);
};

export default createComputedStore;
