import statusDynamic from "./status/status-dynamic";
import statusInteractive from "./status/status-interactive";

/**
 * @typedef {"started" | "finished" | "running" | "delayed" | "paused"} StatusType
 */

/**
 * Get string status
 * @param {Number} percents
 * @param {import("../../../utils/process-values-in-resolutions").PropertyMapProps} data
 * @param {import("../../store/utils/get-trigger").TriggerProps} trigger
 * @returns {StatusType}
 */
const getPercentsStatus = (data, trigger, mode) => {
  if (trigger.interactive) {
    return statusInteractive(data, trigger);
  } else {
    return statusDynamic(data, trigger, mode);
  }
};

export default getPercentsStatus;
