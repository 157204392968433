import Main from "../Main";
import getVariables from "./get-variables";
import setVariables from "./set-variables";

/**
 * @typedef {Object} VariableStoreProps
 * @property {Object} variables
 * @property {Array} dataset
 */

/**
 * Variables
 * @param {string} input Input string
 */
Main.prototype.processVariables = function (input) {
  /** @type {VariableStoreProps} */
  const data = getVariables(input);

  /** @type {String} */
  const dataset = setVariables(data, this).trim();

  return {
    dataset,
    variables: data.variables,
  };
};

export default Main.processVariables;
