import WINDOW from "../../utils/window";
import onPointerUp from "../events/on-pointer-up";
import onScroll from "../events/on-scroll";

/**
 * Sets specific event listeners based on the trigger conditions.
 *
 * @memberof Main
 * @function setSpecificListeners
 * @param {import("../store/utils/get-trigger").TriggerProps} trigger An object containing boolean flags for enabling listeners.
 */
// Main.prototype.setSpecificListeners = (trigger) => {
const setSpecificListeners = (trigger) => {
  if (trigger.active && !WINDOW.listeners.active) {
    window.addEventListener("pointerup", onPointerUp);

    WINDOW.listeners.active = true;
  }

  if (trigger.viewport && !WINDOW.listeners.scroll) {
    window.addEventListener("scroll", onScroll);

    WINDOW.listeners.scroll = true;
  }
};

export default setSpecificListeners;
