import progressDynamic from "./progress/progress-dynamic";
import progressInteractive from "./progress/progress-interactive";

/**
 * Get easing progress based on percentage
 * @param {Number} percents
 * @param {import("../../../utils/process-values-in-resolutions").PropertyMapProps} data
 * @param {import("../../store/utils/get-trigger").TriggerProps} trigger
 * @param {Number} percents
 */
const getProgress = (data, trigger, percents) => {
  let progress;

  if (trigger.interactive) {
    progress = progressInteractive(data, trigger, percents);
  } else {
    progress = progressDynamic(data, percents);
  }

  data.set("lastProgress", progress);
  return progress;
};

export default getProgress;
