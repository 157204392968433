import G from "./utils/globals";
import WINDOW from "./utils/window";

export const destroy = async () => {
  G.references = {
    resolutions: new Set(),
    triggers: new Set(),
    regular: {},
    initials: new Set(),
    options: new Set(),
  };

  G.triggers.active.clear();
  G.triggers.viewport.clear();
  G.triggers.animate.clear();
  G.triggers.timeout.clear();
  G.triggers.scroll.clear();
  G.triggers.pointer.clear();
  G.triggers.initial.clear();
  G.triggers.running.clear();

  TORUS.Target.destroy();
  TORUS.Container.destroy();
  TORUS.Group.destroy();
  TORUS.Main.destroy();

  G.elements.Group.clear();
  G.elements.Main.clear();
  G.elements.Container.clear();
  G.elements.Target.clear();

  G.elements.running.clear();

  // WINDOW.observers.intersection.disconnect();
}
