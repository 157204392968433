import Main from "../Main";
import runReference from "./utils/run-reference";

/**
 * Compute multi values
 * @param {{ priority: Boolean, multi: Array }} parent - Parent object
 * @param {String} name - Parent name
 * @param {import("../store/utils/get-trigger").TriggerProps} trigger
 * @param {import("../store/utils/get-trigger").TriggerModeProps} mode
 */
Main.prototype.computeMulti = function (parent, name, trigger, mode) {
  /** Check if the parent property is reference, run it and immediately finish it */
  if (parent.reference) {
    runReference(trigger, trigger.rest, parent);
    parent.status.add(data.output);

    return;
  }

  let result;

  /**
   * Loop each values in "multi-value" property. Usually just one value,
   * but for example "background" can have multiple ones
   */
  for (const properties of parent.multi) {
    result = this.computeProperties(properties, trigger, parent, name, mode);
  }

  return result;
};

export default Main.computeMulti;