import indexReplaceAll from "../../utils/index-replace-all";
import getAliases from "./utils/get-aliases";
import replaceAliases from "./utils/replace-aliases";

/**
 * Process aliases
 * @param {string} input
 */
const processAliases = (input) => {
  /** Replace all `loop` triggers with the `animate` */
  if (input.startsWith("loop")) {
    input = indexReplaceAll(input, { loop: "animate" });
  }

  /** Get array of aliases */
  const aliases = getAliases(input);

  /** Replace alias with regular CSS properties */
  const replaced = replaceAliases(aliases, input);

  /** Remove any white-spaces from start or end */
  return replaced.trim();
};

export default processAliases;
