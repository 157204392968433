/**
 * Remove initials that are not needed anymore - when trigger sets its own initials
 * @param {String} status
 * @param {String} parentName
 * @param {import("../../../utils/process-values-in-resolutions").PropertyMapProps} data
 * @param {String} index
 * @param {import("../../Main").MainClassProps} main
 */
const checkInitials = (status, parentName, data, index, main) => {
  if (status === "started") {
    const initials = main.appliedInitials;
    const multiIndex = data.get("multiIndex");

    if (initials) {
      if (initials?.[parentName]?.[multiIndex]) {
        // TODO: convert to Map()
        delete initials[parentName][multiIndex][index];

        if (!Object.entries(initials[parentName][multiIndex]).length) {
          delete initials[parentName][multiIndex];
        }
      }
    }
  }
};

export default checkInitials;
