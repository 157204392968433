import G from "../../../utils/globals";

/**
 * Adds targets to the global targets map based on the trigger and element.
 * If the trigger has targets defined, it adds the element to each target's set in the global targets map.
 * @param {import("./get-trigger").TriggerProps} trigger
 * @param {import("../../Main").MainClassProps} main
 */
const addTargets = (trigger, main) => {
  if (trigger.hasTarget) {
    for (const item of trigger.parameters.target) {
      const map = G.targets;

      /** If target item doesn't exist in G.targets, add it */
      if (!map.has(item)) {
        map.set(item);
      }

      /**
       * Get the set (content) corresponding to the target item
       * @type {Set}
       */
      const content = map.get(item);

      /** If set is empty, create a new set with the current trigger and main class */
      if (!content) {
        map.set(item, new Map());
        map.get(item).set(trigger.name, { trigger, main });
      } else {
        /** If set already exists, add the current trigger and element to it */
        map.get(item).set(trigger.name, { trigger, main });
      }
    }
  }
};

export default addTargets;
