import getClusterContent from "../../../utils/get-cluster-content";
import getFunction from "../../../utils/get-function";
import getOffsetValue from "../../../utils/get-offset-value";
import getValueAndUnit from "../../../utils/get-value-and-unit";

/**
 * @typedef {Object} TriggerOffsetProps
 * @property {Boolean} isPx - if uses px or %
 * @property {Number} value - Offset value
 */

/**
 * Get the offset trigger in "%" or "px"
 * @param {String} input
 * @returns {TriggerOffsetProps}
 */
const getTriggerOffset = (input, trigger, name, isPage) => {
  let split = [];
  let parameters = input;

  const offsets = {
    x: [],
    y: [],
  };

  if (parameters.startsWith("[")) {
    const content = getClusterContent(parameters);
    split = content.split("|");
  } else {
    split = [parameters];
  }

  for (const item of split) {
    const data = getValueAndUnit(item);
    const isPercents = data.unit === "%";

    if (trigger.axis === "both") {
      const x = getOffsetValue(data.value, "x", isPage, isPercents);
      const y = getOffsetValue(data.value, "y", isPage, isPercents);

      offsets.x.push(x);
      offsets.y.push(y);
    } else {
      const offset = getOffsetValue(
        data.value,
        trigger.axis,
        isPage,
        isPercents
      );
      offsets[trigger.axis].push(offset);
    }
  }

  if (!offsets.x.length) {
    offsets.x = trigger[name].x;
  }
  if (!offsets.y.length) {
    offsets.y = trigger[name].y;
  }

  return offsets;

  // return {
  //   isPx: data.unit === "px",
  //   value: data.value,
  // };
};

export default getTriggerOffset;