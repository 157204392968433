import limit from "../../../../utils/limit";

/**
 * Pointer percents for default method
 * @param {Object} event
 * @param {import("../../../../utils/process-values-in-resolutions").PropertyMapProps} data
 * @param {Number} width
 * @param {Number} height
 * @param {import("../../../store/utils/process-trigger-parameters").AxisType} axis
 */
const pointerDefault = (event, data, width, height, trigger) => {
  const startX = trigger.r_start.x[data.get("stepNumber")] || 0;
  const startY = trigger.r_start.y[data.get("stepNumber")] || 0;
  const endX = trigger.r_end.x[data.get("stepNumber")] || 0;
  const endY = trigger.r_end.y[data.get("stepNumber")] || 0;

  const x = (event.x - startX) / ((endX - startX) || width);
  const y = (event.y - startY) / ((endY - startY) || height);
  const both = (x + y) / 2;

  return {
    x: limit(x),
    y: limit(y),
    both: limit(both),
  };
};

export default pointerDefault;
