import checkAction from "./check-action";
import getOptions from "./get-options";
import getPriority from "./get-priority";
import processMulti from "./process-multi";
import processProperty from "./process-property";

/**
 * @typedef {Object} ParentProps
 * @property {String} original
 * @property {Boolean} priority
 * @property {Boolean} action
 * @property {Boolean} [reference]
 * @property {Array<Object>} [multi]
 */

/**
 * Process single parent CSS property
 * @param {string} input
 * @param {import("./get-trigger").TriggerProps} trigger
 * @param {Object|null} additionalOptions
 * @param {number} stepNumber
 */
const processParent = (input, trigger, additionalOptions, stepNumber = 0) => {
  let data;
  let multi;
  let action;

  const original = input;

  /**
   * Check priority
   */
  const priorityData = getPriority(input);
  const priority = priorityData.priority;

  /**
   * Check options
   */
  const optionsData = getOptions(priorityData.rest, additionalOptions);
  const options = optionsData.options;

  /**
   * @type {import("../../../utils/get-function").FunctionObject}
   * Process the rest of the string and get the data from property
   * definition that looks like a function
   * @example `opacity(.4)`
   */
  data = processProperty(optionsData.rest, priority, trigger);

  data.stepNumber = stepNumber;

  action = checkAction(data);

  if (data.reference) {
    return {
      [data.name]: {
        priority,
        action,
        reference: true,
        referenceStarted: false,
      },
    }
  }

  data.trigger = trigger || null;

  /**
   * Divide the properties group with the pipe "|" and process each property inside
   */
  multi = processMulti(data, options);

  return {
    [data.name]: {
      priority,
      action,
      multi,
      original,
      reference: false,
    },
  };
};

export default processParent;
