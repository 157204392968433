/**
 * ------------------------------------------------------------------------
 * ReplaceAll String prototype
 * ------------------------------------------------------------------------
 */

/**
 * Replaces all occurrences of specified substrings in a string with corresponding replacement values.
 * @param {Object.<string, string>} args - An object containing key-value pairs where each key is the substring to be replaced and the value is its replacement.
 * @returns {string} A new string with all occurrences of the specified substrings replaced.
 */
const indexReplaceAll = function (string, args) {
  for (const i in args) {
    /** Optimization: skip if there is not matching character */
    if (string.indexOf(i) == -1) {
      continue;
    }

    const substr = i;
    const newSubstr = args[i];

    do {
      string = string.replace(substr, newSubstr);
    } while (string.indexOf(substr) !== -1);
  }

  return string;
};

export default indexReplaceAll;