/**
 *
 * @param {import("../../../../utils/process-values-in-resolutions").PropertyMapProps} data
 * @param {import("../../../store/utils/get-trigger").TriggerProps} trigger
 */
const statusInteractive = (data) => {
  const progressFinished = data.get("progress") === data.get("lastProgress");

  switch (true) {
    case data.get("percents") === 0:
      if (data.get("statusProgress") === null) {
        return "idle";
      }

      if (progressFinished) {
        if (!data.get("finished")) {
          data.set("finished", true);
          data.set("started", false);
          return "finished";
        }
      } else {
        if (!data.get("runningDone")) {
          data.set("runningDone", true);
          return "running";
        }
      }
      break;

    case data.get("percents") === 1:
      if (data.get("statusProgress") === null) {
        return "started";
      }

      if (progressFinished) {
        if (!data.get("finished")) {
          data.set("finished", true);
          data.set("started", false);
          return "finished";
        }
      } else {
        if (!data.get("runningDone")) {
          data.set("runningDone", true);
          return "running";
        }
      }
      break;

    default:
      // if (data.get("percents") > 0 && data.get("percents") < 1) {
      //   if (!data.get("started")) {
      //     data.set("started", true);
      //     data.set("finished", false);
      //     return "started";
      //   }
      // } else {
      //   if (!data.get("started")) {
      //     data.set("started", true);
      //     data.set("finished", false);
      //     return "started";
      //   }
      // }

      if (!data.get("started")) {
        data.set("started", true);
        data.set("finished", false);
        return "started";
      }

      if (progressFinished) {
        if (!data.get("idleDone")) {
          data.set("idleDone", true);
          data.set("runningDone", false);
          return "idle";
        }
      } else {
        if (!data.get("runningDone")) {
          data.set("runningDone", true);
          data.set("idleDone", false);
          return "running";
        }
      }
  }

  return "";
};

export default statusInteractive;
