import Main from "../Main";

Main.prototype.releaseAll = function (final) {
  const mapPool = this.mapPool;
  const arrayPool = this.arrayPool;

  for (const nameMap of final.values()) {
    for (const finalI of nameMap.values()) {
      for (const finalIndex of finalI.values()) {
        arrayPool.release(finalIndex.get("result"));
        mapPool.release(finalIndex);
      }
      mapPool.release(finalI);
    }
    mapPool.release(nameMap);
  }
};

export default Main.releaseAll;
