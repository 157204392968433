/**
 * Get child [data-tor] elements inside a container
 * @param {import("../Container").ContainerClass} container
 */
const getChildElements = (container) => {
  const elements = container.element.querySelectorAll("[data-tor]");

  for (const element of elements) {
    container.targets.add(element);
  }
}

export default getChildElements;