import getTarget from "./get-target";
import warnings from "../../../utils/warnings";
import attributeAdd from "./attribute-add";
import attributeToggle from "./attribute-toggle";
import attributeRemove from "./attribute-remove";
import attributeReplace from "./attribute-replace";

/**
 * Attribute actions
 * @param {import("../../Main").MainClassProps} main
 * @param {import("../../../utils/process-values-in-resolutions").PropertyMapProps} property
 * @param {import("../../store/utils/process-parent").ParentProps} parent
 * @param {String} type - add/get/remove
 */
const attributeActions = (main, property, parent, type) => {
  /**
   * Check for attribute name
   * @type {String}
   */
  const name = property.get("options")?.get("name").get("current").get("value");

  /**
   * Get attribute values (optional)
   * @type {String}
   */
  const values =
    property
      .get("options")
      .get("value")
      ?.get("current")
      .get("output")
      .replaceAll("▪", " ") || "";

  /**
   * Get the target (this.element or external)
   * @type {Array<Element>}
   */
  const targets = getTarget(main, property.get("options"));

  if (!name) {
    warnings.noAttributeName(parent);
    return;
  }

  switch (type) {
    case "add":
      attributeAdd(targets, name, values);
      break;

    case "toggle":
      attributeToggle(targets, name, values, property);
      break;

    case "remove":
      attributeRemove(targets, name, values);
      break;

    case "replace":
      attributeReplace(targets, name, values);
      break;
  }
};

export default attributeActions;
