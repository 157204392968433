import WINDOW from "../../../utils/window";

const pointerStatus = () => {
  const pointer = WINDOW.pointer;
  const direction = pointer.direction;
  const modeX = pointer.mode.x;
  const modeY = pointer.mode.y;
  const modeBoth = pointer.mode.both;
  const lastDirection = pointer.lastDirection;

  lastDirection.x = direction.x;
  lastDirection.y = direction.y;

  switch (true) {
    case pointer.x - pointer.lastRafX > 0:
      pointer.running = true;
      direction.x = 1;

      modeX.enter = true;
      modeX.leave = false;
      break;

    case pointer.x - pointer.lastRafX < 0:
      pointer.running = true;
      direction.x = -1;

      modeX.enter = false;
      modeX.leave = true;
      break;

    case pointer.y - pointer.lastRafY > 0:
      pointer.running = true;
      direction.y = 1;

      modeY.enter = true;
      modeY.leave = false;
      break;

    case pointer.y - pointer.lastRafY < 0:
      pointer.running = true;
      direction.y = -1;

      modeY.enter = false;
      modeY.leave = true;
      break;

    default:
      pointer.running = false;
      direction.x = lastDirection.x;
      direction.y = lastDirection.y;
      break;
  }

  if (modeX.leave && modeY.leave) {
    modeBoth.enter = false;
    modeBoth.leave = true;
  } else {
    modeBoth.enter = true;
    modeBoth.leave = false;
  }

  pointer.lastRafX = pointer.x;
  pointer.lastRafY = pointer.y;

  // if (direction.x === 1) {
  //   modeX.enter = true;
  //   modeX.leave = false;
  // }
  // if (direction.x === -1) {
  //   modeX.enter = false;
  //   modeX.leave = true;
  // }

  // if (direction.y === 1) {
  //   modeY.enter = true;
  //   modeY.leave = false;
  // }
  // if (direction.y === -1) {
  //   modeY.enter = false;
  //   modeY.leave = true;
  // }
};

export default pointerStatus;
