/**
 * Run "animate" triggers
 */
const onAnimate = (targets) => {
  if (!targets) {
    return;
  }

  for (const main of targets) {
    /** @type {import("../store/utils/get-trigger").TriggerProps} */
    let trigger;

    for (trigger of main.triggers.animate) {
      const event = main.customEvents[`animateenter:${trigger.name}`];
      main.on.animateenter(event);
    }
  }
}

export default onAnimate;