import initTarget from "../../../utils/init-target";
import onAnimate from "../../events/on-animate";
import onPointerMove from "../../events/on-pointer-move";
import onScroll from "../../events/on-scroll";
import onTimeout from "../../events/on-timeout";
import classChangeListener from "../../listeners/class-change-listener";

const applyAsyncReferences = (mains) => {
  setTimeout(() => {
    classChangeListener();
    initTarget();
    onTimeout();
    onScroll();
    onPointerMove();
    onAnimate(mains);
  }, 100);
};

export default applyAsyncReferences;
