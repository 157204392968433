/**
 * ------------------------------------------------------------------------
 * Get operator
 * ------------------------------------------------------------------------
 */

const getOperator = function (value) {
  if (value.startsWith("+")) {
    return { type: "add", sign: "+" };
  }
  if (value.startsWith("-")) {
    return { type: "deduct", sign: "-" };
  }

  return { type: "none", sign: "" };
};

export default getOperator;
