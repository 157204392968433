import getComposition from "../Main/store/utils/get-composition";
import checkCalc from "./check-calc";
import easing from "./easing";
import getOptionsParameters from "./get-options-parameters";
import getValueType from "./get-value-type";
import indexReplaceAll from "./index-replace-all";
import parseNumber from "./parse-number";
/**
 *
 * @param {String} input - Input string with value to be parsed
 * @param {import("./get-function").FunctionObject | null} fn - Mark if the string is part of the CSs function
 * @returns
 */
const getValueAndUnit = (input, fn) => {
  let value;
  let unit;
  let type;

  let isCalc = false;

  let compositionData = getComposition(input);
  let composition = compositionData.composition;
  let rest = compositionData.rest;
  let parsed = parseNumber(rest);
  let output = rest;

  if (isNaN(parsed)) {
    value = rest;
    unit = null;
  } else {
    value = parsed;
    unit = rest.replace(`${value}`, "");
    unit = unit ? unit : null;
  }

  type = getValueType(value);

  // TODO: separate
  if (fn?.isFn) {
    value = null;
    unit = null;

    if (input.startsWith("∫")) {
      value = getOptionsParameters(input);

      if (fn.name === "bezier" || fn.name === "spring") {
        value = easing[fn.name](...value);
      }
    }

     const calc = checkCalc(fn);

     if (calc) {
       isCalc = true;
       rest = calc;
       value = calc;
     }
  }

  if (rest.indexOf("▓") > 0) {
    value = rest;
    output = indexReplaceAll(rest, { "▓": " " });
    unit = null;
    isCalc = true;
  }

  // TODO: this function is called 2x
  if (unit === "s") {
    unit = "ms";
    value = value * 1000;
  }

  if (value === "?") {
    type = "isInherit";
  }
  /**
   * If it is calc
   */
  if (/ (\+|\-|\/|\*) /.test(value)) {
    type = "isCalc";
  }


  return {
    value,
    unit,
    composition,
    isCalc,
    output,
    isString: false,
    isNumber: false,
    [type]: true,
  }
}

export default getValueAndUnit;