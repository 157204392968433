/**
 * Pointer coordinates where the 100% is in the middle of a screen and 0% on left/right edges
 * @param {Number} width
 * @param {Number} height
 * @param {import("../../../../utils/window").PointerEventType} event
 */
const pointerMiddle = (width, height, event) => {
  const sideX = width / 2 - event.x;
  const sideY = height / 2 - event.y;
  const a = Math.sqrt(Math.pow(sideX, 2) + Math.pow(sideY, 2));
  const b = Math.sqrt(Math.pow(width / 2, 2) + Math.pow(height / 2, 2));

  const x = 1 - Math.abs(sideX / (width / 2));
  const y = 1 - Math.abs(sideY / (height / 2));
  const both = 1 - a / b;

  return {
    x,
    y,
    both,
  };
};

export default pointerMiddle;