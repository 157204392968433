import staticDestroy from "../Main/static/static-destroy";
import staticInit from "../Main/static/static-init";
import G from "../utils/globals";
import checkIntersection from "./utils/check-intersection";
import getChildElements from "./utils/get-child-elements";

/**
 * @typedef {Object} ContainerClass
 * @property {Function} init
 * @property {Function} events
 * @property {Object} on
 * @property {Function} on.intersection
 * @property {HTMLElement} element
 * @property {Set} targets
 */

/**
 * ------------------------------------------------------------------------
 * @type {ContainerClass}
 * @param {HTMLElement} element
 * ------------------------------------------------------------------------
 */
class Container {
  constructor(element) {
    this.element = element;
    this.targets = new Set();
    this.events();

    getChildElements(this);
    checkIntersection(this.element);

    G.containers.all.add(this);
  }

  /**
   * ------------------------------------------------------------------------
   * Container destroy
   * ------------------------------------------------------------------------
   */
  static destroy(elements) {
    /**
     * Loop all containers and set them to visible
     * This makes all target (child) elements set to `this.visible`, too,
     * and remove any RAF fps limit
     */
    for (const container of G.containers.all) {
      container.on.intersection(true);
    }

    G.containers.all.clear();
    G.containers.observer?.disconnect();

    staticDestroy("Container", "[data-tor-container]", elements);
  }

  /**
   * ------------------------------------------------------------------------
   * Reload
   * ------------------------------------------------------------------------
   */
  static reload() {
    this.destroy();
    this.init();
  }

  /**
   * ------------------------------------------------------------------------
   * Container init
   * @param {NodeList} elements
   * ------------------------------------------------------------------------
   */
  static async init(elements) {
    return await staticInit("Container", Container, elements, null, "tor-container");
  }
}

export default Container;
