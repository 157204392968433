import Main from "../Main";
import actionGet from "./utils/action-get";
import actionAdd from "./utils/action-add";
import actionRemove from "./utils/action-remove";
import actionToggle from "./utils/action-toggle";
import actionReplace from "./utils/action-replace";

/**
 * Run various actions
 * @function
 * @name processAction
 * @param {String} name
 * @param {import("../../utils/process-values-in-resolutions").PropertyMapProps} property
 * @param {import("../store/utils/process-parent").ParentProps} parent
 * @this {import("../Main").MainClassProps}
 */
Main.prototype.processAction = function (name, property, parent) {
  switch (name) {
    case "get":
      return actionGet(this, property, parent);

    case "add":
      actionAdd(this, property, parent);
      break;

    case "remove":
      actionRemove(this, property, parent);
      break;

    case "toggle":
      actionToggle(this, property, parent);
      break;

    case "replace":
      actionReplace(this, property, parent);
      break;
  }
};

export default Main.processAction;
