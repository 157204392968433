/**
 * ------------------------------------------------------------------------
 * Separate attributes
 * ------------------------------------------------------------------------
 */

import getGroupSelectorAndParameters from "./get-group-selector";
import setDatasetToElement from "./set-dataset-to-element";

const separateAttributes = async function (group) {
  /**
   * Split attribute definitions separated by `;`
   * @example `#element => hover:opacity(.5); .image => hove:scale(2)`
   *                                        ^
   */
  const split = group.dataset.split(";");

  for (let attribute of split) {
    /** Empty/last split */
    if (!attribute) {
      continue;
    }

    const data = getGroupSelectorAndParameters(attribute, group);
    setDatasetToElement(data.elements, data.parameters, group);
  }
};

export default separateAttributes;