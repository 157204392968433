import { Target } from "../Target/index";
import G from "./globals";

const initTarget = () => {
  for (const [target, set] of G.targets) {
    Target.init(target, { set });
  }
};

export default initTarget;
