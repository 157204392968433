/**
 *
 * @param {String} input
 */
const optimizeComputed = (input) => {
  let result = input;

  if (input.indexOf(" ") > 0) {
    if (input.indexOf(",") > 0 ) {
      result = input.replaceAll(", ", ",");
    }

    if (result.startsWith("rgb(")) {
      result = result.replace("rgb", "rgba").replace(")", ",1)");
    }

    result = result.replaceAll(" ", "▄");
  }

  return result;
}

export default optimizeComputed;