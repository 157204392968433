import WINDOW from "../../../utils/window";

const scrollStatus = () => {
  const scroll = WINDOW.scroll;
  scroll.lastDirection.y = scroll.direction.y;

  switch (true) {
    case scroll.y - scroll.lastRafY > 0:
      scroll.running = true;
      scroll.direction.y = 1;
      break;

    case scroll.y - scroll.lastRafY < 0:
      scroll.running = true;
      scroll.direction.y = -1;
      break;

    default:
      scroll.running = false;
      scroll.direction.y = scroll.lastDirection.y;
      break;
  }

  if (scroll.direction.y === 1) {
    scroll.mode.y.enter = true;
    scroll.mode.y.leave = false;
  }
  if (scroll.direction.y === -1) {
    scroll.mode.y.enter = false;
    scroll.mode.y.leave = true;
  }

  scroll.lastRafY = scroll.y;
};

export default scrollStatus;

