import G from "../../utils/globals";
import WINDOW from "../../utils/window";
import onClassChange from "../events/on-class-change";

/**
 * Listens for changes in the class attribute of elements in the document body and triggers a callback accordingly.
 * @function
 * @name classChangeListener
 */
const classChangeListener = () => {
  /**
   * Checks if the class change listener is enabled, if not, exits the function.
   */
  if (!WINDOW.listeners.class || WINDOW.observers.class) {
    return;
  }

  /**
   * Options for the MutationObserver to observe changes in the class attribute.
   * @type {Object}
   * @property {String[]} attributeFilter - An array of attribute names to observe changes for.
   * @property {Boolean} attributeOldValue - Whether to record the old value of attributes.
   * @property {Boolean} subtree - Whether to observe changes in the entire subtree of the target node.
   */
  const observerOptions = {
    attributeFilter: ["class"],
    attributeOldValue: true,
    subtree: true,
  };

  /**
   * Callback function to be executed when a mutation is observed.
   * @function
   * @param {MutationRecord[]} entries - An array of mutation records.
   */
  const callback = (entries) => {
    const values = new Set();

    /** Iterate over each mutation record */
    for (const entry of entries) {
      /** Get the Torus instance attached to the target element */
      const torus = entry.target.Torus;

      /** If Torus instance is not found, skip to the next mutation record */
      if (!torus) {
        continue;
      }

      if (values.has(entry.oldValue)) {
        continue;
      }

      values.add(entry.oldValue);

      /** Split previous class names into an array, if oldValue is available, otherwise, initialize an empty array */
      const previousClasses = entry.oldValue ? entry.oldValue.split(" ") : [];
      /** Get the current class names of the target element as an array */
      const currentClasses = Array.from(entry.target.classList);

      /** Filter out the class names that are added since the last mutation */
      const added = currentClasses.filter(
        (className) => !previousClasses.includes(className)
      );
      /** Filter out the class names that are removed since the last mutation */
      const removed = previousClasses.filter(
        (className) => !currentClasses.includes(className)
      );

      /** Trigger the classchange event with the added and removed classes via the Torus Main event handler */
      if (torus.Target) {
        onClassChange(torus.Target, added, removed);
      }
      if (torus.Main) {
        onClassChange(torus.Main, added, removed);
      }
    }
  };

  /**
   * Creates a new MutationObserver with the specified callback and options, and starts observing changes in the document body.
   * @type {MutationObserver}
   */
  WINDOW.observers.class = new MutationObserver(callback);
  WINDOW.observers.class.observe(document.body, observerOptions);
};

export default classChangeListener;
