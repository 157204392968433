import getCenter from "../get-center";

/**
 * Pointer facing percents
 * @beta
 * @param {import("../../../bounds/set-bounds").BoundsProps} bounds
 * @param {import("../../../../utils/window").PointerEventType} event
 */
const pointerFacing = (bounds, event) => {
  const center = getCenter(bounds);
  const centerX = center.x;
  const centerY = center.y;

  const pointX = centerX - event.x;
  const pointY = centerY - event.y;
  const angle = Math.atan2(pointX, -pointY) * (180 / Math.PI);
  let both = angle / 360;

  return { both };
};

export default pointerFacing;