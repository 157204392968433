import immediateInitials from "../Main/initials/immediate-initials";
import addListeners from "../Main/listeners/add-listeners";
import Target from "./Target";

/**
 * Initialize the Target class
 */
Target.prototype.init = function (options) {
  /**
   * Add event listeners to this Target element
   */
  for (const item of options.set.values()) {
    addListeners(item.trigger, this, true);
    immediateInitials(item.main, this);
  }

  return Promise.resolve(this);
};

export default Target.init;
