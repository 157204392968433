import G from "../../../utils/globals";
import runAffectedElements from "../../events/run-affected-elements";

/**
 * Run reference
 * @param {import("../../store/utils/get-trigger").TriggerProps} trigger
 */
const runReference = (trigger, reference, parent) => {
  const items = G.references.regular[reference];

  for (const item of items) {
    const mode = trigger.mode.enter ? "enter" : "leave";

    if (!parent.referenceStarted) {
      runAffectedElements(item, reference, mode, trigger);
      parent.referenceStarted = true;
    }
  }
}

export default runReference;