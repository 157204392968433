import getValueAndUnit from "./get-value-and-unit";
import G from "./globals";

/**
 * Retrieves shorthand options
 * @param {string} input - The input string representing the option.
 * @param {Object} doubleValues - An object containing information about doubled values.
 * @example
 * const doubleValues = { offsets: 0 };
 * const option = getOptionShorthand("spring", doubleValues);
 * console.log(option); // Output: { name: "easing", value: "spring" }
 */
const getOptionShorthand = (input, doubleValues) => {
  let data;

  const map = new Map();

  /**
   * Check for "resolution" character "✦"
   * @example `[2x✦xl▹4x]`
   * @type number
   */
  const resolution = input.indexOf("✦");

  if (resolution > 0) {
    const extracted = input.substring(1, resolution);
    data = getValueAndUnit(extracted);
  } else {
    data = getValueAndUnit(input);
  }

  switch (true) {
    /**
     * Easing
     */
    case input === "spring" || input === "elastic":
      return map.set("easing", "spring(∫5⊗20)");

    case input.startsWith("ease") || input === "linear":
      if (G.easingTypes.has(input)) {
        return map.set("easing", G.easingTypes.get(input));
      }
      break;

    /**
     * Direction
     */
    case input === "alternate" || input === "reversed":
      return map.set("direction", input);

    case input === "infinite" || data.unit === "x":
      return map.set("iterations", input);

    /**
     * Methods
     */
    case input === "delta":
      return map.set("method", "delta(∫10)");

    case input === "delta-oriented":
      return map.set("method", "delta-oriented(∫10)");

    case data.unit === "s" || data.unit === "ms":
      return map.set("duration", input);

    case data.unit === "%" || data.unit === "px":
      if (!doubleValues.offsets) {
        ++doubleValues.offsets;

        return map.set("start", input);
      } else {
        return map.set("end", input);
      }

    case input === "skip-first":
      return map.set("skip-first", "true");

    default:
      return map.set("unknown", input);
  }
};

export default getOptionShorthand;
