import G from "../../utils/globals";
import WINDOW from "../../utils/window";
import pointerStatus from "../animate/utils/pointer-status";
import scrollStatus from "../animate/utils/scroll-status";

let then = performance.now();
let elapsed = 0;
let fps = 0;

const onRaf = () => {
  WINDOW.raf = requestAnimationFrame((now) => {
    /** @type {import("../Main").MainClassProps} */
    let main;

    const difference = now - WINDOW.lastNow;

    WINDOW.run = false;

    if (difference > 100) {
      fps += 100;
    } else {
      fps += difference;
    }

    WINDOW.now = fps;

    if (elapsed > 1000 / 1) {
      WINDOW.run = true;
      then = now - (elapsed % 1000) / 1;
    }

    if (G.elements.running.size) {
      for (main of G.elements.running.values()) {
        main.on.run();
      }

      for (main of G.elements.running.values()) {
        main.on.flat();
      }

      for (main of G.elements.running.values()) {
        main.on.css();
      }
    }

    WINDOW.lastNow = now;

    elapsed = now - then;

    scrollStatus();
    pointerStatus();

    onRaf();

    // if (!G.elements.running.size) {
    //   WINDOW.rafRunning = false;
    // }

    // if (WINDOW.rafRunning) {
    //   onRaf();
    // } else {
    //   cancelAnimationFrame(WINDOW.raf);
    // }
  });
};

export default onRaf;
