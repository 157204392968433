import getIterations from "./get-iterations";

/**
 *
 * @param {import("../../../utils/process-values-in-resolutions").PropertyMapProps} data
 * @param {*} trigger
 * @returns
 */
const checkFinishedIterations = (data, trigger) => {
  if (data.get("r_iterations")) {
    return getIterations(data, trigger);
  }

  /** Reset property data after the finish, to be ready in the next timeline iteration */
  if (data.get("mode").enter) {
    data.set("leavePercents", 0);
    data.set("lastLeaveProgress", 0);
  }

  return "finished";
};

export default checkFinishedIterations;
