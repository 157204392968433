import G from "../../utils/globals";
import dispatchTimeoutEvent from "./utils/dispatch-timeout-event";

const onTimeout = () => {
  for (const main of G.triggers.timeout.values()) {
    /**
     * @type {import("../store/utils/get-trigger").TriggerProps}
     */
    let trigger;

    for (trigger of main.triggers.timeout) {
      /** Dispatch custom timeout event */
      dispatchTimeoutEvent(trigger, main);
    }
  }
}

export default onTimeout;