const attributeAdd = (targets, name, values) => {
  for (const target of targets) {
    const original = target.getAttribute(name);

    if (original && !original.includes(values)) {
      target.setAttribute(name, `${original} ${values}`);
    } else {
      target.setAttribute(name, values);
    }
  }
}

export default attributeAdd;