import getIterableElements from "../../utils/get-iterable-elements";
import G from "../../utils/globals";

/**
 * Common function that destroy all element of given class
 * @param {String} name Class (module) name
 * @param {String | null} selector String selector
 * @param {Set | Array | undefined} targets
 */
const staticDestroy = (name, selector, targets) => {
  /** @type {HTMLElement} */
  let element;

  /** Convert give Set or single single element into array */
  let elements = Array.from(getIterableElements(targets || G.elements[name]));

  /** If no elements are given via function args, use the given string selector */
  if (!elements.length) {
    elements = document.querySelectorAll(selector);
  }

  for (element of elements) {
    const module = element.Torus?.[name];

    /** If element has already module removed */
    if (!module) {
      continue;
    }

    /** Check for any listeners and remove them */
    if (module.listeners) {
      for (const [listener, fn] of module.listeners) {
        element.removeEventListener(listener, fn);
      }
    }

    /** Check for bounds and remove them */
    if (module.bounds) {
      delete element.Torus.bounds;
      /** SVG path used in `translate-along-path` property alias */
      module.pathInitialized = false;
    }

    /** Remove module from global trigger object */
    // TODO: add `pointer`, `scroll` and `active` also
    if (module.triggers) {
      for (const trigger in module.triggers) {
        G.triggers[trigger].delete(module);
      }
    }

    /**
     * Check for "computed" CSS properties object (transform, opacity, ...).
     * Then get the names and remove all properties that was added by the TorusKit
     */
    if (module.computed) {
      for (const property of module.computed.keys()) {
        element.style.removeProperty(property);
      }
    }

    /** Finally, remove the Class (module) from `Torus` object on element */
    delete element.Torus[name];
  }
};

export default staticDestroy;
