import WINDOW from "../../../utils/window";
import setAlternateStartTime from "./set-alternate-start-time";

const getNullStartTime = (
  data,
  startTime,
  trigger,
  duration,
  dataDelay,
  triggerDelay
) => {
  if (!startTime) {
    if (data.get("fromTrigger")) {
      /**
       * If NOT delayed
       * This whole block is a "hack" for "leave" mode while the animation
       * is alternating
       */
      const fromData = data.get("alternating") && data.get("mode").leave;
      const fromTrigger = trigger.alternating && trigger.mode.leave;

      startTime = trigger.startTime;

      if (fromData || fromTrigger) {
        startTime = setAlternateStartTime(data, startTime, trigger, duration);
      }
      if (dataDelay) {
        startTime = trigger.startTime + dataDelay;
      }
      if (triggerDelay) {
        startTime = trigger.startTime + triggerDelay;
      }
    } else {
      startTime = WINDOW.now;
      data.set("firstAlternate", false);
    }

    data.set("firstAlternate", false);
    data.set("startTime", startTime);
  }

  return startTime;
};

export default getNullStartTime;
