import findVariable from "./find-variables";

/**
 * Replace regular expression matches
 * @param {String} input
 * @param {String} regex
 * @param {import("./process-variables").VariableStoreProps} store
 * @param {import("../Main").MainClassProps} main
 * @returns {String}
 */
const replaceMatches = (input, regex, store, main) => {
  let replaced = input;

  /** @type {RegExp} */
  const globalRegex = new RegExp(regex);

  /** @type {Array} */
  const match = input.match(globalRegex);

  for (const item of match) {
    const find = findVariable(store.variables[item], item, main, input);
    replaced = find ? replaced.replace(item, find) : null;
  }

  return replaced;
};

export default replaceMatches;
