import getIterableElements from "../../utils/get-iterable-elements";
import G from "../../utils/globals";

const staticInit = async (name, module, targets, options, attribute) => {
  const elements = getIterableElements(
    targets || document.querySelectorAll("[data-" + attribute +"]")
  );

  if (!elements?.length) {
    return;
  }

  const promises = [];

  for (const target of elements) {
    if (target?.Torus?.[name]) {
      continue;
    }

    target.Torus = target.Torus || {};
    target.Torus[name] = await new module(target).init(options);

    G.elements[name].add(target);

    promises.push(target.Torus[name]);
  }

  return Promise.all(promises);
};

export default staticInit;
