const countIterations = (data, trigger, entering) => {
  if (entering) {
    if (
      !data.get("r_alternate") ||
      (data.get("r_alternate") && data.get("percents") === 1 && trigger.entering)
    ) {
      let currentIteration = data.get("currentIteration");
      let overallIterations = data.get("overallIterations")
      data.set("currentIteration", ++currentIteration);
      data.set("overallIterations", ++overallIterations);
    }
  } else {
    if (
      !data.get("r_alternate") ||
      (data.get("r_alternate") && data.get("percents") === 0 && trigger.leaving)
    ) {
      let currentIteration = data.get("currentIteration");
      let overallIterations = data.get("overallIterations");

      data.set("currentIteration", --currentIteration);
      data.set("overallIterations", ++overallIterations);
    }
  }
};

export default countIterations;
