import Main from "../Main";

/**
 *
 * @param {String} name
 * @param {import("../store/utils/process-parent").ParentProps} parent
 */
Main.prototype.setAction = function (name, parent) {
  const data = parent.multi[0][0].current;

  return this.processAction(name, data, parent);
};

export default Main.setAction;
