import getNestedValue from "./get-nested-value";

const handleStoreCreation = (
  data,
  property,
  store,
  info,
  name,
  resolution,
  parent,
  index
) => {
  let reference;

  /** Composition (+)/(-) */
  const composition =
    data.get("composition") || info.original.current.get("composition");

  /** Step number */
  const step = info.stepNumber;

  /** Reference */
  const prop = parent === "transform" ? info.original : info.property;

  /** Live progress, entering and leaving values */
  const live = prop.current.get("live");

  const nested = getNestedValue(info, property, resolution, index, data);

  store.has("fn") || store.set("fn", null);
  store.has("value") || store.set("value", new Map());
  store.get("value").has(name) || store.get("value").set(name, new Map());

  reference = store.get("value").get(name);
  reference.set(step, new Map());

  const stepRef = reference.get(step);
  for (const item of [
    "value",
    "unit",
    "isString",
    "isInherit",
    "isCalc",
    "output",
    "isNumber",
    "isFn",
    "fn",
    "hasChild",
    "index",
    "path",
  ]) {
    stepRef.set(item, nested.get(item));
  }

  stepRef.set("composition", composition);
  stepRef.set("priority", info.priority);
  stepRef.set("live", live);
};

export default handleStoreCreation;
