/**
 * ------------------------------------------------------------------------
 * Get random number
 * ------------------------------------------------------------------------
 */

import round from "./round";

const getRandomNumber = (min, max) => {
  return round(Math.random() * (max - min) + min);
};

export default getRandomNumber;
