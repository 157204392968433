import onEvents from "../Main/events/on-events";
import staticDestroy from "../Main/static/static-destroy";
import staticInit from "../Main/static/static-init";
import G from "../utils/globals";

/**
 * Target
 */
class Target {
  constructor(element) {
    this.isTarget = true;

    /**
     * The target DOM element undergoing mutation.
     * @type {Element}
     */
    this.element = element;

    /**
     * Custom events
     */
    this.customEvents = {};

    /**
     * Target elements
     */
    this.targets = {};

    /**
     * Set of listeners - do not process trigger again if it's defined
     */
    this.listeners = new Map();

    /**
     * Object that contains defined trigger sets
     */
    this.triggers = {
      animate: new Set(),
      viewport: new Set(),
      class: new Set(),
      timeout: new Set(),
    };

    /**
     * Event listeners with their appropriate standard/custom event functions
     */
    this.on = onEvents(this);
  }

  static destroy() {
    staticDestroy("Target", null, G.elements.Target);
    G.targets.clear();
  }

  static async init(elements, options) {
    return await staticInit("Target", Target, elements, options);
  }
}

export default Target;
