import warning from "./warning";

const warnings = {
  noAttributeName: (parent) => {
    warning(
      "No option `name` in",
      parent.original,
      "found! You should add `<name: {attribute-name}>` as an option."
    );
  },

  noClassName: (parent) => {
    warning(
      "No option `name` in",
      parent.original,
      "found! You should add `<name: {class-name}>` as an option."
    );
  },

  noVariable: (original) => {
    warning("No definition for the", original, "variable found");
  },

  noValue: (original) => {
    warning("No value in parenthesis on", original, "property.");
  },

  notClosedOptions: (original) => {
    warning("Looks like the options definition", original, "is not regularly closed");
  },

  noInitialDefinition: (parent) => {
    warning(
      "No initial definition for",
      parent,
      "found. Using the one from the current trigger."
    );
  },

  numberOfValuesMismatch: (initial, current) => {
    warning(
      "Number of values in initial definition:",
      initial,
      "does not match with the current one:",
      current
    );
  },

  cannotFindInitialValue: (initial) => {
    warning(
      "Cannot find the corresponding value in initials values of",
      initial,
      "and the current one. Make sure they are the same count"
    );
  },

  unknownTriggerParameter: (original) => {
    warning("Unknown trigger parameter:", original, "");
  },

  multipleTargets: (parent) => {
    warning(
      "Multiple target elements found in",
      parent.original,
      "You should use the unique one."
    );
  },

  unknownDefaultProperty: (original, element) => {
    warning("Unknown property", original, "On the element: ");
    console.warn(element);
  },

  unknownAliasVariant: (original, alias) => {
    warning(
      "Unknown alias value",
      original,
      "You need to choose the one from: ",
      Object.keys(alias)
    );
  },
};

export default warnings;
