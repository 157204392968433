import runDynamicTrigger from "./run-dynamic-trigger";
import runInteractiveTrigger from "./run-interactive-trigger";

/**
 * Execute the selected trigger
 * @param {import("../../Main").MainClassProps} main
 * @param {import("../../store/utils/get-trigger").TriggerProps} trigger
 * @param {import("../../store/utils/get-trigger").TriggerModeProps} mode
 * @param {import("../on-events").EventTypeProps} type
 * @param {Number} startTime
 */
const runSelectedTrigger = (main, trigger, mode, type, startTime) => {
  /** Check if trigger is `scroll|mouse|pointer` */
  if (trigger.interactive) {
    runInteractiveTrigger(trigger);
  } else {
    /** Trigger is regular like {hover|click|.class} */
    runDynamicTrigger(trigger, startTime, mode, type);
  }

  /** Add the trigger into active triggers map if it is not initial */
  if (!trigger.initial) {
    main.addToActiveTriggers(trigger, startTime, mode, type);
  }

  /** Trigger is not iterating when in leave mode */
  if (mode.leave) {
    trigger.iterating = false;
  }

  /** Automatically set the timeline as `active` on when trigger is executed */
  trigger.timeline.info.active = true;
};

export default runSelectedTrigger;
