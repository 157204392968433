const getIterableElements = (elements) => {
  if (typeof elements === "string") {
    return [...document.querySelectorAll(elements)];
  }

  if (elements?.length) {
    return elements;
  }

  if (elements instanceof Node) {
    return [elements];
  }

  if (elements instanceof NodeList) {
    return Array.from(elements);
  }

  if (elements instanceof Set && elements.size) {
    return Array.from(elements);
  }

  return elements;
};

export default getIterableElements;
