import WINDOW from "../../utils/window";
import Main from "../Main";

/**
 * @type {import("../Main").MainClassProps}
 * @memberof Main.prototype
 * @param {import("../store/utils/get-trigger").TriggerProps} trigger
 */
// Main.prototype.setCustomEvents = function (trigger) {
const setCustomEvents = function (trigger, item) {
  /**
   * Viewport
   */
  if (trigger.viewport) {
    /** In viewport */
    item.customEvents[`viewportenter:${trigger.name}`] = new CustomEvent(
      "viewportenter",
      {
        detail: trigger,
      }
    );

    /** Out of viewport */
    item.customEvents[`viewportleave:${trigger.name}`] = new CustomEvent(
      "viewportleave",
      {
        detail: trigger,
      }
    );
  }

  /**
   * Check checkbox
   */
  if (trigger.check) {
    /** In viewport */
    item.customEvents[`check:${trigger.name}`] = new CustomEvent("check", {
      detail: trigger,
    });

    /** Out of viewport */
    item.customEvents[`uncheck:${trigger.name}`] = new CustomEvent("uncheck", {
      detail: trigger,
    });
  }

  /**
   * Reference
   */
  if (trigger.reference) {
    /** Reference enter */
    item.customEvents[`referenceenter:${trigger.trigger}`] = new CustomEvent(
      "referenceenter",
      {
        detail: trigger,
      }
    );

    /** Reference leave */
    item.customEvents[`referenceleave:${trigger.trigger}`] = new CustomEvent(
      "referenceleave",
      {
        detail: trigger,
      }
    );
  }

  /**
   * Animate
   */
  if (trigger.animate) {
    /** Animate enter */
    item.customEvents[`animateenter:${trigger.name}`] = new CustomEvent(
      "animateenter",
      {
        detail: trigger,
      }
    );

    /** Animate leave */
    item.customEvents[`animateleave:${trigger.name}`] = new CustomEvent(
      "animateleave",
      {
        detail: trigger,
      }
    );
  }

  /**
   * Timeout
   */
  if (trigger.timeout) {
    /** Animate enter */
    item.customEvents[`timeoutenter:${trigger.name}`] = new CustomEvent(
      "timeoutenter",
      {
        detail: trigger,
      }
    );

    /** Animate leave */
    item.customEvents[`timeoutleave:${trigger.name}`] = new CustomEvent(
      "timeoutleave",
      {
        detail: trigger,
      }
    );
  }

  /**
   * Class
   */
  if (trigger.class) {
    /** Class added */
    item.customEvents[`classadded:${trigger.name}`] = new CustomEvent(
      "classadded",
      {
        detail: trigger,
      }
    );

    /** Class removed */
    item.customEvents[`classremoved:${trigger.name}`] = new CustomEvent(
      "classremoved",
      {
        detail: trigger,
      }
    );

    WINDOW.listeners.class = true;

    item.triggers.class.add({
      name: trigger.name,
      class: trigger.trigger.slice(1),
    });

    // console.log("              ", item.triggers.class);
  }
};

export default setCustomEvents;
