import WINDOW from "../../../utils/window";

const runInteractiveTrigger = (trigger) => {
  /** @type {import("../../utils/window").ScrollEventType} */
  const type = WINDOW[trigger.type];
  const axis = trigger.parameters.axis;

  trigger.mode = type.mode[axis];

  const timeline = trigger.timeline;
  const current = timeline.info.currentStep;
  const steps = timeline.steps;

  steps[current].active = true;

  // steps[current].processed = true;
}

export default runInteractiveTrigger;