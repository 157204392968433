/**
 *
 * @param {import("../../store/utils/get-trigger").TriggerProps} trigger
 * @param {Number} startTime
 * @param {import("../../store/utils/get-trigger").TriggerModeProps} mode
 * @param {String} type
 */
const runDynamicTrigger = (trigger, startTime, mode, type) => {
  trigger.entering = type === "enter";
  trigger.leaving = type === "leave";
  trigger.modeChange = true;
  trigger.fromTrigger = true;
  trigger.delayDone = false;
  trigger.pauseDone = false;
  trigger.paused = false;
  trigger.startTime = startTime;
  trigger.mode = mode;
  trigger.isActive = mode.enter;
};

export default runDynamicTrigger;
