import runSelectedTrigger from "./events/utils/run-selected-trigger";

/**
 * Animation control
 */
const control = {
  /**
   * Restarts the animation
   * @param {Array<import("./Main").TorusElement>} elements
   */
  restart: (elements) => {
    /** @type {import("./Main").TorusElement} */
    let target;

    const targets = elements || document.querySelectorAll("[data-tor]");

    for (target of targets) {
      const main = target.Torus.Main;

      // @ts-ignore
      for (const trigger of main.activeTriggersNames) {
        const mode = { enter: true, leave: false };
        const type = "enter";
        const startTime = performance.now();

        runSelectedTrigger(main, trigger, mode, type, startTime);
      }
    }
  },
};

export default control;
