import WINDOW from "../../utils/window";
import Main from "../Main";
import checkDelay from "./utils/check-delay";
import timelineIterations from "./utils/timeline-iterations";
import shouldChangeStep from "./utils/should-change-step";

/**
 * Process steps of a given timeline
 * @param {import("../store/utils/process-timeline").TimelineProps} timeline
 * @param {import("../store/utils/get-trigger").TriggerProps} trigger
 */
Main.prototype.computeStep = function (
  step,
  timeline,
  trigger,
  info,
  currentStep,
  mode
) {
  /** Step count reference */
  const stepCount = info.stepCount - 1;

  /** Current step status. Recreate on every iteration */
  const status = { started: false, finished: false };

  /** Get the status of all parent properties for the current step */
  const result = this.computeParents(step, trigger, mode, info, status);

  /** Force "finish" the result to keep running the `timelineIterations` function for pause computing */
  if (trigger.paused) {
    result.finished = true;
  }

  /** If all properties has finished */
  if (result.finished) {
    /** Checks if the step change is required based on the trigger mode and current step count */
    shouldChangeStep(trigger, currentStep, stepCount, info, timeline.steps);

    const paused = timelineIterations(trigger, info, currentStep, timeline);

    if (paused) {
      return;
    }

    trigger.startTime = WINDOW.now;
  } else {
    trigger.stepChange = false;
  }

  if (trigger.interactive && !trigger.activeProperties.size) {
    info.active = false;
  }
};

export default Main.computeStep;
