import G from "../../../utils/globals";
import handleCalc from "./handle-calc";
import handleChildValues from "./handle-child-values";
import handleInitialsCreation from "./handle-initials-creation";
import handleStoreCreation from "./handle-store-creation";

/**
 *
 * @param {String} parent - parent CSS property name
 * @param {Number|String} index - numbered or named index
 * @param {import("../../../utils/process-values-in-resolutions").PropertyMapProps} property
 * @param {import("../../store/utils/create-computed-store").ComputedSingletProps} store
 * @param {import("../../store/utils/get-trigger").TriggerProps} trigger
 * @param {import("../../Main").MainClassProps} main
 */
const loopChildValues = (
  parent,
  property,
  store,
  trigger,
  main,
  info,
  first,
  resolution,
  previous
) => {
  const name = trigger.initial ? "initial" : trigger.name;
  /** @type {import("../../../utils/process-values-in-resolutions").PropertyMapProps} */
  const data = property[resolution] || info.original.current;

  /** Initials */
  const initials = main.initials[parent][info.index];

  /** Named index (translateX...) or 0 */
  let indexName = info.isNamed ? info.fn : 0;

  /** If it's multi-index */
  if (!info.isNamed && Object.entries(initials).length > 1) {
    indexName = info.valueIndex;
  }

  const initial = initials[indexName];

  if (data.get("hasChild")) {
    handleChildValues(
      store,
      data,
      info,
      parent,
      trigger,
      main,
      resolution,
      data
    );
  } else {
    /** Check for CSS `calc() function` */
    handleCalc(data, property, info, resolution);

    /** Create a `store[trigger-name][step-number]` that will be added int "flat" object */
    handleStoreCreation(data, property, store, info, name, resolution, parent);

    /** Does not have an initial value defined */
    handleInitialsCreation(store, info, initial);
  }

  const isRgba = previous?.get("fn") === "rgba";

  store.set("hasChild", data.get("hasChild"));
  store.set("isString", data.get("isString"));
  store.set("isCalc", data.get("isCalc"));
  store.set("depth", data.get("depth"));
  store.set("index", data.get("index"));
  store.set("first", first);
  store.set("info", { original: info.original, initial });
  store.set("isTransform", parent === "transform");
  store.set("separator", isRgba ? ", " : " ");

  if (data.has("r_order")) {
    store.set("order", data.get("r_order"));
  }

  const flat = main.flat.get(parent).get(info.index).get(0);

  /** If initial has more resolutions than current property */
  const missing = [];

  if (!initial) {
    return;
  }

  if (initial.current) {
    for (const resolution in initial) {
      if (!info.property[resolution]) {
        missing.push(resolution);
      }
    }

    for (const resolution of missing) {
      flat.set(resolution, flat.get("current"));
    }
  }

  flat.get(info.resolution).add(store);

  G.references.resolutions.add(flat);
};

export default loopChildValues;
