import deepObjectToMap from "../../../utils/deep-object-to-map";
import getOptionShorthand from "../../../utils/get-option-shorthand";
import getResolutionsObject from "../../../utils/get-resolutions-object";
import { optionsObject } from "../../../utils/globals";
import indexReplaceAll from "../../../utils/index-replace-all";

/**
 * @typedef {Object} OptionsData
 * @property {OptionsResolutions} duration
 * @property {OptionsResolutions} pause
 * @property {OptionsResolutions} delay
 * @property {OptionsResolutions} easing
 * @property {OptionsResolutions} iterations
 * @property {OptionsResolutions} direction
 * @property {OptionsResolutions} start
 * @property {OptionsResolutions} end
 * @property {OptionsResolutions} method
 * @property {OptionsResolutions} [method-init]
 * @property {OptionsResolutions} [skip-first]
 * @property {OptionsResolutions} threshold
 * @property {OptionsResolutions} target
 */

/**
 * @typedef {Object} OptionsResolutions
 * @property {Object} all
 * @property {{ value: any } | Map<String, any>} current
 * @property {Object} [xs]
 * @property {Object} [sm]
 * @property {Object} [md]
 * @property {Object} [lg]
 * @property {Object} [xl]
 * @property {Object} [xxl]
 */

/**
 * @typedef {Map<keyof OptionsData, OptionsData[keyof OptionsData]>} OptionsMapProps
 * @return {Map<String, Object>}
 */

/**
 * Convert and process the options from string into object
 * @param {string} string
 * @input `durationː0.2s,easingː[spring(20s)✦md▹spring(40)],pauseː[2s✦xl▹5s]`
 */
const processOptions = (string) => {
  /**
   * Some options can have double values
   * @example `10%, 80%` = `offset-start: 10%, offset-end: 80%`
   * @type {object}
   */
  const doubleValues = {
    offsets: 0,
  };

  /**
   * Default options
   * @typedef {Object} Options
   * @property {Object<Object>} - Configuration for the animation duration.
   */
  const result = deepObjectToMap(optionsObject);

  if (!string) {
    return result;
  }

  if (string.startsWith(",")) {
    string = string.slice(1);
  }

  /**
   * Options has custom settings in parenthesis
   */
  if (string.indexOf("(") > 0) {
    const match = string.match(/\((.*?)\)/g);

    if (match) {
      for (let item of match) {
        string = string.replace(item, () => {
          if (item.indexOf("∫") > 0) {
            return indexReplaceAll(item, { ",": "⊗" });
          } else {
            return indexReplaceAll(item.replace("(", "(∫"), { ",": "⊗" });
          }
        });
      }
    }
  }

  const options = string.split(",");

  for (const option of options) {
    /**
     * @type {String} Option name
     * @example `duration`
     */
    let name;
    /**
     * @type {String} Option value with unit
     * @example `1s`
     */
    let value;

    const split = option.split("ː");

    /**
     * Is full named option
     * @example `<duration: 1s>` / `<1s>`
     */
    const dataIndex = split[1] ? 1 : 0;
    const data = getOptionShorthand(split[dataIndex], doubleValues);
    // console.log(split, data);
    name = split[1] ? split[0] : data.keys().next().value;
    value = data.values().next().value;

    result.set(name, getResolutionsObject(value, { name }));
    result.get(name).set("isCustom", true);
  }

  return result;
};

export default processOptions;
