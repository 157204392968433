import getClusterContent from "../../../utils/get-cluster-content";
import isCluster from "../../../utils/is-cluster";
import getReplacedResult from "./get-replaced-result";

/**
 * Replace aliases with regular CSS properties
 * @param {Array<import("./get-aliases").AliasDefinition>} aliases Array of aliases
 * @param {string} input @example `hover:pull-up()`
 */
const replaceAliases = (aliases, input) => {
  /**
   * Original input string
   * @example `hover:pull-up()`
   */
  let result = input;

  /** Set of possible initial properties */
  const initials = new Set();

  /** Loop trough founded aliases */
  for (const item of aliases) {
    /** Split the alias on the "trigger" and the rest */
    const split = item.attribute.split(":");

    /** Select actual alias attribute without a trigger */
    let attribute = split[1] ? split[1] : split[0];

    if (isCluster(attribute)) {
      attribute = getClusterContent(attribute);
    }

    /** Regex for exact matching of alias in current attribute */
    // const regex = new RegExp(
    //   `(^|(?:█))\\b${item.name}\\((.*?)(?=█)(>\\)|\\)\\))|(^|(?:█))\\b${item.name}\\((.*?)\\)`,
    //   "g"
    // );
    // const regex = new RegExp(`\\b${item.name}+\\([^)]*\\)`, "g");
    // const regex = new RegExp(`(?<![a-zA-Z-])\\b${item.name}\\([^()]*\\)`, "g");
    // console.log(attribute);
    // const regex = new RegExp(
    //   `(?<![a-zA-Z-])\\b(?:${item.name})\\((?:[^()]*|\\((?:[^()]*|\\([^()]*\\))*\\))*\\)`,
    //   "g"
    // );
    const regex = new RegExp(
      `\\b${item.name}\\((?:[^()]*|\\((?:[^()]*|\\([^()]*\\))*\\))*\\)`,
      "g"
    );

    /** Find all alias occurrences */
    const matches = attribute.match(regex);

    if (!matches) {
      continue;
    }

    for (const match of matches) {
      result = getReplacedResult(item, match, result, initials);
    }
  }

  return `${Array.from(initials).join(" ")} ${result}`;
};

export default replaceAliases;
