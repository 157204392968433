import getFunction from "../../../utils/get-function";
import getShorthand from "../../../utils/get-shorthand";
import G from "../../../utils/globals";

/**
 * Get property parameters from string
 * @param {String} input
 * @param {Boolean} priority
 * @param {import("./get-trigger").TriggerProps} trigger
 */
const processProperty = (input, priority, trigger) => {
  const fn = getFunction(input);

  if (!fn) {
    if (input.startsWith("@")) {
      return { name: input, reference: true }
    } else {
      return;
    }
  }

  const shorthand = getShorthand(fn.name);

  if (shorthand.parent) {
    fn.name = shorthand.parent;
    fn.original = shorthand.value;
    fn.parameters = `${shorthand.value}(${fn.parameters})`
  } else {
    fn.name = shorthand.value;
  }

  fn.isNamed = G.namedProperties.has(fn.name);
  fn.isMultiIndex = G.multiIndex.has(fn.name);
  fn.priority = priority;
  fn.axis = trigger?.parameters?.axis || null;

  return fn;
}

export default processProperty;