import warnings from "../../../utils/warnings";

/**
 * Get attribute
 * @param {import("../..").MainClassProps} main
 * @param {import("../../../../utils/process-values-in-resolutions").PropertyMapProps} property
 * @param {import("../../../store/utils/process-parent").ParentProps}
 */
const attributeGet = (main, property, parent) => {
  const attribute = property.options.name?.current.value;

  if (!attribute) {
    warnings.noAttributeName(parent);
  }

  return main.element.getAttribute(attribute);
};

export default attributeGet;
