import parseNumber from "./parse-number";

const getOptionsParameters = (string) => {
  return string
    .slice(1)
    .split("⊗")
    .map((item) => {
      const val = parseNumber(item);
      if (isNaN(val)) {
        return item;
      } else {
        return val;
      }
    });
};

export default getOptionsParameters;
