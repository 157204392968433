import G from "../utils/globals";
import optimizeString from "../utils/optimize-string";
import Main from "./Main";
import processAliases from "./aliases/process-aliases";
import ObjectPool from "../utils/object-pool";
import immediateInitials from "./initials/immediate-initials";
import WINDOW from "../utils/window";

/**
 * Initializes the Main object
 * @this {import("./Main").MainClassProps}
 * @returns {Promise<void>} A promise that resolves when initialization is complete.
 */
Main.prototype.init = async function (options) {
  /**
   * Element is visible at the beginning
   */
  this.visible = true;

  /**
   * Set `this.on()` function
   */
  this.events();

  /**
   * Current element's style
   * @type {CSSStyleDeclaration}
   */
  this.style = this.element.style;

  /** Initialize the object pool */
  this.mapPool = new ObjectPool(() => new Map());
  this.arrayPool = new ObjectPool(() => []);

  /**
   * Optimized dataset string
   * @type {string}
   */
  const dataset = optimizeString(this.element.dataset.tor);

  /**
   * @type {import("./aliases/process-aliases").Aliases}
   */
  const aliases = processAliases(dataset);

  /**
   * Process variables
   * @type {import("./variables/process-variables").Variables}
   */
  const variables = this.processVariables(aliases);

  /**
   * Dataset with replaced variables
   * @type {string}
   */
  this.dataset = variables.dataset;

  /**
   * Local variables
   * @type {Object}
   */
  this.variables = variables.variables;

  /**
   * Computed styles
   * @type {CSSStyleDeclaration}
   */
  this.computedStyle = getComputedStyle(this.element);

  /**
   * Store all triggers with properties
   * @type {Object}
   */
  this.store = this.createStore(options);

  /**
   * Apply initial CSS styles and trigger all triggers that has potentially
   * initial states such as "class" is already added, checkbox is check etc..
   */
  immediateInitials(this, this);

  /**
   * Return promise
   */
  return Promise.resolve(this);
};

export default Main.init;
