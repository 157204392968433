import G from "../../../utils/globals";
import warnings from "../../../utils/warnings";
import processFunction from "../../store/utils/process-function";
import setInitialsCSS from "../../store/utils/set-initials-css";

const getDefaultInitial = (name, multiIndex, index, property, main) => {
  if (G.defaults[name]) {
    /** Get the default value */
    const defaults = G.defaults[name][index];

    if (!defaults) {
      warnings.unknownDefaultProperty(index, main.element);
      return;
    }

    /** @type {import("../../../utils/process-values-in-resolutions").PropertyMapProps} */
    const computedDefaults = processFunction(defaults)[0].current;

    const initial = main.initials[name][multiIndex];

    /** Has named index. @example `translateX`, `blur` */
    if (property.current.get("isNamed")) {
      const value = computedDefaults.get("value")[0];

      initial[computedDefaults.get("fn")] = value;
      initial[computedDefaults.get("fn")].isNamed = true;
    } else {
      initial[index] = initial[index] || {};
      initial[index].current = computedDefaults;
    }
  } else {
    main.initials[name][multiIndex][index] = property;
    setInitialsCSS(main);
    warnings.noInitialDefinition(name);
  }

  // console.log(main.initials);
};

export default getDefaultInitial;
