/**
 * Dispatch timeout event
 * @param {import("../../store/utils/get-trigger").TriggerProps} trigger
 * @param {import("../../Main").MainClassProps} main
 */
const dispatchTimeoutEvent = (trigger, main) => {
  const start = setTimeout(() => {
    const startEvent = main.customEvents[`timeoutenter:${trigger.name}`];
    main.on[startEvent.type](startEvent);
    // main.element.dispatchEvent(startEvent);

    clearTimeout(start);

    if (trigger.r_end.value) {
      const end = setTimeout(() => {
        const endEvent = main.customEvents[`timeoutleave:${trigger.name}`];
        main.on[endEvent.type](endEvent);
        // main.element.dispatchEvent(endEvent);

        clearTimeout(end);
      }, trigger.r_end.value);
    }

  }, trigger.r_start.value);
}

export default dispatchTimeoutEvent;