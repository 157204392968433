/**
 * Dispatch viewport in/out custom event
 * @param {Boolean} isInview
 * @param {import("../../store/utils/get-trigger").TriggerProps} trigger
 * @param {import("../../Main").MainClassProps} main
 */
const dispatchViewportEvent = (isInview, trigger, main) => {
  if (isInview) {
    if (!trigger.isInview) {
      const event = main.customEvents[`viewportenter:${trigger.name}`];
      main.element.dispatchEvent(event);

      trigger.isInview = true;
    }
  } else {
    if ((trigger.isInview || trigger.isInview === null) && !trigger.r_keep) {
      const event = main.customEvents[`viewportleave:${trigger.name}`];
      main.element.dispatchEvent(event);

      trigger.isInview = false;
    }
  }
};

export default dispatchViewportEvent;
