import checkForType from "./check-for-type";
import splitByTopLevelSeparator from "./split-by-top-level-separator";

/**
 * Get array of strings and extract the resolutions to resolution-based object
 * @param {array<string>} resolutions
 * @input `['2s', 'sm▹4s', 'xl▹5s']`
 * @returns {Object} `{all: '2s', sm: '4s', xl: '5s'}`
 */
const processResolutions = (resolutions) => {
  const result = {};

  /** Process each resolution within the cluster */
  for (const resolution of resolutions) {
    const split = splitByTopLevelSeparator(resolution, "▹");

    /** If a media query is present, add it to the result object */
    if (split[1]) {
      result[split[0]] = checkForType(split[1]);
      // result[split[0]] = split[1];
    } else {
      /**  Otherwise, add the resolution to the "all" property */
      result.all = checkForType(split[0]);
      // result.all = split[0];
    }
  }

  return result;
}

export default processResolutions;