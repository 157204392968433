import getFunction from "../../../utils/get-function";
import indexReplaceAll from "../../../utils/index-replace-all";
import warnings from "../../../utils/warnings";
import extractLastOptions from "../../store/utils/extract-last-options";

/**
 * Set the alias - replace the alias in the string with the regular CSS property including options
 * @param {string} match Exact match of the alias (including parenthesis) in the attribute
 * @param {import("./get-aliases").AliasDefinition} alias
 */
const setAlias = (match, alias, replacer) => {
  /** Final string */
  let final = "";

  /** Initial CSS properties */
  let initials = [];

  /** Extract the data from the match */
  const fn = getFunction(match);

  /** Get the options */
  const extracted = extractLastOptions(fn.parameters);

  /** Alias definition */
  const def = alias.definition;

  /** Options */
  const options = extracted.options || "";

  /** Replacer */
  let A_replacement;

  if (replacer) {
    A_replacement = replacer
  } else if (def.variants) {
    const variants = def.variants[extracted.rest];

    if (variants) {
      A_replacement = def.variants[extracted.rest].R
    } else {
      warnings.unknownAliasVariant(extracted.rest, def.variants);
    }
  } else {
    A_replacement = def.R;
  }

  /** Initial property */
  let A_initial = def.I;
  /** Addition/composition */
  let A_addition = def.A || "";
  /** Value */
  let A_value;
  /** Alias Options */
  let A_options = def.O || "";
  /** Custom Options */
  let A_custom = def.CV || "";

  if (extracted.rest) {
    A_value = `${A_addition}${extracted.rest}`;
  } else {
    A_value = def.V || 0;
  }

  if (A_custom) {
    let customValue = A_custom.value;

    for (const option of options.split(",")) {
      if (option.includes(A_custom.name)) {
        customValue = option.substring(option.indexOf("ː") + 1);
        break;
      }
    }

    A_custom = customValue;
  }

  final = A_replacement.replaceAll("«V»", A_value);
  final = final.replace("«O»", A_options + (options ? "," + options : ""));
  final = indexReplaceAll(final, { "<,": "<" });
  final = final.replace("«CV»", A_custom);
  final = final.replace("<>", "");

  if (A_initial) {
    for (const item of A_initial) {
      initials.push(item.replace("«V»", A_value));
    }
  }

  const initial = initials.join(" ");

  return {
    final,
    initial,
  };
};

export default setAlias;
