import checkForType from "./check-for-type";
import getClusterContent from "./get-cluster-content";
import isCluster from "./is-cluster";
import processResolutions from "./process-resolutions";
import splitByTopLevelSeparator from "./split-by-top-level-separator";

/**
 * Extract the resolution cluster string and get the resolutions object
 * @param {string} input - The input string containing resolutions.
 * @input `[2s✦xl▹5s]`
 * @return Object: `{resolution}: string`
 */
const getResolutionsFromCluster = (input, data) => {
  if (input.indexOf("✦") > 0 && isCluster(input, "{", "}")) {
    /**
     * Extract content from cluster
     * @example `[2s md::3s]` => `2s✦md▹3s`
     * @param {string} cluster - The cluster content.
     * @returns {string} The extracted content.
     */
    const extracted = getClusterContent(input, "{", "}");

    /**
     * @type {array}
     */
    const split = splitByTopLevelSeparator(extracted, "✦");

    /** Return parsed resolutions for multiple media queries */
    return processResolutions(split);
  } else {
    /** Return a simple object with the "all" property for a single resolution */
    return {
      all: checkForType(input, data),
      // all: input,
    };
  }
}

export default getResolutionsFromCluster;