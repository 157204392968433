import Main from "../Main";

/**
 * @typedef {Set} ActiveTriggersSet
 */

/**
 * @typedef {Object} ShiftType
 * @property {Object} translateX
 * @property {Object} translateY
 */

/**
 * @typedef {Object} MainTriggers
 * @property {Set} viewport
 * @property {Set} class
 */

Main.prototype.defaults = function () {
  /**
   * @type {string}
   */
  this.dataset = "";

  this.initials = {};

  this.initialsFlat = new Map();

  this.appliedInitials = null;

  this.store = {};

  this.values = {};

  this.variables = {};

  this.customEvents = {};

  this.computedStyle = {};

  /**
   * Stores trigger information
   * @type {import("../store/utils/get-trigger").TriggerProps}
   */
  this.activeTriggers = new Map();

  /** @type {ActiveTriggersSet} */
  this.activeTriggersSet = new Set();

  this.computed = new Map();

  this.flat = new Map();

  this.final = new Map();

  /** @type {ShiftType} */
  this.shift = "";

  /** Set if the element's bounds needs to be calculated */
  this.isBoundsElement = false;

  /** Stores active triggers names */
  this.activeTriggersNames = new Set();

  this.runningProperties = new Set();

  this.observers = new Set();

  this.listeners = new Map();

  this.hasPointer = false;

  this.hovered = false;

  this.visible = true;

  this.immediate = false;

  /** @type {MainTriggers} */
  this.triggers = {
    animate: new Set(),
    viewport: new Set(),
    class: new Set(),
    timeout: new Set(),
  };

  /** SVG "translate-along-path" related values */
  this.svgPathReduceWidth = null;
  this.svgPathReduceHeight = null;
  this.svgPathMatrix = null;
  this.pathInitialized = false;
  this.path = {
    target: null,
    computed: {
      x: 0,
      y: 0,
    },
  };
}

export default Main.defaults;