import getFunction from "../get-function";
import G from "../globals";
import indexReplaceAll from "../index-replace-all";

const colorSlashToRgba = (input, color) => {
  let result = input;

  for (const index in G.depthSeparators) {
    const separator = G.depthSeparators[index];

    if (input.indexOf(separator) > 0) {
      result = indexReplaceAll(input, { [separator]: ",", "/,": "" });
      const fn = getFunction(result);
      const split = fn.parameters.split(",");
      const alpha = split[3];

      if (alpha) {
        const number = parseInt(alpha) / 100;
        split[3] = number;
      }

      result = `${color}(${split.join(",")})`;
      break;
    } else {
      result = input;
    }
  }

  return result;
};

export default colorSlashToRgba
