import setOptionPath from "../../../utils/set-option-path";
import warnings from "../../../utils/warnings";
import getOptions from "../../store/utils/get-options";

/**
 * Get the "length" of the `path` SVG element
 * @param {import("../../Main").MainClassProps} main
 * @param {import("../../../utils/process-values-in-resolutions").PropertyMapProps} property
 * @param {Object} parent
 */
const getLength = (main, property, parent, original) => {
  /** @type {Element} */
  let target;

  /** Extract `options` and the `rest` */
  const options = getOptions(original);

  /** DOM `path` element */
  const path = setOptionPath(options.options, main);

  // TODO: test with the "target" option
  target = path
    ? path
    : property.get("options").get("target")?.get("current").get("value");

  if (target) {
    if (target.length > 1) {
      warnings.multipleTargets(parent);
    } else {
      return target.getTotalLength();
    }
  } else {
    /** @type {SVGElement} */
    const element = main.element[0] || main.element;

    if (element.ownerSVGElement) {
      return element.getTotalLength();
    }
  }
};

export default getLength;
