import { Container } from "../Container/index";
import { Group } from "../Group/index";
import { Main } from "../Main/index";
import setWindowData from "./set-window-data";
import WINDOW from "./window";

/**
 * Sets up a MutationObserver to perform actions before mutations occur in the DOM.
 *
 * @function
 * @name beforeMutation
 *
 */

const beforeMutation = async () => {
  setWindowData();

  const inits = new Promise((resolve) => {
    /**
     * Callback function to be executed when a mutation is observed.
     * @function
     * @param {MutationRecord[]} entries - An array of mutation records.
     */
    const callback = async (entries) => {
      const mains = new Set();
      const groups = new Set();
      const containers = new Set();

      // TODO: simplify and move to external file
      for (const entry of entries) {
        for (const node of entry.addedNodes) {
          if (node.dataset?.tor) {
            // /** @type {DOMRect} */
            // const bounds = node.getBoundingClientRect();

            node.Torus = node.Torus || {};
            // node.Torus.bounds = bounds;
            mains.add(node);
          }

          if (node.dataset?.torGroup) {
            node.Torus = node.Torus || {};
            groups.add(node);
          }

          if (node.dataset?.torContainer === "") {
            node.Torus = node.Torus || {};
            containers.add(node);
          }
        }
      }

      if (groups.size) {
        await Group.init(groups);
      }
      if (mains.size) {
        await Main.init(mains);
      }
      if (containers.size) {
        await Container.init(containers);
      }

      resolve("All");
    };

    WINDOW.observers.mutation = new MutationObserver(callback);

    WINDOW.observers.mutation.observe(document, {
      childList: true,
      subtree: true,
      attributeFilter: ["data-tor", "data-tor-group", "data-tor-container"],
    });
  });

  const all = await inits;

  return Promise.resolve(all);
};

export default beforeMutation;
