import G from "../../../utils/globals";

/**
 * Check for "action"
 * @param {import("../../../utils/get-function").FunctionObject} data
 */
const checkAction = (data) => {
  return G.actionTypes.has(data.name);
};
export default checkAction;
