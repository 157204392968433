import linearInterpolation from "../../../../utils/linear-interpolation";
import WINDOW from "../../../../utils/window";

/**
 * Get interactive progress
 * @param {import("../../../../utils/process-values-in-resolutions").PropertyMapProps} data
 * @param {import("../../../store/utils/get-trigger").TriggerProps} trigger
 * @param {Number} percents
 */
const progressInteractive = (data, trigger, percents) => {
  const initialized = WINDOW[trigger.type].initialized;
  const start = data.get("progress");
  const end = percents;
  let amount;

  if (initialized) {
    amount = 155 / data.get("r_duration");
  } else {
    amount = 1;
  }

  return linearInterpolation(start, end, amount);
};

export default progressInteractive;
