import G from "../../utils/globals";

const checkIntersection = (element) => {
  const callback = (entries) => {
    for (const entry of entries) {
      /** @type {import("../Container").ContainerClass} */
      const container = entry.target.Torus?.Container;

      if (container) {
        container.on.intersection(entry.isIntersecting);
      }
    }
  };

  G.containers.observer = G.containers.observer || new IntersectionObserver(callback, { rootMargin: "50%" });
  G.containers.observer.observe(element);
};

export default checkIntersection;
