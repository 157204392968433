/**
 * Get the target - internal `this.element` or external one
 * @param {import("../../Main").MainClassProps} main
 * @param {import("../../store/utils/process-options").OptionsMapProps} options
 * @returns {Array<Element>}
 */
const getTarget = (main, options) => {
  const hasTarget = options.target?.current.value;

  return hasTarget ? hasTarget : [main.element];
}

export default getTarget;