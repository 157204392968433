import round from "../../../utils/round";
import getPointerPercents from "./percents/get-pointer-percents";
import getScrollPercents from "./percents/get-scroll-percents";

/**
 * Get interactive percents (scroll, pointer)
 * @param {import("../../../utils/process-values-in-resolutions").PropertyMapProps} data
 * @param {import("../../store/utils/get-trigger").TriggerProps} trigger
 */
const getInteractivePercents = (data, trigger, bounds) => {
  let percents;

  if (trigger.scroll) {
    percents = getScrollPercents(data, trigger, bounds);
  }

  if (trigger.pointer) {
    percents = getPointerPercents(data, trigger, bounds);
  }

  /** Reverse percents if `reversed: true` */
  if (data.get("r_reversed")) {
    percents = round(1 - percents);
  }

  data.set("percents", percents);

  return percents;
};

export default getInteractivePercents;
