import attributeActions from "./attribute-actions";
import classActions from "./class-actions";

/**
 * "Remove" action
 * @param {import("../../Main").MainClassProps} main
 * @param {import("../../../utils/process-values-in-resolutions").PropertyMapProps} property
 * @param {import("../../store/utils/process-parent").ParentProps} parent
 */
const actionRemove = (main, property, parent) => {
  switch (true) {
    case property.get("output") === "class":
      classActions(main, property, parent, "remove");
      break;

    case property.get("output") === "attribute":
      attributeActions(main, property, parent, "remove");
      break;
  }
};

export default actionRemove;
