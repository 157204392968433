const attributeRemove = (targets, name, values) => {
  for (const target of targets) {
    const original = target.getAttribute(name);

    if (values) {
      target.setAttribute(name, original.replace(values, ""));
    } else {
      target.removeAttribute(name);
    }
  }
}

export default attributeRemove;