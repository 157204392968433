import mergeObjects from "../../../utils/merge-objects";
import extractLastOptions from "./extract-last-options";
import processOptions from "./process-options";

/**
 * Parses options from a string and merges them with parent options if available.
 * @param {String} input - The input string containing options.
 * @param {import("./get-trigger").TriggerProps} trigger
 * @param {import("./process-options").OptionsMapProps} [parentOptions] - Optional. The parent options to merge with extracted options.
 */
const getOptions = (input, parentOptions) => {
  let options;
  let data;

  data = extractLastOptions(input, false);
  options = processOptions(data.options);

  if (parentOptions) {
    options = mergeObjects(parentOptions, options);
  }

  return {
    options,
    rest: data.rest,
  }
}

export default getOptions;