import limit from "../../../utils/limit";
import round from "../../../utils/round";
import WINDOW from "../../../utils/window";
import getStartTime from "./get-start-time";

/**
 * Get dynamic percents (hover, animate, class...)
 * @param {import("../../../utils/process-values-in-resolutions").PropertyMapProps} data
 * @param {import("../../store/utils/get-trigger").TriggerProps} trigger
 * @param {Boolean} spring
 * @param {import("../../store/utils/get-trigger").TriggerModeProps} mode
 */
const getDynamicPercents = (data, trigger, spring, mode) => {
  let percents = 0;
  let duration = data.get("r_duration");

  const startTime = getStartTime(trigger, data);
  const now = WINDOW.now;

  if (trigger.timeline.info.skip?.get("value") && trigger.iterating && !trigger.currentStep) {
    duration = 0;
  }

  const lastEnter = spring ? 1 : data.get("enterPercents");
  const lastLeave = spring ? 1 : 1 - data.get("leavePercents");

  if (data.get("r_alternate") && !data.get("fromTrigger")) {
    if (data.get("alternating")) {
      mode.leave = true;
      mode.enter = false;
    } else {
      mode.leave = false;
      mode.enter = true;
    }
  }

  data.set("mode", mode);

  if (mode.enter) {
    percents = (now - startTime) / (duration * lastLeave);
  }

  if (mode.leave) {
    percents = (now - startTime) / (duration * lastEnter);
  }

  if (trigger.modeChange && !trigger.alternating && !data.get("alternating")) {
    percents = 0;
  }

  if ((data.get("r_delay") || trigger.timelineDelay) && data.get("fromTrigger")) {
    const delayed = percents < 0;
    data.set("delayed", delayed);

    if (delayed) {
      return 0;
    }
  }

  percents = limit(percents);

  if (mode.enter) {
    const fragment = 1 - data.get("leavePercents");
    data.set("enterPercents", data.get("leavePercents") + fragment * percents);
    data.set("percents", round(data.get("enterPercents")));
  }

  if (mode.leave) {
    const fragment = 0 - data.get("enterPercents");
    data.set("leavePercents", data.get("enterPercents") + fragment * percents);
    data.set("percents", round(data.get("leavePercents")));
  }

  if (trigger.stepChange /*&& !trigger.alternating && !data.alternating*/) {
    if (mode.enter) {
      data.set("percents", 0);
    }
    if (mode.leave) {
      data.set("percents", 1);
    }
  }

  if (
    (mode.enter && data.get("percents") === 1) ||
    (mode.leave && data.get("percents") === 0)
  ) {
    data.set("fromTrigger", false);
  }

  return percents;
};

export default getDynamicPercents;
