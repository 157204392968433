import G from "../../utils/globals";
import optimizeString from "../../utils/optimize-string";
import replaceMatches from "./replace-matches";

/**
 * Find the variable declaration and replace it with corresponding value
 * @param {String} input Attribute that contains a variable string that should be replaced
 * @param {Boolean} global
 * @param {import("../Main").MainClassProps} main
 * @param {import("./process-variables").VariableStoreProps} data
 * @returns {String}
 */
const replaceVariable = (input, global, main, data) => {
  let replaced = null;

  if (global) {
    /** Replace global `$$` variables */
    replaced = replaceMatches(input, /\$\$(.*?)(?=\W|$)/g, G, main);
  } else {
    /** Replace local `$` variables */
    replaced = replaceMatches(input, /\$(.*?)(?=\W)/g, data, main);
  }

  return optimizeString(replaced);
};

export default replaceVariable;
