import WINDOW from "./window";

/**
 * Check if the element is in/out of viewport
 * @param {*} bounds
 * @param {import("../Main/store/utils/get-trigger").TriggerProps} trigger
 * @returns
 */
const checkInview = (bounds, trigger) => {
  if (!bounds) {
    return;
  }

  const height = bounds.height;

  const y = bounds["offset-top"] - WINDOW.scroll.y;
  const x = bounds["offset-left"] - WINDOW.scroll.x;

  const start = trigger.r_start.y[0];
  const end = trigger.r_end.y[0];

  const offsetEnd = WINDOW.height - end;
  const position = y + height - offsetEnd;
  const viewport = WINDOW.height + height - start - offsetEnd;

  return position <= viewport && position >= 0;
};

export default checkInview;
