const getValueType = (input) => {
  switch (true) {
    case !!Number(input) || input === 0:
      return "isNumber";

    case isNaN(input):
      return "isString";
  }
}

export default getValueType;