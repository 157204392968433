import G from "./globals";
import setWindowData from "./set-window-data";
import WINDOW from "./window";

/**
 * Check if the resolution string is in the current screen size
 * @param {import("./globals").ResolutionNamesProps} resolution
 * @returns
 */
const isInResolution = (resolution) => {
  if (!WINDOW.width) {
    setWindowData();
  }

  const width = G.resolutions[resolution];

  return width < WINDOW.width;
};

export default isInResolution;