import WINDOW from "../../../utils/window";
import getNullStartTime from "./get-null-start-time";
import setAlternateStartTime from "./set-alternate-start-time";

/**
 * Get the property startTime either from the trigger or from the alternate iteration
 * @param {import("../../store/utils/get-trigger").TriggerProps} trigger
 * @param {import("../../../utils/process-values-in-resolutions").PropertyMapProps} data
 * @returns {Number}
 */
const getStartTime = (trigger, data) => {
  let startTime = data.get("startTime");

  const dataDelay = data.get("r_delay");
  const triggerDelay = trigger.timelineDelay;
  const duration = data.get("r_duration");

  startTime = getNullStartTime(
    data,
    startTime,
    trigger,
    duration,
    dataDelay,
    triggerDelay
  );

  /**
   * Alternating after delay
   * Another "alternating" hack for delayed animation in alternate direction
   */
  if (data.get("r_alternate") && dataDelay) {
    if (
      WINDOW.now > startTime &&
      data.get("alternating") &&
      data.get("fromTrigger") &&
      data.get("mode").leave
    ) {
      startTime = setAlternateStartTime(data, startTime, trigger, dataDelay, true);
    }
  }

  if (trigger.timelineAlternate && triggerDelay) {
    if (
      WINDOW.now > startTime &&
      trigger.alternating &&
      data.get("fromTrigger") &&
      trigger.mode.leave
    ) {
      startTime = setAlternateStartTime(data, startTime, trigger, duration, true);
    }
  }

  return startTime;
};

export default getStartTime;
