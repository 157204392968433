/**
 * Show warning
 * @param {String} before
 * @param {String} content
 * @param {String} after
 */
const warning = (before, content, after, appendix) => {
  const bold = "font-weight: bold";
  const normal = "font-weight: normal";
  console.warn(
    `[Torus warn]: ${before} \`%c${content}%c\` ${after} %c${
      appendix ? `\`${appendix}\`` : ""
    }`,
    bold,
    normal,
    bold
  );
};

export default warning;
