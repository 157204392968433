import onDom from "../events/on-dom";
import onResize from "../events/on-resize";
import onVisibilityChange from "../events/on-visibility-change";

const addGlobalListeners = () => {
  /**
   * Resize
   */
  window.addEventListener("resize", onResize);

  /**
   * DOM Content Loaded
   */
  window.addEventListener("DOMContentLoaded", onDom);

  /**
   * Visibility change
   */
  document.addEventListener("visibilitychange", onVisibilityChange);
}

export default addGlobalListeners;