import WINDOW from "../../../../utils/window";

/**
 * Scroll continuous (parallax)
 * @param {import("../../../bounds/set-bounds").BoundsProps} bounds
 * @returns
 */
const scrollContinuous = (bounds) => {
  const height = WINDOW.height;
  const scroll = WINDOW.scroll;
  const offsetTop = bounds["offset-top"];

  // const positionY =
  //   height + scroll.y - bounds["offset-top"] - O.start - bounds.height / 2;
  // const y = positionY / (height - O.end - O.start);
  // const positionY = height + scroll.y - offsetTop - start - bounds.height / 2;
  // const y = positionY / (height - end - start);

  const positionY = height + scroll.y - offsetTop - bounds.height / 2 - height / 2;
  const y = positionY / (height - (height - bounds.height) / 2);

  return {
    y,
  };
};
export default scrollContinuous;
