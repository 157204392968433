/**
 * Returns true if the input parameter ends with 's' or 'ms' and represents a valid numeric value followed by the unit.
 * @param {String} input - The input string to be check.
 * @returns {Boolean} Returns true if the input ends with 's' or 'ms' and represents a valid numeric value followed by the unit, otherwise returns false.
 */
const getParameterUnit = (input) => {
  if (input.endsWith("s")) {
    return /^\d+(s|ms)$/.exec(input) ? true : false;
  }

  return false;
};

export default getParameterUnit;
