import WINDOW from "../../utils/window";
import Main from "../Main";
import getFinalString from "./utils/get-final-string";

/**
 * @this import("../Main").MainClassProps
 */
Main.prototype.setCSS = function () {
  const final = this.final;

  for (const [name, multi] of final) {
    let css = "";

    const initials = this.appliedInitials;
    const shift = this.shift;
    const string = getFinalString(multi, initials, name, this.arrayPool);
    const priority = string.priority ? "important" : "";

    css = string.result.join(", ");

    if (css) {
      if (string.isTransform) {
        css = `${css} ${shift}`;
      }

      // console.log(name, css);
      this.style.setProperty(name, css, priority);

      // TODO: visible is not working
      if (this.visible || this.immediate) {
        this.style.setProperty(name, css, priority);
      } else {
        if (WINDOW.run) {
          this.style.setProperty(name, css, priority);
        }
      }
    }
  }

  /** Release all maps used in this computation back to the pool */
  this.releaseAll(this.final);
};

export default Main.setCSS;
