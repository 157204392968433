import Container from "./Container";

/**
 * @this {import("./Container").ContainerClass}
 */
Container.prototype.events = function () {
  this.on = {
    intersection: (isIntersecting) => {
      for (const target of this.targets) {
        /** @type {import("../Main/Main").MainClassProps} */
        const main = target.Torus?.Main;

        if (main) {
          main.visible = isIntersecting;
        }
      }
    },
  };
};

export default Container.events;
