import dispatchClassEvent from "./utils/dispatch-class-event";

/**
 * Check for added/removed classes
 * @param {import("../Main").MainClassProps} main
 * @param {Array} added
 * @param {Array} removed
 */
const onClassChange = (main, added, removed) => {
  if (!main)   {
    return;
  }

  for (const single of added) {
    dispatchClassEvent(main, single, "classadded");
  }

  if (!added.length) {
    for (const single of removed) {
      dispatchClassEvent(main, single, "classremoved");
    }
  }
}

export default onClassChange;