import getTriggerEvents from "./get-trigger-events";
import getTriggerType from "./get-trigger-type";

/**
 * @typedef {Object} TriggerInfoProps
 * @property {String} type
 * @property {Boolean} interactive
 * @property {Object} events
 * @property {Boolean} [pointer]
 * @property {Boolean} [mouse]
 * @property {Boolean} [scroll]
 * @property {Boolean} [hover]
 * @property {Boolean} [viewport]
 * @property {Boolean} [active]
 * @property {Boolean} [initial]
 * @property {Boolean} [class]
 * @property {Boolean} [timeout]
 * @property {String} [reference]
 */

/**
 * Return the `type` and `event` of the trigger
 * @param {String} input trigger string
 * @returns {TriggerInfoProps}
 */
const getTriggerInfo = (input) => {
  const type = getTriggerType(input);

  return {
    type,
    interactive: type === "scroll" || type === "pointer",
    [type]: true,
    events: getTriggerEvents(type, input),
  }
}

export default getTriggerInfo;