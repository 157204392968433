import WINDOW from "./window";
import round from "./round";

const getWindowScroll = () => {
  /** @type {import("./window").ScrollEventType} */
  const scroll = WINDOW.scroll;

  scroll.x = round(window.scrollX);
  scroll.y = round(window.scrollY);

  if (scroll.direction.x || scroll.direction.y) {
    scroll.initialized = true;
  }
};

export default getWindowScroll;
