/**
 * ------------------------------------------------------------------------
 * Get replaced matches
 * @description Find all counts definitions by `/.../` in the matches and replaced
 * them with computed counting
 * @example `hover:tx(/+2/rem)` = `hover:tx(0rem)`, `hover:tx(2rem)`, `hover:tx(4rem)`...
 * ------------------------------------------------------------------------
 */

import indexReplaceAll from "../../utils/index-replace-all";
import round from "../../utils/round";
import checkRandomDefinition from "./check-random-definition";
import getOperator from "./get-operator";

const getReplacedMatches = function (attributes, index) {
  const replaced = [];

  /**
   * Loop trough array of attributes
   * @example ['/+.3/', '/random(-3,5)/', '/+1/']
   */

  for (const attribute of attributes) {
    let start = 0;
    let counting;
    let operator;
    let finalCounting;

    const clean = indexReplaceAll(attribute, { "/": "" });
    const isRandom = checkRandomDefinition(clean);

    if (!isNaN(isRandom)) {
      replaced.push(isRandom);
      continue;
    }

    const split = clean.split(",");

    /** Has start and end value */
    if (split[1]) {
      start = +split[0];
      counting = split[1];
    } else {
      counting = split[0];
    }

    operator = getOperator(counting);
    counting = +counting.replace(operator.sign, "");

    if (operator.type === "add") {
      finalCounting = round(start + counting * index);
    }
    if (operator.type === "deduct") {
      finalCounting = round(start - counting * index);
    }

    replaced.push(finalCounting);
  }

  return replaced;
};

export default getReplacedMatches;
