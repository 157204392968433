import hexToRgba from "./colors/hex-to-rgba";
import parseHsla from "./colors/parse-hsla";
import colorSlashToRgba from "./colors/color-slash-to-rgba";

/**
 * Check input string for type (color, etc..)
 * @param {String} input
 * @param {{ name: String } | null} data
 */
const checkForType = (input, data) => {
  switch (true) {
    /** Check fox HEX color */
    case /^#([a-zA-Z0-9])+$/.test(input) &&
      data?.name !== "path" &&
      data?.name !== "target": {
      return hexToRgba(input);
    }

    case input.startsWith("rgb") && input.endsWith(")"):
      if (input.indexOf("/") > 0) {
        return colorSlashToRgba(input, "rgba");
      } else {
        return input;
      }

    case input.startsWith("hsl") && input.endsWith(")"):
      return parseHsla(input);

    default:
      return input;
  }
};

export default checkForType;
