function deepObjectToMap(obj) {
  const map = new Map();
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (
        typeof obj[key] === "object" &&
        obj[key] !== null &&
        !Array.isArray(obj[key])
      ) {
        map.set(key, deepObjectToMap(obj[key]));
      } else {
        map.set(key, obj[key]);
      }
    }
  }
  return map;
}

export default deepObjectToMap;
