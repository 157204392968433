import G from "../../utils/globals";
import Main from "../Main";

/**
 *
 * @param {import("./utils/get-trigger").TriggerProps} trigger
 */
Main.prototype.addToGlobal = function (trigger) {
  switch (true) {
    case trigger.viewport:
      G.triggers.viewport.set(this.element, this);
      this.triggers.viewport.add(trigger);
      break;

    case trigger.animate: {
      G.triggers.animate.set(this.element, this);
      this.triggers.animate.add(trigger);
      break;
    }

    case trigger.timeout: {
      G.triggers.timeout.set(this.element, this);
      this.triggers.timeout.add(trigger);
      break;
    }

    case trigger.active:
      G.triggers.active.set(this.element, this);
      break;

    case trigger.scroll:
      G.triggers.scroll.set(this.element, this);
      break;

    case trigger.pointer: {
      this.hasPointer = true;
      G.triggers.pointer.set(this.element, this);
      break;
    }

    case trigger.reference: {
      const reference = G.references.regular;
      reference[trigger.trigger] = reference[trigger.trigger] || new Set();
      reference[trigger.trigger].add(this);
      break;
    }
  }
};

export default Main.addToGlobal;
