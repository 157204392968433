/**
 * Switch the property's `alternating` status
 * @param {import("../../../utils/process-values-in-resolutions").PropertyMapProps} data
 * @param {import("../../store/utils/get-trigger").TriggerProps} trigger
 */
const getAlternate = (data, trigger) => {
  /** Check if property has `alternate` option */
  if (data.get("r_alternate")) {
    /**
     * Get the mode
     * @type {import("../../store/utils/get-trigger").TriggerModeProps}
     */
    const mode = data.get("mode");

    /** Check if animation has finished in the "enter" mode (is at the end) */
    if (mode.enter && data.get("percents") === 1) {
      /** Revert the property to be used again in `set-alternate-start-time.js` */
      data.set("firstAlternate", false);

      /** Check if it's the last property iteration */
      const last = data.get("currentIteration") === data.get("r_iterations") - 1;

      /** Only set the alternating to `true`, if it's not the last iteration or timeline has `alternate` direction */
      if (!last || trigger.r_alternate) {
        data.set("alternating", true);
      }
    }

    /** Check if animation has finished in the "leave" mode (is at the start) */
    if (mode.leave && data.get("percents") === 0) {
      /** Revert the property to be used again in `set-alternate-start-time.js` */
      data.set("firstAlternate", false);

      /** Set the alternating to `false` when animation finishes the leave mode */
      data.set("alternating", false);
    }
  }
};

export default getAlternate;
