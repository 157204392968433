import checkChildCharacter from "./check-child-character";
import setOptionMethod from "./set-option-method";
import setOptionOffset from "./set-option-offset";
import getFunction from "./get-function";
import getSpecificationDefault from "./get-specification-default";
import getValueAndUnit from "./get-value-and-unit";
import G from "./globals";
import setOptionThreshold from "./set-option-threshold";
import setOptionReferences from "./set-option-references";
import setInitialsCSS from "../Main/store/utils/set-initials-css";

/**
 * @typedef {Object} PropertyValueData
 * @property {PropertyMapProps} all
 * @property {PropertyMapProps} current
 * @property {PropertyMapProps} [xs]
 * @property {PropertyMapProps} [sm]
 * @property {PropertyMapProps} [md]
 * @property {PropertyMapProps} [lg]
 * @property {PropertyMapProps} [xl]
 * @property {PropertyMapProps} [xxl]
 */

/**
 * @typedef {Object} LiveObject
 * @property {Number} progress
 * @property {Boolean} entering
 * @property {Boolean} leaving
 * @property {String} status
 */

/**
 * @typedef {Map<keyof LiveObject, LiveObject[keyof LiveObject]>} LiveProps
 * @description This map holds various state properties with their initial values.
 */

/**
 * @typedef {Map<string, any>} PropertyMapProps
 * @property {Number | String | PropertyValueData} value
 * @property {String | null} unit
 * @property {String} fn - Function name
 * @property {import("../Main/store/utils/process-options").OptionsMapProps} options - Options
 * @property {Boolean} isFn - It's a function
 * @property {Boolean} hasChild - If function, than has a child
 * @property {Boolean} [isString] - It's a string
 * @property {Boolean} [isNumber] - It's a number
 * @property {Boolean} [isAction] - Is action
 * @property {Boolean} [isCalc] - Is "calc()"
 * @property {Boolean} [isInherit] - Inherits a value
 * @property {Boolean} [isInitial] - Property is from initial trigger
 * @property {Boolean} [priority] - Has "!" priority
 * @property {Boolean} [composition] - Has (+)/(-) composition
 * @property {Boolean} [hasBeginReference] - Has `begin`
 * @property {Boolean} [hasFinishReference] - Has `finish`
 * @property {Boolean} [isNamed]
 * @property {String} [output]
 * @property {Number} [enterPercents]
 * @property {Number} [leavePercents]
 * @property {Number} [lastLeaveProgress]
 * @property {Number} [lastEnterProgress]
 * @property {Number} [percents]
 * @property {Number} [progress]
 * @property {Number} [lastPercents]
 * @property {Number} [lastProgress]
 * @property {Number} [statusProgress]
 * @property {{ started: Boolean, running: Boolean, finished: Boolean }} status
 * @property {Boolean} [r_spring] - Spring easing
 * @property {Boolean} [r_duration] - Duration
 * @property {Boolean} [r_pause] - Pause
 * @property {Boolean} [r_delay] - Delay
 * @property {Boolean} [r_easing] - Easing
 * @property {Boolean} [r_iterations] - Iterations
 * @property {Boolean} [r_direction] - Direction
 * @property {Boolean} [r_reversed]
 * @property {Boolean} [r_alternate]
 * @property {Boolean} [r_method]
 * @property {Number} [r_methodInit]
 * @property {Number} [r_methodValue]
 * @property {Number} [r_threshold]
 * @property {Number} [r_thresholdHover]
 * @property {Number} [r_order]
 * @property {Boolean} [delayed] - Property is currently delayed
 * @property {Boolean} [paused] - Property is currently paused
 * @property {Boolean} [alternating]
 * @property {Number} [currentIteration]
 * @property {Number} [stepNumber]
 * @property {Boolean} [r_offsetStartX]
 * @property {Boolean} [r_offsetStartY]
 * @property {Boolean} [r_offsetEndX]
 * @property {Boolean} [r_offsetEndY]
 * @property {Boolean} [r_infinite]
 * @property {String} [r_path]
 * @property {Object} [live]
 * @property {Number} [live.progress]
 * @property {Boolean} [live.entering]
 * @property {Boolean} [live.leaving]
 * @property {Boolean} [firstAlternate]
 * @property {Number} [startTime]
 * @property {Number} [multiIndex]
 */

/**
 * Loop trough object of resolutions and process the string into value and unit
 * @param {Object<string>} resolutions
 * @param {Object} data
 * @param {import("../Main/store/utils/process-options").OptionsMapProps} options
 * @param {import("../Main/store/utils/get-trigger").TriggerProps} trigger
 * @returns {PropertyMapProps}
 */
const processValuesInResolutions = (resolutions, data, options) => {
  let final = new Map();

  for (const index in resolutions) {
    if (!resolutions[index]) {
      continue;
    }

    /** @type {PropertyMapProps} */
    let result;

    const content = resolutions[index];
    const isFn = getFunction(content);

    if (isFn) {
      const valueUnit = getValueAndUnit(isFn.parameters, isFn);
      final.set(index, new Map(Object.entries(valueUnit)));

      result = final.get(index);
      result.set("fn", isFn.name);
      result.set("isFn", true);
      result.set("isAction", G.actionTypes.has(isFn.name));
      result.set("isNamed", data?.isNamed);
    } else {
      const valueUnit = getValueAndUnit(content, null);
      final.set(index, new Map(Object.entries(valueUnit)));

      result = final.get(index);
      result.set("isFn", false);
      result.set("fn", null);
    }

    if (checkChildCharacter(result.get("value")) || isFn?.isFn) {
      result.set("hasChild", true);
    } else {
      result.set("hasChild", false);
    }

    /** Check for target element */
    if (data?.name === "target") {
      result.set("value", document.querySelectorAll(result.get("value")));
    }

    // TODO: separate and simplify
    /** If options are defined, it means that it's a property with its options */
    if (options) {
      result.set("status", "");
      result.set("options", options);
      result.set("lastEnterProgress", 0);
      result.set("lastLeaveProgress", 0);

      result.set("percents", 0);
      result.set("progress", 0);
      result.set("lastPercents", 0);
      result.set("enterPercents", 0);
      result.set("leavePercents", 0);
      result.set("currentIteration", 0);
      result.set("overallIterations", 0);
      result.set("lastProgress", null);
      result.set("statusProgress", null);
      result.set("startTime", null);
      result.set("mode", {
        enter: true,
        leave: false,
      });

      result.set("lastEntering", false);
      result.set("lastLeaving", false);

      result.set("started", false);
      result.set("finished", false);
      result.set("paused", false);

      if (data.trigger) {
        result.set("activeName", `${data.trigger.name}/${result.get("output")}`);
        result.set("isInitial", data.trigger.initial);
      }

      result.set(
        "live",
        new Map([
          ["progress", 0],
          ["entering", true],
          ["leaving", false],
          ["status", ""],
        ])
      );

      result.set("hasBeginReference", options.get("begin") ? true : false);
      result.set("hasFinishReference", options.get("finish") ? true : false);
      result.set("initialized", false);

      result.set("last", 0);
      result.set("add", 0.01);
      result.set("interpolation", 0);

      result.set("alternating", false);
      result.set("priority", data ? data.priority : false);

      result.set("stepNumber", data.stepNumber || 0);

      result.set("r_path", !!options.get("path"));
      result.set("r_order", +result.get("r_order"));

      setOptionReferences(options, result);
      setOptionMethod(options, result);
      setOptionThreshold(options, result, data);

      if (result.get("r_path")) {
        data.trigger.this.isBoundsElement = true;
      }

      if (result.get("r_direction") === "reversed") {
        setInitialsCSS(data.trigger.this);
      }
    }
  }

  return final;
};

export default processValuesInResolutions;
