import getClusterContent from "../../../utils/get-cluster-content";
import setStepDuration from "./set-step-duration";
import extractLastOptions from "./extract-last-options";
import processCluster from "./process-cluster";
import processOptions from "./process-options";

/**
 * @typedef {Object} TimelineInfoType
 * @property {Number} stepCount
 * @property {Number} currentStep
 * @property {Object} iterations
 * @property {Number} currentIteration
 * @property {Object} delay
 * @property {Object} pause
 * @property {Boolean} active
 * @property {Boolean} started
 * @property {Boolean} finished
 */

/**
 * @typedef {Object} TimelineProps
 * @property {TimelineInfoType} info
 * @property {import("./process-options").OptionsMapProps} options
 * @property {Array<Object>} steps
 * @property {Object} [data]
 */

/**
 * Process timeline by extracting each timeline item
 * @param {String} input
 * @param {import("./get-trigger").TriggerProps} trigger
 */
const processTimeline = (input, trigger) => {
  /** @type {Array<String>|undefined} */
  let items = [];

  /** Step index */
  let index = 0;

  /** Step offset if offset-based timeline */
  let offset = 0;

  let lastOffset = 0;

  /** All steps store */
  const steps = [];

  /** Extract the timeline items with options from cluster */
  const timeline = getClusterContent(input);

  /** Extract the last options and the rest of the string */
  const data = extractLastOptions(timeline, true);

  /** Common options for every step */
  const options = processOptions(data?.options);

  /**
   * Split the timeline to separate steps
   * @example `[tx(3px)█s(2)]░[o(.2)]`
   */
  if (input.includes("%_[")) {
    items = data?.rest.split(/,(?=\d+%_)/);
  } else {
    items = data?.rest.split("░");
  }

  if (!items) {
    return;
  }

  /** Timeline duration */
  // TODO: replace with r_ references
  const duration = options.get("duration").get("current").get("value");

  /** Steps count */
  const count = items.length;

  /** Process the parents in each cluster (step) */
  for (const originalItem of items) {
    let item = originalItem;

    /** Set duration for each step based on the timeline duration */
    const offsets = setStepDuration(
      item,
      options,
      offset,
      lastOffset,
      duration,
      count
    );

    item = offsets.item;
    lastOffset = offsets.lastOffset;

    const cluster = processCluster(item, trigger, options, index, true);
    const computed = cluster.steps[0];

    steps.push({
      offset: lastOffset,
      index,
      active: !index ? true : false,
      processed: false,
      info: computed.info,
      options: computed.options,
      parents: computed.parents,
    });

    ++index;
  }

  /** Timeline info */
  const info = {
    active: false,
    stepCount: index,
    currentStep: 0,
    currentIteration: 0,
    delay: options.get("delay").get("current"),
    pause: options.get("pause").get("current"),
    iterations: options.get("iterations").get("current"),
    skip: options.get("skip-first").get("current"),
  };

  const delay = info.delay.get("value");
  const pause = info.pause.get("pause");
  const alternate =
    options.get("direction").get("current").get("value") === "alternate";

  trigger.timelineDelay = delay ? delay : null;
  trigger.timelinePause = pause ? pause : null;
  trigger.timelineAlternate = alternate ? alternate : null;

  return {
    info,
    options,
    steps,
  };
};

export default processTimeline;
