import checkChildCharacter from "./check-child-character";

/**
 * Parse number
 * @param {String} input
 * @returns
 */
const parseNumber = (input) => {
  switch (true) {
    case input.indexOf(".") >= 0:
      if (input.startsWith(".") || /^(.?)\d\.+/.test(input)) {
        return parseFloat(input);
      } else {
        return NaN;
      }

    case input.indexOf(",") >= 0:
      return NaN;

    default:
      if (checkChildCharacter(input)) {
        return NaN
      } else {
        return parseInt(input);
      }
  }
};

export default parseNumber;
