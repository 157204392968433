import staticDestroy from "../Main/static/static-destroy";
import staticInit from "../Main/static/static-init";
import getIterableElements from "../utils/get-iterable-elements";
import G from "../utils/globals";

class Group {
  constructor(element) {
    this.element = element;
  }

  /**
   * Destroy method
   * @param {String | Array | Set | NodeList} elements
   */
  static destroy(elements) {
    staticDestroy("Group", "[data-tor-group]", elements);
  }

  /**
   * ------------------------------------------------------------------------
   * Initial
   * ------------------------------------------------------------------------
   */

  static async init(elements) {
    return await staticInit("Group", Group, elements, null, "tor-group");
  }
}

export default Group;
