import getFunction from "../../utils/get-function";
import G from "../../utils/globals";

/**
 * Get variables from string and attributes that contains a variable symbol ($)
 * @param {String} input
 * @return
 */
/**
 * Parses the input string to extract variables and dataset.
 * Variables starting with '$$' are considered global and stored in G.variables object.
 * Variables starting with '$' are stored in local variables object.
 * All other attributes are considered part of the dataset.
 * @param {string} input - The input string containing attributes separated by spaces.
 */
const getVariables = (input) => {
  const dataset = [];
  const variables = {};
  const attributes = input.split(" ");

  for (const attribute of attributes) {
    switch (true) {
      /**
       * Global variable
       */
      case attribute.startsWith("$$"): {
        const fn = getFunction(attribute);

        /** Variable definition found. @example `$$scale(0.1)` */
        if (fn) {
          G.variables[fn.name] = fn.parameters;
        } else {
          dataset.push(attribute);
        }

        break;
      }

      /**
       * Local variable
       */
      case attribute.startsWith("$"): {
        const fn = getFunction(attribute);

        if (fn) {
          variables[fn.name] = fn.parameters;
        } else {
          dataset.push(attribute);
        }

        break;
      }

      /**
       * No variable
       */
      default:
        dataset.push(attribute);
        break;
    }
  }

  return {
    variables,
    dataset,
  };
}

export default getVariables;