/**
 * Retrieves the event(s) associated with a given trigger type.
 * @param {string} trigger - The trigger type for which to retrieve the event(s).
 * @returns {Array<string>} An array containing the event(s) associated with the given trigger type.
 */
const getTriggerEvents = (trigger, type) => {
  switch (true) {
    case trigger === "hover":
      return ["pointerenter", "pointerleave"];

    case trigger === "over":
      return ["pointerover"];

    case trigger === "animate":
      return ["animateenter", "animateleave"];

    case trigger === "click":
      return ["click"];

    case trigger === "active":
      return ["pointerdown"];

    case trigger === "focus":
      return ["focus", "blur"];

    case trigger === "focus-within":
      return ["focusin", "focusout"];

    case trigger === "select":
      return ["change"];

    case trigger === "change":
      return ["change"];

    case trigger === "check":
      return ["check", "uncheck", "change"];

    case trigger === "scroll":
      return ["scroll"];

    case trigger === "mouse" || trigger === "pointer":
      return ["pointermove"];

    case trigger === "viewport":
      return ["viewportenter", "viewportleave", "scroll"];

    case trigger === "timeout":
      return ["timeoutenter", "timeoutleave"];

    case trigger === "class":
      return ["classadded", "classremoved"];

    case trigger === "reference":
      return [type];

    // case trigger.startsWith("."):
    //   return ["classadded", "classremoved"];

    case trigger.startsWith("@"):
      return ["referenceenter", "referenceleave"];

    default:
      return [];
  }
};

export default getTriggerEvents;
