import getWindowScroll from "./get-window-scroll";
import WINDOW from "./window";

/**
 * Get pointer coordinates
 * @param {PointerEvent} e
 */
const getWindowPointer = (e) => {
  /** @type {import("./window").PointerEventType} */
  const pointer = WINDOW.pointer;

  if (e) {
    pointer.x = e.clientX;
    pointer.y = e.clientY;
    pointer.initialized = true;
  } else {
    pointer.x = 0;
    pointer.y = 0;
  }

};

export default getWindowPointer;
