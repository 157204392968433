import checkForType from "./check-for-type";
import G from "./globals";
import indexReplaceAll from "./index-replace-all";
import normalizeWhitespace from "./normalize-whitespace";

/**
 * Valid separator types.
 * @typedef {Record<number, string>} SeparatorsTypeProps
 */

/**
 * Replaces spaces within parentheses based on nesting level.
 * @param {String} input - The input string containing parentheses and spaces.
 * @returns {String} A modified string with spaces within parentheses replaced.
 */
const optimizeString = (input) => {
  if (!input) {
    return input;
  }

  const replacesPrev = G.replace.prev;
  const replacesNext = G.replace.next;

  /** @type {String} */
  let letters = "";
  /** @type {0 | 1 | 2 | 3 | 4} */
  let level = 0;
  let cluster = 0;
  let options = 0;
  let variable = false;
  let comment = false;
  let i = 0;

  let lastLetter = "";
  let nextLetter = "";

  /** @type {SeparatorsTypeProps} */
  const separators = {
    0: " ",
    1: "▄",
    2: "▃",
    3: "▂",
    4: "▁",
  };

  if (input.indexOf("::") > 0) {
    input = indexReplaceAll(input, G.replaceResolutions);
    input = input.replace(/\[[^\[\]]*✦[^\[\]]*\]/g, (item) => {
      return indexReplaceAll(item, { "[": " {", "]": "}" });
    });
  }

  if (input.indexOf("#") > 0) {
    input = input.replace(/#(?:[0-9a-fA-F]{3}){1,2}\b/g, (e) =>
      checkForType(e, null)
    );
  }

  if (input.indexOf("/*") > 0) {
    input = input.replace(/\/\*(.*?)\*\//g, "");
  }

  // input = normalizeWhitespace(input);
  input = input.replace(/\s+/g, " ");
  const inputArray = input.split("");

  for (let item of inputArray) {
    let letter = item;
    let add = true;

    nextLetter = inputArray[i + 1];

    switch (true) {
      case letter === "(":
        ++level;
        break;

      case letter === ")":
        --level;
        variable = false;
        break;

      case letter === "[":
        ++cluster;
        break;

      case letter === "]":
        --cluster;
        break;

      case letter === "<":
        ++options;
        break;

      case letter === ">":
        --options;
        break;

      case letter === "$":
        variable = true;
        break;
    }

    if (letter === "<" && nextLetter === "!") {
      comment = true;
    }

    if (comment) {
      add = false;
    }

    if (letter === ">" && lastLetter === "-") {
      comment = false;
    }

    if (letter === " ") {
      if (replacesPrev.has(lastLetter)) {
        add = false;
      }

      if (replacesNext.has(nextLetter)) {
        add = false;
      }

      if (lastLetter === " ") {
        add = false;
      }

      if (level && inputArray[i + 1] === "✦") {
        add = false;
      }

      if (add) {
        letter = separators[level];

        /**
         * @example `[[s(2)█o(.2)],[top(1rem)█padding(3)]]`
         */

        if (cluster && !level) {
          letter = "█";
        }
      }
    }

    /**
     * Replace commas "," in nested CSS functions such as `rgba()`
     */

    if (letter === ",") {
      if (level === 3) {
        letter = "▂";
      }
      if (level === 4) {
        letter = "▁";
      }
    }

    if (letter === ":") {
      if (level === 1 && !cluster && !options && !variable) {
        letter = "˸";
      }

      if (nextLetter === ":") {
        add = false;
      }

      if (lastLetter === ":") {
        letter = "▸";
      }

      if (lastLetter === "%" && !level) {
        letter = "_";
      }
    }

    if (letter === "▂" && lastLetter === "▂") {
      add = false;
    }

    /**
     * Replace all colons `:` with `ː`
     */
    if (options) {
      if (letter === ":") {
        letter = "ː";
      }

      if (level > 1) {
        if (letter === "(") {
          letter = "(∫";
        }

        if (letter === ",") {
          letter = "⊗";
        }
      }

      /** Space in options */
      if (letter === "▄") {
        letter = "▪";
      }
      if (letter === "▃") {
        add = false;
      }
    }

    if (!level) {
      if (letter === "✦") {
        letter = "";
      }
      if (letter === "▹") {
        letter = "▸";
      }
    }

    if (add) {
      letters += letter;
    }

    lastLetter = letter;
    ++i;
  }
  // console.log(letters);
  return letters;

  // TODO: fix on responsive options `duration: [1s xl::2s], pause: 1s`
  // return letters.indexReplaceAll({ "],": "]░" });
};

export default optimizeString;
