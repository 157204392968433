import Main from "../Main";
import applyListener from "./apply-listener";

/**
 * Add event listener
 * @param {import("../store/utils/get-trigger").TriggerProps}
 */
const addListeners = function (trigger, item, fromTarget) {
  if (trigger.hasTarget && !fromTarget) {
    return;
  }

  const triggers = [
    "animate",
    "hover",
    "click",
    "active",
    "focus",
    "select",
    "check",
    "scroll",
    "class",
    "mouse",
    "pointer",
    "viewport",
    "focus-within",
    "change",
    "reference",
    "timeout",
  ];

  for (const name of triggers) {
    if (trigger[name]) {
      applyListener(trigger, item);
      break;
    }
  }
};

export default addListeners;
