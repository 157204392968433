import WINDOW from "../../../utils/window";

const getPause = (pause, startTime, duration, data) => {
  const passedTime = WINDOW.now - startTime - duration;

  if (passedTime < pause) {
    if (data) {
      data.set("paused", true);
    }
    return true;
  }

  if (data) {
    data.set("paused", false);
  }
  return false;
};

export default getPause;
