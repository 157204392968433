import Main from "../Main";
import getStatus from "./utils/get-status";

/**
 * Process parents in the given step
 * @param {import("../store/utils/get-steps").Step} step
 * @param {import("../store/utils/get-trigger").TriggerProps} trigger
 * @param {import("../store/utils/get-trigger").TriggerModeProps} mode
 */
Main.prototype.computeParents = function (step, trigger, mode, stepStatus) {
  /** Active parent properties */
  const active = new Set();

  /**
   * Get parents object in the step
   * @example `hover:[[o(.2) tx(2rem)]]` = `{opacity: {…}, transform: {…}}`
   */
  const parents = step.parents;

  /** Loop each parent (CSS property) name in parents */
  for (const name in parents) {
    /** @type {import("../store/utils/process-parent").ParentProps} */
    const parent = parents[name];

    /**
     * Compute "multi-definition" divided by "|" pipe
     * Returns status for each child property (not as a whole step)
     * @example `bg(#fff | linear-gradient(...))`
     */
    const parentResult = this.computeMulti(parent, name, trigger, mode);

    /**
     * If "action", result status to "finished" immediately and don't wait
     * until the percents are computed
     */
    // TODO: fast toggle of classes does not work. Change to modeChange
    if (parent.action && (trigger.modeChange || parentResult.started)) {
      this.setAction(name, parent);
    }

    /**
     * Check if at least one of the properties in the parent starts
     * If yes, add a parent property name (opacity, transform...) to the set
     * If not, remove the name from the set.
     * This way we can distinguish if all parents started or finished
     */
    if (trigger.activeProperties.size) {
      active.add(name);
    } else {
      active.delete(name);
    }
  }

  return getStatus(active, step, stepStatus);
};

export default Main.computeParents;
