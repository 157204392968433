import Main from "../Main";

Main.prototype.computeFlat = function () {
  const flat = this.flat;
  const final = this.final;
  const mapPool = this.mapPool;
  const arrayPool = this.arrayPool;

  for (const [name, multi] of flat) {
    /** @type {import("../store/utils/create-computed-store").ComputedSingletProps} */
    let item;

    /** Array of "final" CSS strings */
    const finalName = mapPool.acquire();
    final.set(name, finalName);

    for (const [i, parents] of multi) {
      const finalI = mapPool.acquire();
      finalName.set(i, finalI);

      for (const [index, resolutions] of parents) {
        const finalIndex = mapPool.acquire();
        finalI.set(index, finalIndex);
        finalIndex.set("result", arrayPool.acquire());
        finalIndex.set("priority", false);

        const parent = resolutions.get("current");

        /** Loop each "flat" item */
        for (item of parent) {
          /**
           * Has multiple values. Can be a CSS function
           */
          if (item.has("values")) {
            this.computeValues(item, finalIndex);
          } else {
            /**
             * Only single (output) value (with possible multiple triggers)
             */
            this.computeSingle(item, index, finalIndex, i);
          }
        }
      }
    }
  }
};

export default Main.computeFlat;
