import Main from "../Main";
import G from "../../utils/globals";
import setInitials from "../initials/set-initials";
import getAttributes from "./utils/get-attributes";
import getSteps from "./utils/get-steps";
import getTrigger from "./utils/get-trigger";
import addListeners from "../listeners/add-listeners";
import addTargets from "./utils/add-targets";
import createComputedStore from "./utils/create-computed-store";
import getShift from "./utils/get-shift";
import setWindowData from "../../utils/set-window-data";
import indexReplaceAll from "../../utils/index-replace-all";

/**
 * @typedef {Array<Object>} StoreType
 */

/**
 * Create store
 * @type {import("../Main").MainClassProps}
 * @param {Object} options
 * @this {import("../Main").MainClassProps}
 */

Main.prototype.createStore = function () {
  const dataset = this.dataset;
  const attributes = getAttributes(dataset);
  const store = {};

  /**
   *
   */
  for (const items of attributes) {
    /**
     * Trigger name + value
     */
    for (const attribute in items) {
      /**
       * Get the trigger data
       * @type {import("./utils/get-trigger").TriggerProps}
       */
      let trigger;

      /**
       * Attribute value
       * @type {string}
       */
      let value;
      let name;
      let computed;

      value = items[attribute];

      if (value.indexOf("],[") > 0) {
        value = indexReplaceAll(value, { "],[": "]░[" });
      }

      trigger = getTrigger(attribute, value, this.element);
      trigger.rest = value;
      trigger.isInview = null;
      trigger.this = this;

      if (trigger.hasResolution) {
        G.references.triggers.add(trigger);
      }

      if (trigger.shift) {
        getShift(trigger.rest, this);
        continue;
      }

      name = trigger.name;

      store[name] = trigger;

      /**
       * If trigger has a target in parameters, add it into global object
       * together with this current element as an affected element
       */
      addTargets(trigger, this);

      /**
       * Add to global object
       */
      this.addToGlobal(trigger);

      /**
       * Set bounds
       */

      computed = getSteps(value, trigger, this);
      store[name].timeline = computed;
      setInitials(this, trigger, computed.steps);
      createComputedStore(this, trigger, store[name].timeline);

      if (trigger.initial) {
        G.triggers.initial.add(this);
      } else {
        addListeners(trigger, this);
        store[name].computed = true;

        /** Check if the trigger timeline is "alternate" direction */
        trigger.r_alternate =
          trigger.timeline.options
            ?.get("direction")
            .get("current")
            .get("value") === "alternate";

        this.setBounds(trigger);
      }
    }
  }

  return store;
};

export default Main.createStore;
