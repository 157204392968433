/**
 * Check if the string is a cluster. Has `[]` or `{}`
 * @param {String} input
 * @param {String} open Open character
 * @param {String} close Close character
 */
const isCluster = (input, open = "[", close = "]") => {
  return input.charAt(0) === open && input.charAt(input.length - 1) === close;
};

export default isCluster;
