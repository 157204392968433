import { Container } from "./Container/index";
import { Group } from "./Group/index";
import { Main } from "./Main/index";
import applyAsyncReferences from "./Main/store/utils/apply-async-references";
import intersectionCallback from "./utils/intersection-callback";
import WINDOW from "./utils/window";

export const observer = async () => {
  if (WINDOW.observers.asyncMutation) {
    return;
  }

  const callback = async (entries) => {
    const mains = new Set();
    const groups = new Set();
    const containers = new Set();

    for (const entry of entries) {
      for (const node of entry.addedNodes) {
        if (node.dataset?.tor) {
          node.Torus = node.Torus || {};
          node.Torus.visible = true;
          mains.add(node);
        }

        if (node.dataset?.torGroup) {
          node.Torus = node.Torus || {};
          groups.add(node);
        }

        if (node.dataset?.torContainer === "") {
          node.Torus = node.Torus || {};
          containers.add(node);
        }
      }

      for (const node of entry.removedNodes) {
        if (node.Torus?.Main) {
          TORUS.Main.destroy(node);
        }
      }
    }

    //TODO: finish for mains
    let mainsInitialized;

    if (groups.size) {
      await Group.init(groups);
    }
    if (mains.size) {
      mainsInitialized = await Main.init(mains);
    }
    if (containers.size) {
      await Container.init(containers);
    }

    applyAsyncReferences(mainsInitialized);
  };

  WINDOW.observers.asyncMutation = new MutationObserver(callback);

  WINDOW.observers.asyncMutation.observe(document, {
    childList: true,
    subtree: true,
    attributeFilter: ["data-tor", "data-tor-group", "data-tor-container"],
  });
};
