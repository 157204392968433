const getPriority = (property) => {
  let priority = false;

  if (property.get("priority")) {
    priority = true;

    const live = property.get("live");
    const status = live.status === "finished";
    const finished = live.progress === 0;

    if (status && finished) {
      priority = false;
    }
  }

  return priority;
};

export default getPriority;
