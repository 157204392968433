import getMaxSide from "../../../../utils/get-max-side";
import WINDOW from "../../../../utils/window";
import getCenter from "../get-center";
import threshold from "../threshold";

/**
 * Pointer self parallax
 * @param {import("../../../bounds/set-bounds").BoundsProps} bounds
 * @param {import("../../../../utils/window").PointerEventType} event
 * @param {import("../../../../utils/process-values-in-resolutions").PropertyMapProps} data
 */
const pointerSelfParallax = (bounds, event, data) => {
  const center = getCenter(bounds);
  const centerX = center.x;
  const centerY = center.y;

  const max = getMaxSide(bounds, WINDOW.width, WINDOW.height);
  const maxX = max.xSide;
  const maxY = max.ySide;

  bounds.maxXSide = maxX;
  bounds.maxYSide = maxY;

  const width = bounds.width;
  const height = bounds.height;

  let x = ((event.x - centerX * 2) / threshold(data, maxX, width)) + 1;
  let y = ((event.y - centerY * 2) / threshold(data, maxY, height)) + 1;
  let both = (x + y) / 2;

  // TODO: add method init on all pointer methods
  if (!event.initialized) {
    const init = data.has("r_methodInit");

    if (init) {
      x = data.get("r_methodInit");
      y = data.get("r_methodInit");
    }
  }

  return {
    x,
    y,
    both,
  };
};

export default pointerSelfParallax;
