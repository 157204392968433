import G from "./globals";

const setOptionReferences = (options, result) => {
  const references = G.optionsReferences;

  /**
   * `result.r_iterations = options.iterations.current.value;`
   */
  for (const key of references.regular) {
    const option = options.get(key).get("current").get("value");

    if (option || option === 0) {
      result.set(`r_${key}`, option);
    }
  }

  /**
   * result.r_spring = options.easing.current.fn === "spring";
   * result.r_reversed = options.direction.current.value === "reversed";
   */
  for (const key in references.boolean) {
    const value = references.boolean[key];
    const option = options.get(value.name);

    if (option) {
      result.set(`r_${key}`, option.get("current").get(value.type) === key);
    }
  }

  G.references.options.add(result);
};

export default setOptionReferences;
