import warnings from "../../../utils/warnings";
import substringOptions from "./substring-options";

/**
 * Extracts the content between the last occurrence of `<` and `>` in the input string.
 * @param {String} input - The input string.
 * @param {Boolean} isCluster
 * @returns The content between the last occurrence of `<` and `>` in the input string, or `null` if no matching pair is found.
 */
const extractLastOptions = (input, isCluster) => {
  let hasLastOptions = false;

  if (isCluster) {
    hasLastOptions = input.charAt(input.length - 1) === ">";
  } else {
    hasLastOptions = input.charAt(input.length - 2) === ">";
  }

  let startIdx = input.lastIndexOf("<");
  let endIdx = input.lastIndexOf(">");

  if (startIdx > 0 && endIdx < 0) {
    warnings.notClosedOptions(input);
    return;
  }

  /** Full function `ty(1rem <2s>)` */
  if (startIdx !== -1 && endIdx !== -1 && startIdx < endIdx && hasLastOptions) {
    return substringOptions(input, startIdx, endIdx);
  }

  /** Just value with options `1rem <2s>` */
  if (endIdx === input.length - 1) {
    return substringOptions(input, startIdx, endIdx);
  }

  return {
    options: null,
    rest: input,
  };
};

export default extractLastOptions;
