/**
 * ------------------------------------------------------------------------
 * Loop trough all affected HTML elements and set the `data-tor` attribute with
 * computed counting
 * ------------------------------------------------------------------------
 */

import checkAndReplaceFirstLastAction from "./check-and-replace-action";
import getReplacedMatches from "./get-replaced-matches";

const setDatasetToElement = async function (elements, attributes, group) {
  for (let i = 0; i < elements.length; i++) {
    let matches;
    let counting;
    let replaced = attributes;

    matches = attributes.match(/\/(.*?)\/+/g);

    if (matches) {
      counting = getReplacedMatches(matches, i);

      /** Replace original counting definition with computed value */
      for (let x = 0; x < matches.length; x++) {
        replaced = replaced.replace(matches[x], counting[x]);
      }
    }

    if (replaced.indexOf("first@") !== -1 || replaced.indexOf("last@") !== -1) {
      const matchings = replaced.match(/(?:last|first)@([^,>)]+)/g);

      replaced = checkAndReplaceFirstLastAction(
        elements,
        matchings,
        i,
        replaced
      );
    }

    // const target = group.targets.get(elements[i]);

    // if (!target) {
    //   group.targets.set(elements[i], new Set());
    // }

    // const set = group.targets.get(elements[i]);
    // set.add(replaced);

    const element = elements[i];
    let target = group.targets.get(element);

    if (!target) {
      target = new Set();
      group.targets.set(element, target);
    }

    target.add(replaced);
  }
};

export default setDatasetToElement;
