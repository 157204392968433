import WINDOW from "../../utils/window";
import Main from "../Main";
import onPointerMove from "../events/on-pointer-move";
import onScroll from "../events/on-scroll";
import setCustomEvents from "./set-custom-events";
import setSpecificListeners from "./set-specific-listeners";

/**
 * Apply an event listener(s) to given target
 * @param {import("../store/utils/get-trigger").TriggerProps} trigger
 */
// Main.prototype.applyListener = function (trigger) {
const applyListener = function (trigger, main) {
  let element = main.element;

  const globalTriggers = {
    scroll: {
      callback: onScroll,
    },
    mouse: {
      callback: onPointerMove,
    },
    pointer: {
      callback: onPointerMove,
    },
  };

  /**
   * Set custom listener events
   * @example `viewport`
   */
  setCustomEvents(trigger, main);

  /**
   * Specific listeners
   * @example `active`
   */
  setSpecificListeners(trigger);

  for (const event of trigger.events) {
    const globalEvent = globalTriggers[trigger.type];

    if (globalEvent) {
      if (!WINDOW.listeners[trigger.type]) {
        window.addEventListener(
          globalEvent.event || event,
          globalEvent.callback
        );

        WINDOW.listeners[trigger.type] = globalEvent.callback;
      }
    } else {
      if (!main.listeners.has(event)) {
        const fn = (e) => {
          if (e.detail !== 0) {
            e.details = trigger;
          }

          return main.on[event](e);
        };

        element.addEventListener(event, fn);
        main.listeners.set(event, fn);
      }
    }
  }
};

export default applyListener;
