import runReference from "./run-reference";

const checkReference = (trigger, status, data, parent) => {
  if (data.get("hasBeginReference") && status === "started") {
    const reference = data.get("options").get("begin").get("current").get("value");
    runReference(trigger, reference, parent);
  }

  if (data.get("hasFinishReference") && status === "finished") {
    const reference = data.get("options").get("finish").get("current").get("value");
    runReference(trigger, reference, parent);
  }
};

export default checkReference;
